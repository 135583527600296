export default {
  label: "nl",
  antdPath: require("antd/lib/locale-provider/nl_NL"),
  reactIntlPath: require("react-intl/locale-data/nl"),
  messages: {
    "User.profile": "Mijn profiel",
    "User.addName": "Voeg naam toe",
    "Tenant.organization": "Mijn organisatie",
    "Tenant.organization.name": "Organisatienaam",
    "NewDashboard.welcomeToFleks": "Welkom {name}!",
    "NewDashboard.getToKnowThePlatform": "Begin direct met plannen.",
    "NewDashboard.newJob": "Nieuwe<br/>dienst",
    "NewDashboard.createANewJob": "Maak een nieuwe dienst aan",
    "NewDashboard.newApplications": "Nieuwe inschrijvingen",
    "NewDashboard.newApplicationsDescription": "Reageer op de kandidaten!",
    "NewDashboard.newDeclarations": "Nieuwe urenregistratie",
    "NewDashboard.newInvoices": "Nieuwe facturen",
    "NewDashboard.newDeclarationsDescription": "Controleer de gewerkte uren!",
    "NewDashboard.openJobs": "Open diensten",
    "NewDashboard.openJobsDescription": "Vul de open plekken!",
    "NewDashboard.goToPlanningProgress": "Bekijk planning voortgang",
    "NewDashboard.planningProgress.title": "De planning voortgang",
    "NewDashboard.planningProgress.totalPositions": "Totaal aantal posities",
    "NewDashboard.planningProgress.fromDate": "Startdatum",
    "NewDashboard.planningProgress.toDate": "Einddatum",
    "NewDashboard.planningProgress.progress":
      "Intern gevuld / Extern gevuld / Open posities",
    "NewDashboard.planningProgress.startEndDate": "Start en einddatum",
    "NewDashboard.toTheOldDashboard": "Ga terug naar actiebord",
    "NewDashboard.toTheNewDashboard": "Naar het widgetbord",
    "Dashboard.welcome": "Welkom",
    "Dashboard.hours-today": "Diensten van vandaag",
    "Dashboard.hours-today.placeholder": "Medewerkers vandaag aan het werk",
    "Dashboard.reserved-hours": "Reserves van vandaag",
    "Dashboard.reserved-hours.placeholder":
      "De medewerkers die reserve staan vandaag",
    "Dashboard.projects-progress": "Project voortgang",
    "Dashboard.projects-progress.placeholder": "Openstaande & gevulde diensten",
    "Dashboard.contracts": "Contracten",
    "Dashboard.contracts.placeholder": "Medewerkers zonder contract sinds",
    "Dashboard.contracts.placeholder.upcomingToExpire":
      "Medewerkers met een aflopend contract",
    "Dashboard.contracts.placeholder.noSigned":
      "Medewerkers met een contractvoorstel dat nog ondertekend moet worden",
    "Dashboard.tax-rebates": "Wijzigingen in loonheffingskorting",
    "Dashboard.tax-rebates.placeholder":
      "Medewerkers met een loonheffingskorting wijziging",
    "Dashboard.did-not-check-in": "Inplanningen van vandaag: Te laat aangemeld",
    "Dashboard.did-not-check-in.placeholder":
      "De medewerkers die zich te laat hebben aangemeld",
    "Dashboard.too-late": "Inplanningen van vandaag: Te laat op locatie",
    "Dashboard.too-late.placeholder": "Te laat op de locatie van de dienst",
    "Dashboard.tdl-tables": "Inplanningen van vandaag",
    "Dashboard.contracts-table": "Contracten",
    "Dashboard.times": "Start-eindtijd",
    "Dashboard.contractName": "Contractnaam",
    "Dashboard.taxRebate-active": "Loonheffingskorting actief",
    "Dashboard.taxRebate-noActive": "Loonheffingskorting niet actief",
    "Dashboard.days": "Einddatum",
    "Dashboard.hr-tna": "Aantal medewerkers",
    "Dashboard.hr-maa": "Actieve medewerkers",
    "Dashboard.hr-nrtm": "Nieuwe medewerkers",
    "Dashboard.hr-wa": "Sollicitanten",
    "Dashboard.hr-wap": "Aangenomen",
    "Dashboard.map.next-two-hours": "Diensten aankomende twee uur",
    "Dashboard.map.jobs-today": "Diensten van vandaag op de kaart",
    "Dashboard.map.loaded": "inplanningen geladen van aantal diensten:",
    "Dashboard.map.client": "Opdrachtgever",
    "Dashboard.map.info-hours": "Ingeplande medewerkers op de dienst",
    "Dashboard.map.info-hours.check_in1": "heeft zich aangemeld op",
    "Dashboard.map.info-hours.check_in2": "op locatie om",
    "Dashboard.map.info-hours.diff": "gaat",
    "Dashboard.map.info-hours.late": "minuten te laat zijn!",
    "Dashboard.map.info-hours.show": "Bekijk details van de inplanning",
    "Dashboard.map": "Diensten van vandaag",
    "Dashboard.absent": "Absent",
    "Dashboard.absent.noShow": "No-Show",
    "Dashboard.absent.calledOff": "Ziek",
    "Dashboard.taxRebate": "Loonheffing",
    "Dashboard.absent.noShow.subtext":
      "Diensten waar de medewerker niet is komen opdagen tussen",
    "Dashboard.absent.calledOff.subtext":
      "Diensten waar de medewerkers zich heeft ziekgemeld tussen",
    "Dashboard.temporaryQualifications": "Tijdelijke kwalificaties",
    "Dashboard.temporaryQualifications.qualification": "Kwalificatie",
    "Dashboard.temporaryQualifications.expired": "Verlopen",
    "Dashboard.temporaryQualifications.comingToExpire": "Aflopend",
    "Dashboard.temporaryQualifications.onGoing": "Lopend",
    "Dashboard.temporaryQualifications.endDate": "Einddatum",
    "Dashboard.temporaryQualifications.description": "Bekijk hier de verlopen, aflopende en lopende tijdelijke kwalificaties",
    "Presets.select-presets": "Je modules",
    "Presets.select-hr": "Je statistieken",
    "Presets.reset": "Verwijder je modules",
    "LoginPage.emailUnregistered":
      "Dit e-mailadres komt niet overeen met onze database. Probeer het opnieuw of",
    "LoginPage.emailInvalid":
      "Het emailadres dat je hebt ingevoerd is niet geldig. Probeer het opnieuw!",
    "LoginPage.usernameUnregistered":
      "De gebruikersnaam is niet gevonden, probeer opnieuw",
    "LoginPage.usernameInvalid":
      "De gebruikersnaam is niet gevonden, probeer opnieuw",
    "LoginPage.requestHelp": "hulp vragen.",
    "LoginPage.next": "Doorgaan",
    "LoginPage.login": "Log in",
    "loginPage.mediamarktCheckbox":
      "Ik ga hierbij akkoord met de algemene gebruikersvoorwaarden en de gedragscode van promotoren van MediaMarkt.",
    "LoginPage.passwordPlaceholder": "Voer uw wachtwoord in...",
    "LoginPage.passwordInvalid":
      "De combinatie van jouw gebruikersnaam en wachtwoord kloppen niet. Probeer het opnieuw of",
    "LoginPage.resetPassword": "Nieuw wachtwoord",
    "LoginPage.resetPassword.description":
      "Vul een nieuw wachtwoord in. Dit wachtwoord moet minimaal 8 karakters hebben.",
    "LoginPage.resetPassword.success": "Gelukt!",
    "LoginPage.resetPassword.success.description":
      "Je hebt een nieuw wachtwoord ingesteld.",
    "LoginPage.resetPassword.save": "Sla nieuw wachtwoord op",
    "LoginPage.resetPassword.noValidCode": "De code is niet meer geldig",
    "LoginPage.resetPassword.noValidCode.description":
      "De link is verlopen of is al een keer gebruikt",
    "LoginPage.rememberMe": "Onthouden",
    "LoginPage.confirmation":
      "Klik op de %slink%e in de mail en%b gebruik de reset code om een %snieuw wachtwoord%e in te stellen.",
    "LoginPage.gotMail": "Je hebt wat in je mailbox!",
    "LoginPage.validRobot": "Klopt dat je geen robot bent",
    "LoginPage.error": "Er is een fout opgetreden. Probeer het later opnieuw",
    "LoginPage.password": "Wachtwoord",
    "LoginPage.password.new": "Nieuw wachtwoord",
    "LoginPage.password.confirm": "Bevestig wachtwoord",
    "LoginPage.password.notEqual": "Wachtwoorden komen niet overeen",
    "LoginPage.username": "Gebruikersnaam",
    "LoginPage.newConnection":
      "We hebben gemerkt dat u een connectieverzoek hebt ontvangen. Log in voor meer informatie over het verzoek.",
    "LoginPage.welcome": "Welkom terug!",
    "LoginPage.welcome.description":
      "Log in met jouw inloggegevens (alleen voor werkgevers).",
    "LoginPage.forgotYourPassword": "Wachtwoord vergeten?",
    "LoginPage.createAccount": "Heb je nog geen account? Registreer direct!",
    "LoginPage.createAccount.create": "Maak een account",
    "LoginPage.createAccount.fillIn": "Vul de ontbrekende gegevens in",
    "LoginPage.createAccount.userAlreadyTaken":
      "De gebruikersnaam is al in gebruik",
    "LoginPage.createAccount.companyNameAlreadyTaken":
      "De organisatienaam is al in gebruik",
    "LoginPage.createAccount.goBack": "Ga terug",
    "LoginPage.createAccount.invalidUsername":
      "De gebruikersnaam mag geen spaties bevatten.",
    "LoginPage.createAccount.success":
      "Je account wordt aangemaakt! Je ontvangt binnen enkele minuten een bevestigingsmail met je gegevens en direct kunt starten met plannen & diensten kunt uitwisselen met je samenwerkende partij!",
    "LoginPage.createAccount.error":
      "Je kunt geen account aanmaken met deze gegevens <a href='https://www.fleks.works/contact' target='_blank'>Neem contact met ons op</a> zodat wij samen naar een oplossing kunnen kijken",
    "LoginPage.createAccount.generalAgreements":
      "Door te klikken op 'Maak een account' ga je akkoord met <a href='https://assets.website-files.com/5e6fe86cf07ada83b89b10a2/5f8011c7581ce4bd9999831f_Algemene%20Voorwaarden.pdf' target='_blank'>algemene voorwaarden & verwerkersovereenkomst</a>",
    "LoginPage.credentialsDoNotMatch":
      "Jouw gebruikersnaam en wachtwoord komen niet overeen.",
    "LoginPage.forgot": "Wachtwoord reset",
    "LoginPage.forgot.description":
      "Vul jouw gebruikersnaam in waar je normaal mee in zou loggen. Let op! Dit veld is hoofdletter gevoelig.",
    "LoginPage.forgot.sendResetEmail": "Stuur de reset wachtoord mail",
    "LoginPage.forgot.checkYourEmail": "Bekijk je mail",
    "LoginPage.forgot.checkYourEmail.caption":
      "Wij sturen een link om het wachtwoord opnieuw in te stellen naar het e-mailadres dat aan deze gebruikersnaam is gekoppeld. Controleer je inbox.",
    "LoginPage.forgot.didNotReceive":
      "Geen e-mail ontvangen? Neem dan contact op met jouw werkgever.",
    "LoginPage.forgot.itIsNotAValidUsername":
      "Het gebruikersnaam wordt niet herkend.",
    "LoginPage.2fa.sendCode.error":
      "De ingevoerde code moet 6 cijfers bevatten",
    "LoginPage.2fa.descriptionChoose.subTitle":
      "Je organisatie heeft tweefactorauthenticatie ingeschakeld. Kies de authenticatiemethode waarmee u de code wilt ontvangen om in te loggen.",
    "LoginPage.2fa.checkboxSelect.methodGoogle": "Gebruik Google Authenticator",
    "LoginPage.2fa.checkboxSelect.methodEmail":
      "Gebruik het e-mailadres dat aan mijn account is gekoppeld",
    "LoginPage.2fa.descriptionChoose.title": "Kies een methode",
    "LoginPage.2fa.title.authenticationGoogle": "Google Authenticatie",
    "LoginPage.2fa.subtitle.authenticationGoogle":
      "Gebruik de Google Authenticator-app om de onderstaande afbeelding te scannen.",
    "LoginPage.2fa.secondSubtitle.authenticationGoogle":
      "Na het scannen van de afbeelding geeft de app een 6-cijferige code weer die u hieronder kunt invoeren.",
    "LoginPage.2fa.thirdSubtitle.authenticationGoogle":
      "Voer de 6-cijferige code in die wordt weergegeven in de Google Authenticator-app hieronder.",
    "LoginPage.2fa.title.authenticationEmail": "Email Authenticatie",
    "LoginPage.2fa.subtitle.authenticationEmail":
      "Er is een e-mail verzonden naar het e-mailadres dat aan uw account is gekoppeld. Voer de 6-cijferige code in die in de onderstaande e-mail wordt weergegeven.",
    "LoginPage.2fa.enterCode": "Voer code in",
    "LoginPage.2fa.enterCode.error":
      "De ingevoerde code is niet geldig, probeer het opnieuw.",
    "LoginPage.2fa.titleSetMethod.successMessage.google":
      "De authenticatiemethode is toegevoegd aan uw account!",
    "LoginPage.2fa.descriptionSetMethod.successMessage.google":
      "Voortaan wordt elke keer dat u inlogt om een verificatiecode gevraagd. U hebt toegang tot deze code met behulp van de Google Authenticatie-applicatie. Onthoud dat als u uw apparaat verliest of een nieuwe authenticatiemethode wilt instellen, u contact moet opnemen met een beheerder om door te gaan.",
    "LoginPage.2fa.titleSetMethod.successMessage.email":
      "De authenticatiemethode is toegevoegd aan uw account!",
    "LoginPage.2fa.descriptionSetMethod.successMessage.email":
      "Voortaan wordt elke keer dat u inlogt om een verificatiecode gevraagd. U krijgt toegang tot deze code door de inbox van de e-mail te controleren die aan uw account is gekoppeld. Onthoud dat als u uw apparaat verliest of een nieuwe authenticatiemethode wilt instellen, u contact moet opnemen met een beheerder om door te gaan.",
    "LoginPage.loginEmployee": "Inloggen als medewerker",
    "PasswordRecoveryPage.resetPassword": "Reset wachtwoord",
    "PasswordRecoveryPage.passwordPlaceholder": "Nieuw wachtwoord",
    "PasswordRecoveryPage.rePasswordPlaceholder": "Herhaal wachtwoord",
    "PasswordRecoveryPage.passwordRequire":
      "Alle velden zijn verplicht, vul alle velden in",
    "PasswordRecoveryPage.differentPasswords":
      "Wachtwoorden moeten overeenkomen, voer ze opnieuw in",
    "PasswordRecoveryPage.confirmation":
      "Je wachtwoord is gewijzigd %bJe gaat terug naar de inlogpagina",
    "PasswordRecoveryPage.success": "Succes!",
    "PasswordRecoveryPage.missCode":
      "Voer deze pagina opnieuw in via de link die naar uw e-mail is verzonden",
    "Database.settings.setup": "Jouw tabellen instellen",
    "Database.settings.description":
      "Dit zijn je tabel instellingen. Pas de volgende informatie aan zodat het beter past bij jouw voorkeuren.",
    "Database.settings.columnsPerTable": "Kolommen per tabel",
    "Database.settings.columnsPerTable.description":
      "Fleks ondersteunt 10 kolommen per tabel, waarvan 'Naam' en 'Status' niet aanpasbaar zijn. Dit betekent dat jij maximaal 8 kolommen zelf kan toevoegen en aanpassen.",
    "Database.settings.columnsPerTable.whatTableEdit":
      "Welke tabel wil je aanpassen?",
    "Database.settings.rowsPerTable": "Rijen aanpassen per tabel",
    "Database.settings.rowsPerTable.description":
      "De hoeveelheid regels die per pagina worden getoond kan je hier aanpassen.",
    "Database.settings.rowsPerTable.numberOfRows": "Aantal rijen",
    "Database.settings.rowsPerTable.numberOfRows.maxError":
      "Je kunt niet meer dan 100 instellen",
    "Database.settings.rowsPerTable.adjustRows": "Rijen aanpassen",
    "Database.settings.title": "Tabel instellingen",
    "Database.settings.saveTableViews": "Bewaar tabelweergaven",
    "Database.settings.saveTableViews.description": "Wilt u uw huidige tabelweergaven als standaard instellen? Hiermee wordt uw huidige tabblad en sorteerwijze voor klant-, project-, diensten-, inplanningen-, medewerker- en urenregistratietabellen opgeslagen.",
    "Database.settings.saveTableViews.saveDefault": "Opslaan als standaard",
    "Database.customization.clients": "Opdrachtgevers tabel",
    "Database.customization.projects": "Projecten tabel",
    "Database.customization.jobs": "Diensten tabel",
    "Database.customization.jobRequests": "Dienst aanvragen tabel",
    "Database.customization.newemployers": "Nieuwe werkgevers tabel",
    "Database.customization.employers": "Werkgevers tabel",
    "Database.customization.employees": "Medewerkers tabel",
    "Database.customization.incomingEmployees": "Externe medewerkers tabel",
    "Database.customization.shifts": "Inplanningen tabel",
    "Database.customization.applicants": "Sollicitanten tabel",
    "Database.customization.newFreelancers": "Nieuwe freelancers tabel",
    "Database.customization.freelancers": "Freelancers tabel",
    "Database.customization.singles": "Urenregistratie per inplanning tabel",
    "Database.customization.totals": "Urenregistratie per medewerker",
    "Database.customization.singlesInternal":
      "Intern per inplanning",
    "Database.customization.totalsInternal":
      "Intern per medewerker",
    "Database.customization.externals": "Externe uren tabel",
    "Database.customization.allHours": "Alle uren",
    "Database.customization.invoices": "Facturen tabel",
    "Database.customization.description":
      "Verander de volgorde of vervang de kolommen door de vakjes te slepen.",
    "Database.customization.saveChanges": "Opslaan",
    "Database.tables.haveSelected":
      "U hebt alle velden van de pagina geselecteerd.",
    "Database.tables.AllListHaveBeenSelected":
      "Alle {total} velden zijn geselecteerd.",
    "Database.tables.selectAllList":
      "Selecteer alle {total} velden op het tabblad '{tab}'",
    "Clients.archived": "{items} opdrachtgevers gearchiveerd",
    "Clients.name": "Naam",
    "Clients.id": "ID",
    "Clients.city": "Stad",
    "Clients.projects": "Projecten",
    "Clients.date": "Datum",
    "Clients.clients": "Opdrachtgevers",
    "Clients.newClient": "Voeg opdrachtgever of locatie toe",
    "Clients.all": "Alle",
    "Clients.filter": "Filter",
    "Clients.create-client": "Opdrachtgever",
    "Clients.create-department": "Maak locatie",
    "Clients.upload": "Uploaden",
    "Clients.street-name": "Straatnaam",
    "Clients.zip-code": "Postcode",
    "Clients.house-number": "Huisnummer",
    "Clients.house-number-ext": "Extensie",
    "Clients.require-text": "Dit veld is verplicht",
    "Clients.fill-in": "Vul in",
    "Clients.general": "Algemeen",
    "Clients.logo": "Logo",
    "Clients.contact": "Contactpersoon",
    "Clients.Single.totalProjects": "Totaal aantal projecten",
    "Clients.Single.ongoingProjects": "Lopende projecten",
    "Clients.Single.finishedProjects": "Afgelopen projecten",
    "Clients.Single.project": "Projecten",
    "Clients.Details.general": "Algemeen",
    "Clients.Details.clientId": "Klantnummer",
    "Clients.Details.id": "Klantnummer",
    "Clients.Details.name": "Naam",
    "Clients.Details.streetName": "Straatnaam",
    "Clients.Details.number": "Huisnummer",
    "Clients.Details.ext": "Ext",
    "Clients.Details.stateRegion": "Stad",
    "Clients.Details.zipCode": "Postcode",
    "Clients.Details.documents": "Documenten",
    "Clients.Details.documents.add": "Voeg een document toe",
    "Clients.Details.documents.upload": "Document uploaden",
    "Clients.Details.documents.dragText": "Upload hier het bestand.",
    "Clients.Details.documents.edit": "Document aanpassen",
    "Clients.Details.documents.delete": "Document verwijderen",
    "Clients.Details.documents.delete.confirmation":
      "Weet je zeker dat je het document wilt verwijderen?",
    "Clients.Details.documents.deleteFile.confirmation":
      "Weet je zeker dat je het bestand wilt verwijderen?",
    "Clients.Details.Parent": "Opdrachtgever",
    "Clients.Details.description.placeholder":
      "Voeg een beschrijving toe over de functies die de medewerker voor deze opdrachtgever gaat doen, dit zal zichtbaar zijn voor de medewerker in zijn functieomschrijving.",
    "Clients.Details.description": "Beschrijving (optioneel)",
    "Clients.title": "Opdrachtgevers",
    "Clients.contactPerson": "Contactpersonen van opdrachtgever",
    "Clients.addDepartment": "Voeg locatie toe",
    "Clients.parent": "Opdrachtgever",
    "Clients.type": "Type",
    "Clients.type.checkbox":
      "Dit is een van de locatie(s) van de opdrachtgever",
    "Clients.create": "Opdrachtgever aanmaken",
    "Clients.Details.templates": "Standaard project",
    "Clients.templates.no-data":
      "Voeg een sjabloonproject aan de opdrachtgever toe, wanneer u een project voor deze opdrachtgever maakt, kunt je dit later selecteren tijdens het aanmaken van een project.",
    "Clients.emptyState.subTable": "Geen locaties toegevoegd",
    "Clients.emptyState.title": "Er zijn geen opdrachtgevers toegevoegd",
    "Clients.emptyState.description":
      "Klik op de paarse knop rechtsboven in de hoek om een opdrachtgever of een locatie toe te voegen.",
    "Clients.noClients": "Geen opdrachtgevers gevonden",
    "Clients.active": "Actief",
    "Clients.inactive": "Inactief",
    "Clients.numberOfProjects": "Aantal projecten",
    "Clients.customNumber": "ID",
    "Clients.dateCreated": "Aanmaak datum",
    "Clients.standardProjects.new": "Voeg een standaard project toe",
    "Clients.archive.confirmation": `Je staat op het punt om
    {items, plural,
      one {# opdrachtgever}
      other {# opdrachtgevers}    
    } te {archive, select,
      true {de-archiveren}
      false {archiveren}
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Clients.chamberOfCommerce": "KvK nummer",
    "Clients.adjustGeneralInfo": "Pas de algemene informatie aan",
    "Clients.adjustContactPersonInfo": "Informatie contactpersoon aanpassen",
    "Clients.saveGeneralInfo": "Opslaan",
    "Templates.errors.delete": "Het is niet gelukt het sjabloon te verwijderen",
    "Templates.errors.edit":
      "Er zijn geen sjablonen gevonden die aangepast kunnen worden",
    "Templates.success.delete": "Project sjabloon is verwijderd",
    "Templates.info.template": "[text]",
    "Templates.success.create": "Sjabloon is aangemaakt",
    "Templates.success.edit": "Sjabloon is aangepast",
    "Templates.info.loading": "Instellingen sjabloon inladen",
    "Templates.edit": "Pas sjabloon aan",
    "Templates.delete": "Standaard project verwijderen",
    "Templates.create": "Maak sjabloon aan",
    "Templates.export.project": "Projecten",
    "Templates.export.job": "Diensten",
    "Templates.export.employee": "Medewerkers",
    "Templates.export.declaration_internal": "Intern per inplanning",
    "Templates.export.declaration_total": "Intern totalen",
    "Templates.export.shift_internal": "Interne inplanningen",
    "Templates.export.shift_all": "Intern & externe inplanningen",
    "Templates.export.billable_hours": "Extern per inplanning",
    "Templates.export.internal_per_employee": "Intern per medewerker",
    "Templates.exportCreation": "Nieuwe export template",
    "Templates.exportEdit": "Template aanpassen",
    "Templates.exportAvailableFor": "Template beschikbaar voor",
    "Templates.roles": "Rollen",
    "Templates.adminOnly":
      "Dit rapport is alleen beschikbaar voor admin-gebruikers",
    "Export.templates.delete": "Template is succesvol verwijderd",
    "Export.templates.create": "Template is succesvol gemaakt",
    "Export.templates.edit": "Template is succesvol aangepast",
    "Templates.model.project_title": "Project titel",
    "Templates.userTypes.app_brand_user_group": "Manager",
    "Templates.userTypes.dashboard_ftp_user_group": "Admin",
    "Templates.userTypes.planner_user_group": "Planner",
    "Templates.shiftCard.internal": "Alleen intern",
    "Templates.shiftCard.internalExternal": "Interne & externe medewerkers",
    "Templates.model.employee_first_name": "Voornaam",
    "Templates.model.employee_initials": "Initialen",
    "Templates.model.employee_last_name": "Achternaam",
    "Templates.model.employee_surname_prefix": "Tussenvoegsel",
    "Templates.model.employee_address": "Adres",
    "Templates.model.employee_bank_acc_number": "IBAN",
    "Templates.model.employee_social_number": "BSN",
    "Templates.model.employee_city": "Stad",
    "Templates.model.employee_house_number": "Huisnummer",
    "Templates.model.employee_house_number_ext": "Huisnummer toevoeging",
    "Templates.model.employee_post_code": "Postcode",
    "Templates.model.employee_gender": "Geslacht",
    "Templates.model.employee_archived_at": "Gearchiveerd op",
    "Templates.model.employee_wage_tax_rebate_status":
      "Loonheffingskorting status",
    "Templates.model.employee_wage_tax_rebate_date":
      "Loonheffingskorting ingangsdatum",
    "Templates.model.employee_phone_number": "Telefoonnummer",
    "Templates.model.project_custom_number": "Projectnummer",
    "Templates.model.project_manager": "Manager",
    "Templates.model.project_full_address": "Volledige adres",
    "Templates.model.project_start_date": "Dienst startdatum",
    "Templates.model.project_end_date": "Dienst einddatum",
    "Templates.model.project_number_of_employees": "Aantal medewerkers",
    "Templates.model.project_accepted_users": "Aantal gepland",
    "Templates.model.project_category_names": "Functie categorieën",
    "Templates.model.project_total_jobs": "Totaal aantal diensten",
    "Templates.model.project_pending_jobs": "Aantal openstaande diensten",
    "Templates.model.project_brand_names": "Opdrachtgever",
    "Templates.model.employee_custom_number": "Personeelsnummer",
    "Templates.model.employee_full_name": "Volledige naam",
    "Templates.model.job_project_number": "Projectnummer",
    "Templates.model.job_category_name": "Functie categorie",
    "Templates.model.job_category_sales_price": "Functie verkoopprijs",
    "Templates.model.sign_off_time": "Digitale handtekening vanuit de manager app",
    "Templates.model.employee_sign_off_time": "Digitale handtekening vanuit de medewerker app",
    "Templates.model.job_full_address": "Volledige adres",
    "Templates.model.job_tenant_name": "Dienst eigenaar",
    "Templates.model.employee_current_tenant_name": "Geregistreerd bij",
    "Templates.model.jap_start_date": "Startdatum en tijd",
    "Templates.model.jap_end_date": "Einddatum en tijd",
    "Templates.model.jap_custom_number": "Inplanning ID",
    "Templates.model.billable_billed_hours": "Uren te factureren",
    "Templates.model.billable_travel_time": "Reistijd",
    "Templates.model.billable_travel_distance": "Aantal gereden kilometers",
    "Templates.model.billable_transportation_cost": "Kosten openbaar vervoer",
    "Templates.model.billable_other_cost": "Overige kosten",
    "Templates.model.employee_birth_date": "Geboortedatum",
    "Templates.model.employee_full_address": "Adres medewerker",
    "Templates.model.employee_email": "Email",
    "Templates.model.job_start_date": "Startdatum",
    "Templates.model.jap_invalid_state": "Annuleringstype",
    "Templates.model.contract_name": "Contractnaam",
    "Templates.model.comment_texts": "Opmerkingen",
    "Templates.model.job_created_by": "Aangemaakt door",
    "Templates.model.job_date_created": "Aangemaakt op",
    "Templates.model.jap_created_by": "Aangemaakt door",
    "Templates.model.jap_date_created": "Aangemaakt op",
    "Templates.model.employee_created_by": "Aangemaakt door",
    "Templates.model.project_created_by": "Aangemaakt door",
    "Templates.model.project_date_created": "Aangemaakt op",
    "Templates.model.employee_qualifications": "Kwalificatie(s) van medewerkers",
    "Templates.model.job_end_date": "Eindatum",
    "Templates.model.declaration_user_hours_worked": "Aantal werkuren",
    "Templates.model.declaration_compensation_hours": "Extra uren",
    "Templates.model.declaration_break_compensation": "Pauzeuren",
    "Templates.model.declaration_applicable_travel_time": "Reistijd",
    "Templates.model.jap_check_in_qr": "Inchecktijd QR code",
    "Templates.model.jap_check_out_qr": "Uitchecktijd QR code",
    "Templates.model.declaration_travel_time_rate": "Reistijd tarief",
    "Templates.model.declaration_travel_time_fee": "Reistijd vergoeding",
    "Templates.model.declaration_travel_distance_applicable":
      "Aantal gereden kilometers",
    "Templates.model.declaration_km_driving_cost": "Kilometer vergoeding",
    "Templates.model.declaration_transportation_cost":
      "Kosten openbaar vervoer",
    "Templates.model.declaration_other_cost": "Overige kosten",
    "Templates.model.declaration_gross_salary": "Bruto salaris",
    "Templates.model.declaration_check_in": "Aanmeldtijd",
    "Templates.model.declaration_check_in_on_location": "Ingecheckt op locatie",
    "Templates.model.declaration_check_out": "Uitchecktijd",
    "Templates.model.declaration_approved_at": "Geaccepteerd op",
    "Templates.model.declaration_approved_by_email": "Geaccepteerd door",
    "Templates.model.declaration_exported_at": "Verwerkt op",
    "Templates.model.declaration_exported_by_email": "Verwerkt door",
    "Templates.model.jap_active_hourly_rate": "Uurloon",
    "Templates.model.declaration_active_fixed_rate": "Vast tarief",
    "Templates.model.declaration_active_payment_type":
      "Betaaltype (vast of uurtarief)",
    "Templates.model.initial": "Initialen",
    "Templates.model.employee_document_number": "Document nummer",
    "Templates.model.employee_wage_tax_rebate": "Loonheffingskorting status",
    "Templates.model.employee_date_created": "Aangemaakt op",
    "Templates.model.employee_last_invitation_at": "Uitgenodigd op",
    "Templates.model.employee_basic_reg_completed_at":
      "Basis registratie afgerond op",
    "Templates.model.employee_last_login_at": "Laaste login op",
    "Templates.model.employee_last_contacted_at": "Laatste contact op",
    "Templates.model.employee_last_jap_date": "Laatste afgerond dienst op",
    "Templates.model.contract_start_date": "Contract startdatum",
    "Templates.model.contract_end_date": "Contract einddatum",
    "Templates.model.job_custom_number": "Dienst nummer",
    "Templates.model.job_start_time": "Starttijd",
    "Templates.model.job_end_time": "Eindtijd",
    "Templates.model.job_worked_hours": "Aantal uur",
    "Templates.model.job_number_of_employees": "Aantal medewerkers nodig",
    "Templates.model.job_accepted_employees": "Aantal medewerkers gepland",
    "Templates.model.job_is_full": "Dienst gevuld (ja/nee)",
    "Templates.model.job_pending_employees":
      "Aantal inschrijvingen in afwachting",
    "Templates.model.job_invited_users": "Aantal uitgenodigd",
    "Templates.model.job_city": "Stad",
    "Templates.model.job_address_description": "Adres beschrijving",
    "Templates.model.job_zip_code": "Dienst postcode",
    "Templates.model.job_extra_note": "Interne notitie",
    "Templates.model.ratecard_date": "Datum",
    "Templates.model.ratecard_month": "Maand",
    "Templates.model.project_brand_custom_number": "Opdrachtgever nummer",
    "Templates.model.ratecard_type": "Toeslagkaart type",
    "Templates.model.job_category_custom_number": "Functie ID",
    "Templates.model.ratecard_start_time": "Starttijd",
    "Templates.model.ratecard_end_time": "Eindtijd",
    "Templates.model.ratecard_surcharge": "Toeslag",
    "Templates.model.ratecard_surcharge_percentage": "Toeslag percentage",
    "Templates.model.ratecard_number_of_units": "Aantal eenheden",
    "Templates.model.employee_shirt_size": "Shirtmaat",
    "Templates.model.employee_shoe_size": "Schoenmaat",
    "Templates.model.employee_license": "Rijbewijs",
    "Templates.model.employee_car": "Auto",
    "Templates.model.employee_education": "Opleiding",
    "Templates.model.jap_exception_state_name": "Bijzonderheidsstatus",
    "Templates.model.jap_exception_state_code": "Bijzonderheidsstatus ID",
    "Templates.model.jap_start_time": "Starttijd",
    "Templates.model.jap_end_time": "Eindtijd",
    "Templates.model.jap_total_hours": "Aantal uur",
    "Templates.model.jap_start_date_short": "Startdatum",
    "Templates.model.jap_end_date_short": "Einddatum",
    "Templates.model.free_fields": "Vrije velden",
    "Templates.model.employee_shifts_total_km_driving_cost": "Reiskosten",
    "Templates.model.employee_shifts_total_net_costs": "Totale kosten",
    "Templates.model.employee_shifts_total_gross_salary":
      "Brutosalaris (excl. reistijd vergoeding)",
    "Templates.model.employee_shifts_number_job_applications":
      "Aantal keer gewerkt",
    "Templates.model.employee_shifts_total_users_hours_worked": "Werkuren",
    "Templates.model.employee_shifts_total_compensation_hours": "Extra uren",
    "Templates.model.employee_shifts_total_travel_time_fee":
      "Reistijd vergoeding",
    "Templates.model.employee_shifts_total_applicable_travel_time": "Reistijd",
    "Templates.model.employee_shifts_total_travel_distance":
      "Aantal gereden kilometers",
    "Templates.model.employee_shifts_total_transportation_cost":
      "OV-vergoeding",
    "Templates.model.employee_shifts_total_other_cost": "Overige kosten",
    "Templates.model.employee_shifts_highest_hourly_rate":
      "Uurloon eind periode",
    "Templates.model.employee_shifts_total_hourly_rate_hours_worked":
      "Aantal uren met uurtarief",
    "Templates.model.employee_shifts_total_reserve_rate_hours_worked":
      "Aantal uren met vast reserve tarief",
    "Templates.model.employee_shifts_total_fixed_rate_hours_worked":
      "Aantal uren met vast tarief",
    "Templates.model.employee_shifts_total_hourly_gross_salary":
      "Brutosalaris met uurtarief",
    "Templates.model.employee_shifts_total_reserve_gross_salary":
      "Totale reserve vergoeding",
    "Templates.model.employee_shifts_total_fixed_gross_salary":
      "Totaal vast tarief vergoeding",
    "Templates.model.function_description": "Function description",
    "ContactPerson.NewContactPerson": "Maak contactpersoon aan",
    "ContactPerson.notCreated": "De contactpersoon is niet aangemaakt",
    "ContactPerson.created": "Contactpersoon is aangemaakt",
    "ContactPerson.create": "Maak een nieuw contactpersoon aan",
    "ContactPerson.userExist": "Het emailadres is al in gebruik",
    "ContactPerson.no-data": "Er zijn nog geen contactpersonen toegevoegd",
    "Projects.title": "Projecten",
    "Projects.newProject": "Nieuw project",
    "Projects.all": "Alle",
    "Projects.running": "Lopend",
    "Projects.upcoming": "Aankomende",
    "Projects.past": "Afgelopen",
    "Projects.name": "Naam",
    "Projects.clients": "Opdrachtgever",
    "Projects.typeOfWork": "Functie",
    "Projects.jobs": "Diensten",
    "Projects.pending": "Openstaand",
    "Projects.projectManager": "Projectmanager",
    "Projects.startDate": "Startdatum",
    "Projects.endDate": "Einddatum",
    "Projects.addEndDate": "Einddatum toevoegen",
    "Projects.id": "Projectnummer",
    "Projects.missedFunctionDescription": "De beschrijving mag niet leeg zijn",
    "Projects.fixedRate": "Vast bedrag",
    "Projects.hourlyRate": "Vast bedrag per uur",
    "Projects.ageHourlyRate": "Op leeftijd uitbetaald",
    "Projects.customAgeRelatedRate": "Op leeftijd",
    "Projects.customNumber": "Projectnummer",
    "Projects.contractBased": "Op contract",
    "Projects.breakApplicable": "Pauze van toepassing",
    "Projects.travelCost":
      "Geef aan of reiskosten automatisch berekend moeten worden aan de hand van de medewerkers thuislocatie en dienst locatie",
    "Projects.publicTransport":
      "Geef aan of de kosten met openbaar vervoer gedeclareerd mogen worden",
    "Projects.publicTransport.true": "Overige kosten zijn declarabel",
    "Projects.publicTransport.false":
      "Kosten gemaakt met openbaar vervoer zijn niet declarabel",
    "Projects.hourCorrection": "Uren correctie",
    "Projects.workingHoursUpdateable":
      "Selecteer of een uren correctie aangevraagd mag worden door de medewerker",
    "Projects.workingHoursUpdateable.true":
      "De medewerker mag een uren correctie inclusief een reden aanvragen",
    "Projects.workingHoursUpdateable.false":
      "Er mag geen uren correctie aangevraagd worden door de medewerker",
    "Projects.travelExpenses": "Reiskosten",
    "Projects.otherCosts": "Overige kosten",
    "Projects.otherCostsApplicable":
      "Geef aan of er overige kosten gedeclareerd mogen worden",
    "Projects.otherCostsApplicable.true": "Overige kosten zijn declarabel",
    "Projects.otherCostsApplicable.false":
      "Overige kosten zijn niet declarabel",
    "Projects.breakAdjustable": "Pauze is in te vullen door de medewerker",
    "Projects.travelTimeApplicable":
      "Selecteer of de reistijd ingevoerd mag worden",
    "Projects.kmDrivenApplicable":
      "Selecteer of de gereden kilometers ingevoerd mogen worden",
    "Projects.preCalculatedAndTravelTime.FalseFalse":
      "De reistijd wordt niet uitbetaald",
    "Projects.preCalculatedAndTravelTime.FalseTrue":
      "De medewerker kan de reistijd zelf invoeren en is niet vooraf berekend",
    "Projects.preCalculatedAndTravelTime.TrueFalse":
      "De reistijd wordt vooraf berekend, gebaseerd op de locatie van de dienst en de thuislocatie van de medewerker door google maps. De medewerker mag dit niet zelf aanpassen",
    "Projects.preCalculatedAndTravelTime.TrueTrue":
      "De reistijd wordt vooraf berekend, gebaseerd op de locatie van de dienst en de thuislocatie van de medewerker door google maps. De medewerker mag dit nog wel zelf aanpassen op het declaratieformulier.",
    "Projects.preCalculatedAndKmDriven.FalseFalse":
      "De gereden kilometers worden niet uitbetaald",
    "Projects.preCalculatedAndKmDriven.FalseTrue":
      "De medewerker kan de gereden kilometers zelf invoeren en is niet vooraf berekend",
    "Projects.preCalculatedAndKmDriven.TrueFalse":
      "De gereden kilometers worden vooraf berekend, gebaseerd op de locatie van de dienst en de thuislocatie van de medewerker door google maps. De medewerker mag dit niet zelf aanpassen",
    "Projects.preCalculatedAndKmDriven.TrueTrue":
      "De gereden kilometers worden vooraf berekend, gebaseerd op de locatie van de dienst en de thuislocatie van de medewerker door google maps. De medewerker mag dit nog wel zelf aanpassen op het declaratieformulier.",
    "Projects.questions": "Vragen",
    "Projects.question": "Vraag",
    "Projects.newQuestion": "Nieuwe vraag",
    "Projects.addQuestion": "Voeg vraag toe",
    "Projects.question.press-enter":
      "Druk op enter om je vraag/antwoord te bevestigen",
    "Projects.answer": "Antwoord {number}",
    "Projects.newAnswer": "Nieuw antwoord",
    "Projects.cancel": "Annuleren",
    "Projects.add-quiz": "Voeg quiz toe",
    "Projects.payment-method": "Betaalmethode",
    "Projects.payment-information": "Betaling informatie",
    "Projects.declaration-form": "Het declaratieformulier",
    "Projects.declaration-form-sub":
      "Selecteer de kosten die gedeclareerd mogen worden en of er een uren correctie mag worden aangevraagd",
    "Projects.hourly-rate": "Vast bedrag per uur",
    "Projects.reserve-rate": "Reserve bedrag",
    "Projects.briefing-pdf": "briefing_voor_project.pdf",
    "Projects.Single.totalJobs": "Totaal aantal diensten",
    "Projects.Single.openJobs": "Openstaande diensten",
    "Projects.Single.filledJobs": "Gevulde diensten",
    "Projects.Single.newJob": "Nieuwe dienst",
    "Projects.Single.published": "Gepubliceerd",
    "Projects.Single.unpublished": "Niet gepubliceerd",
    "Projects.Single.no-published":
      "Het was niet mogelijk om het project te publiceren",
    "Projects.add-briefing": "Voeg briefing toe (pdf)",
    "Projects.images": "Foto's",
    "Projects.projectSales": "Sales",
    "Projects.projectCoordinator": "Coördinator",
    "Projects.create": "Project aanmaken",
    "Projects.createAndPublish": "Aanmaken en publiceren",
    "Projects.category": "Functie",
    "Projects.created-quiz": "De nieuwe quiz is aangemaakt",
    "Projects.Details.pool": "Pools",
    "Projects.Details.poolDescription":
      "De diensten gelinkt aan dit project zijn zichtbaar voor de medewerkers die binnen de criteria vallen van de geselecteerde pools. Wil je de diensten zichtbaar maken voor iedereen? Voeg dan geen pool toe.<br />De diensten zijn nu zichtbaar voor %fu medewerkers. Wil je een uitzondering maken op een dienst? Dan is het ook mogelijk om een pool toe te voegen aan een dienst!",
    "Projects.Details.enterBranch": "Selecteer functie",
    "Projects.Details.enterTraining": "Selecteer training(en)",
    "Projects.Details.enterQualification": "Selecteer kwalificatie(s)",
    "Projects.pool.predefined": "Standaard pool",
    "Projects.pool": "Aangepaste project pool",
    "Projects.pool.predefined.switch": "Maak een aangepaste pool",
    "Projects.predefinedPools": "Selecteer een of meerdere standaard pool(s)",
    "Projects.createProject": "Maak project aan",
    "Projects.fillIn": "Invullen",
    "Projects.general": "Algemeen",
    "Projects.projectName": "Project naam",
    "Projects.jobCategory": "Functie",
    "Projects.fieldRequired": "Dit veld is verplicht",
    "Projects.select": "Selecteer",
    "Projects.client": "Opdrachtgever",
    "Projects.department.2": "Afdeling",
    "Projects.startEndDate": "Startdatum - Einddatum",
    "Projects.responsible": "Projectmanager",
    "Projects.description": "Beschrijving",
    "Projects.characters": "van de 1000 karakters",
    "Projects.payment": "Betaling",
    "Projects.projectSettings": "Project instellingen",
    "Projects.projectSettings.cardAutoScheduled.title":
      "Automatische planning (zonder goedkeuring)",
    "Projects.projectSettings.cardAutoScheduled.subTitle":
      "Selecteer of de interne & externe medewerkers eerst goedgekeurd moeten worden door de planner of dat deze direct ingepland mag worden",
    "Projects.projectSettings.cardAutoScheduled.InternalEmployees":
      "Interne medewerkers",
    "Projects.projectSettings.cardAutoScheduled.ExternalEmployees":
      "Externe medewerkers",
    "Projects.projectSettings.cardAutoScheduled.descriptonSwicthInternal":
      "Selecteer of een interne medewerker direct ingepland kan worden bij inschrijving (wie het eerst komt, het eerst maalt).",
    "Projects.projectSettings.cardAutoScheduled.descriptonSwicthExternal":
      "Selecteer of een externe medewerker van een connectie direct ingepland kan worden (zonder goedkeuring van de planner)",
    "Projects.paymentMethod": "Betalingsmethode",
    "Projects.chooseMethod": "Kies betalingsmethode",
    "Projects.amount": "Bedrag",
    "Projects.errorDescription":
      "De beschrijving is te lang, gebruik minder karakters",
    "Projects.reserveRate": "Reserve bedrag",
    "Projects.reserveRateOptional": "Reserve bedrag (Optioneel)",
    "Projects.new.details":
      "Dit project zal zichtbaar zijn voor de geselecteerde pools. Om het project zichtbaar te maken voor iedereen selecteer dan geen pool",
    "Projects.addPool": "Voeg pool toe",
    "Projects.Details.location": "Locatie (Optioneel)",
    "Projects.Details.location.2": "Locatie",
    "Projects.Details.date": "Datum",
    "Projects.location.address": "Straat",
    "Projects.location.city": "Plaats",
    "Projects.location.house_number": "Huisnummer",
    "Projects.location.house_number_ext": "Toevoeging",
    "Projects.location.zip_code": "Postcode",
    "Projects.projectCreated": "Het project is aangemaakt",
    "Projects.newProjectCreated": "Nieuw project aangemaakt",
    "Projects.somethingWrong": "Er ging iets fout",
    "Projects.load-error": "Er ging iets mis bij het laden van de project(en)",
    "Projects.fieldsRequired":
      "Projectnaam, functie, opdrachtgever, startdatum - einddatum, betaalmethode, bedrag, en reserve bedrag zijn verplicht",
    "Projects.no-clone": "Het was niet mogelijk om het project te kopiëren",
    "Projects.cloning": "Kopieer het project",
    "Projects.Pools.edit": "Pas pool aan",
    "Projects.Pools.error-load":
      "Er ging iets mis bij het laden van de geschikte medewerkers",
    "Projects.Pools.save-edit": "Het opslaan van de pool is gelukt!",
    "Projects.Pools.error-edit": "Het opslaan van de pool is niet gelukt :(",
    "Projects.projectNumber": "Projectnummer",
    "Projects.projectNamePlaceholder": "Voeg een projectnummer toe (optioneel)",
    "Projects.published": "Gepubliceerd",
    "Projects.unpublished": "Niet gepubliceerd",
    "Projects.filledJobs": "Gevulde diensten",
    "Projects.pendingJobs": "Diensten in afwachting",
    "Projects.filledPositions": "Gevulde posities",
    "Projects.nameTitle": "Titel",
    "Projects.visible": "Zichtbaar",
    "Projects.department": "Voeg locatie toe (optioneel)",
    "Projects.caduced":
      "De einddatum van het project is verstreken, het project is niet zichtbaar in de app",
    "Projects.publishedNoPending":
      "Het project is gepubliceerd er zijn alleen geen openstaande diensten. Het project is niet zichtbaar voor de medewerkers.",
    "Projects.publishedWithPending":
      "Het project is gepubliceerd en er staan %sjp diensten uit naar de medewerkers.",
    "Projects.noPublishedNoPending":
      "Het project is NIET gepubliceerd en er zijn geen openstaande diensten. Het project is niet zichtbaar voor de medewerkers.",
    "Projects.noPublishedWithPending":
      "Er zijn %sjp openstaande diensten, publiceer het project om de diensten zichtbaar te maken voor de geschikte medewerkers.",
    "Projects.type": "Type",
    "Projects.vacancy": "Dit is een vacature",
    "Projects.notVacancy": "Dit is een project",
    "Projects.vacancy.subtext.true":
      "Je hebt geselecteerd dat dit een vacature betreft, voer hier de benodigde uren per week per medewerker in",
    "Projects.vacancy.subtext.false":
      "Je hebt geselecteerd dat dit GEEN vacature is, de medewerkers zullen zich op de diensten inschrijven",
    "Projects.vacancy.hoursPerWeek": "Aantal uur per week",
    "Projects.vacancy.numberOfEmployees": "Aantal medewerkers",
    "Projects.fixed-rate": "Totaal vast bedrag",
    "Projects.appliedPools": "Toegepaste pool(s)",
    "Projects.notAppliedPools": "Er zijn geen pool(s) toegepast",
    "Projects.new.selectClient": "1. Selecteer opdrachtgever/locatie",
    "Projects.new.fillData": "2. Informatie",
    "Projects.template": "Project sjabloon",
    "Projects.clientTemplate": "Sjabloon project opdrachtgever",
    "Projects.departmentTemplate": "Sjabloon project locatie",
    "Projects.createTemplate":
      "Maak een standaard (sjabloon) project aan voor: [client_name]",
    "Projects.editTemplate": "Pas sjabloon project aan van: [client_name]",
    "Projects.teamInstructionsPdf": "Teaminstructies",
    "Projects.addTeamInstructions": "Voeg teaminstructies toe (pdf)",
    "Projects.breakRules": "Pauze regels",
    "Projects.breakApplicable.title":
      "Selecteer of de medewerker zijn pauze doorbetaald krijgt",
    "Projects.breakApplicable.subtitle":
      "Geef aan of er pauze kosten gedeclareerd mogen worden",
    "Projects.breakApplicable.true":
      "De pauze wordt verrekend met het aantal werkuren",
    "Projects.breakApplicable.false":
      "Er wordt geen pauze verrekend met het totaal aantal werkuren",
    "Projects.emptyState.title": "Er zijn nog geen projecten aangemaakt",
    "Projects.emptyState.description":
      "Klik op de paarse knop rechtsboven en maak een nieuw project aan.",
    "Projects.noProjects": "Geen projecten gevonden",
    "Projects.internalNote": "Interne notitie",
    "Projects.goToTablePreferences": "Ga naar tabel voorkeuren",
    "Projects.clientName": "Opdrachtgever",
    "Projects.city": "Stad",
    "Projects.toOldDesign": "Klik hier voor het oude overzicht?",
    "Projects.archived": "{items} projecten gearchiveerd",
    "Projects.advancedFilters.close": "Sluit zijbalk",
    "Projects.advancedFilters.title": "Alle filters",
    "Projects.archive.confirmation": `Je staat op het punt om
    {items, plural,
      one {# project}
      other {# projecten}    
    } te {archive, select,
      true {de-archiveren}
      false {archiveren}
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Projects.adjustLocationInfo": "Wijzig de locatie details",
    "Projects.adjustPaymentInfo": "Wijzig betaalinstellingen",
    "Projects.adjustQuiz": "Quiz aanpassen",
    "Projects.adjustEvaluation": "Evaluatielink aanpassen",
    "Projects.evaluationGuide.description":
      "De volgende velden kunnen in de evaluatielink worden vervangen, zodat deze automatisch door het systeem kunnen worden ingevuld wanneer de medewerkers de evaluatie openen vanaf de mobiel",
    "Projects.evaluationGuide.fields": `
      - Naam medewerker = [employee_full_name]<br/>
      - Personeelsnummer = [employee_custom_number]<br/>
      - Startdatum = [job_start_date]<br/>
      - Einddatum = [job_end_date]<br/>
      - Starttijd = [job_start_time]<br/>
      - Eindtijdb = [job_end_time]<br/>
      - Dienst locatie = [job_full_address]<br/>
      - Postcode dienst = [job_zipcode]<br/>
      - Interne notitie = [job_extra_note]<br/>
      - Inplanningsnummer = [custom_number]<br/>
      - Projectnaam = [project_name]<br/>
      - Functie = [job_category_name]`,
    "Projects.evaluationGuide.footer": `
      Om dit aan de bij het invullen van de evaluatie mee te geven, moet de tekst aan de rechterkant van de link worden geplaatst die overeenkomt met de informatie<br/><br/>
      <b>Voorbeeld</b>: <em>https://docs.google.com/forms/d/e/1FAIpQLSfh8ReUe8_yOAU4qg5m8_R1jQxm52rIg7E6r3sFb6vTtGpCJw/viewform?entry.1147536406=[employee_full_name]</em>`,
    "Projects.isThisArunningProject": "Is dit een doorlopend project?",
    "Projects.runningProjectDescription": `Bij een project kun je er voor kiezen om een een start- en einddatum om de periode aan te geven waar het project zich in plaatsvindt. Het is ook mogelijk om "projecten" te interpreteren als "afdeling" of "kostenplaats". Wanneer dit inderdaad het geval is, kan je de einddatum leeg laten. In dit geval zal Fleks het project altijd als "Lopend" beschouwen.`,
    "Projects.creation": "Project aanmaken",
    "Projects.publishProjectCreation": "Openstaande diensten publiceren", 
    "Projects.publishCreation": "Conceptplanning", 
    "Projects.publishProjectDetails": "Openstaande diensten gepubliceerd",
    "Projects.publishDetails": "Conceptplanning",
    "Projects.conceptPlanning" : "Conceptplanning",
    "Projects.conceptPlanningTrue": "Geactiveerd",
    "Projects.conceptPlanningFalse": "Niet actief",
    "Projects.notificationTitle": "Stuur bericht naar de komende inplanningen",
    "Projects.hoverpublishProjectCreation": "Als je openstaande diensten aan het project toevoegt, verschijnen ze op de 'Open jobs'-pagina in de app, zodat geschikte medewerkers zich kunnen inschrijven. Je kunt de openstaande diensten later altijd nog publiceren via de projectdetailpagina.",
    "Projects.hoverpublishProjectDetails": "Publiceer het project zodat de openstaande diensten zichtbaar zichtbaar worden op de 'Open jobs'-pagina en geschikte medewerkers zich kunnen inschrijven.",
    "Projects.hoverPublishCreation": "Wanneer de conceptplanning is geactiveerd, ontvangt de medewerker geen automatische berichten over de planning. In de app zijn alleen het project en de datum zichtbaar. Zo kun je de planning aanpassen zonder dat de medewerker direct meldingen krijgt. Let op: Vergeet niet de conceptplanning uit te zetten, anders kan het gebeuren dat de medewerker zijn dienst mist.",
    "Projects.hoverPublishDetails": "Activeer de conceptplanning om te zorgen dat er geen automatische berichten naar de medewerkers worden gestuurd. Zolang de conceptplanning aanstaat, ziet de medewerker in de app alleen het project en de datum van de dienst. Let op: Vergeet niet om de conceptplanning weer uit te zetten, anders kan de medewerker zijn dienst missen.",
    "Projects.notificationMessageConceptPlanningTrue": "Conceptplanning is geactiveerd, er worden geen automatische berichten verstuurt bij het inplannen",
    "Projects.notificationMessageConceptPlanningFalse": "Concept planning is gedeactiveerd, de dienst details zijn zichtbaar voor de medewerkers in de app onder 'mijn jobs'",
    "Projects.notificationMessagepublishProjectTrue": "De openstaande diensten zijn gepubliceerd. Medewerkers kunnen zich inschrijven via de app. Stuur een notificatie naar de juiste medewerkers om zo snel mogelijk inschrijvingen te ontvangen.",
    "Projects.notificationMessagepublishProjectFalse": "Het project is niet gepubliceerd, het is niet meer mogelijk voor de medewerkers om zich in te schrijven voor openstaande diensten.",
    "Projects.detailsModal.sidebar.title": "Project overzicht",
    "Projects.detailsModal.sidebar.fromStartDateToEndDate":
      "{start_date} tot {end_date}",
    "Projects.detailsModal.sidebar.selectDate": "Selecteer een dag:",
    "Projects.detailsModal.content.subtitleOcupation": "Planning",
    "Projects.detailsModal.content.tableColumn.NoName":
      "<span class='avenir-next-italic'>Open positie</span>",
    "SelectClientList.title": "Zoek opdrachtgever",
    "SelectClientList.select": "Selecteer opdrachtgever",
    "SelectClientList.selectDepartment": "Selecteer locatie",
    "Sidebar.dashboard": "Dashboard",
    "Sidebar.planner": "Planner",
    "Sidebar.newPlanner": "Nieuwe Planner",
    "Sidebar.scheduler": "Roosterbord",
    "Sidebar.database": "Database",
    "Sidebar.newDatabase": "Nieuwe Database",
    "Sidebar.newHours": "Nieuwe uren",
    "Sidebar.newFreelancers": "Nieuwe freelancers",
    "Sidebar.freelancers": "Freelancers",
    "Sidebar.clients": "Opdrachtgevers",
    "Sidebar.projects": "Projecten",
    "Sidebar.jobs": "Diensten",
    "Sidebar.jobRequests": "Dienst aanvragen",
    "Sidebar.newEmployers": "Nieuwe werkgevers",
    "Sidebar.employers": "Werkgevers",
    "Sidebar.employees": "Medewerkers",
    "Sidebar.applicants": "Sollicitanten",
    "Sidebar.hours": "Urenregistratie",
    "Sidebar.create-project": "Nieuw project",
    "Sidebar.create-client": "Nieuwe opdrachtgever",
    "Sidebar.create-quiz": "Maak quiz aan",
    "Sidebar.create-employee": "Nieuwe medewerker",
    "Sidebar.shifts": "Inplanningen",
    "Sidebar.singles": "Per inplanning",
    "Sidebar.singlesInternal": "Intern per inplanning",
    "Sidebar.internals": "Intern",
    "Sidebar.totals": "Per medewerker",
    "Sidebar.totalsInternal": "Intern per medewerker",
    "Sidebar.allHours": "Alle uren",
    "Sidebar.invoices": "Facturen",
    "Sidebar.archive": "Archief",
    "Sidebar.company": "Bedrijfsinformatie voor de app",
    "Sidebar.hr": "HR & Financiën",
    "Sidebar.admin": "Admin",
    "Settings.selectExportTemplate": "Selecteer export template",
    "Settings.selectRateCard": "Selecteer tariefkaart",
    "ExportModal.surchargeExport": "Toeslag export",
    "Settings.page.admin.deactivate": "Deactiveren",
    "Settings.page.admin.deactivateSuccess": "De gebruiker is gedeactiveerd",
    "Settings.page.admin.activateSuccess": "De gebruiker is geactiveerd",
    "Settings.page.admin.archiveSuccess":
      "De workflow sjabloon is gearchiveerd",
    "Clients.timesheets": "Doorlopende opdrachten",
    "Clients.timesheets.management": "Toegang tot doorlopende opdrachten",
    "Clients.timesheets.management.description":
      "Doorlopende opdrachten zijn bedoeld voor medewerkers die op langdurige basis direct op de werkvloer bij de opdrachtgever werkzaam zijn. Het rooster wordt direct tussen de flexkracht en de opdrachtgever besproken. De medewerker kan de afgesproken diensten zelf direct in de mobiele app aanmaken. Door medewerkers toe te voegen aan projecten van de opdrachtgever, hebben zij de mogelijkheid om de afgesproken diensten die in de toekomst plaatsvinden te registreren, maar ook om van gewerkte diensten in het verleden de uren te schrijven. De uren dienen op dezelfde manier geaccordeerd te worden als de normale diensten.",
    "Clients.timesheets.addEmployee": "Voeg medewerker toe",
    "Clients.timesheets.addProjects": "Selecteer de projecten",
    "Clients.timesheets.editProjects": "Pas toegang tot de projecten aan",
    "Clients.timesheets.linkProjects": "Bevestig project(en) toegang",
    "Sidebar.templates": "Sjablonen",
    "Sidebar.template-project": "Standaard projecten",
    "Sidebar.make-salary-run": "Start salarisrun",
    "Sidebar.days": "dagen",
    "Sidebar.externalConnections": "Externe verbindingen",
    "Sidebar.directPay": "Directe betaling",
    "Sidebar.directPayDescription": "Directe betaling gekozen door medewerker",
    "Pictures.viewer": "Bekijk de foto's",
    "Employees.creation": "Medewerker(s) aanmaken",
    "Employees.availability.dateSelector": "Selecteer een datum of periode",
    "Employees.availability.weekSelector": "Selecteer de dagen van de week",
    "Employees.availability.availabilitySelector":
      "Selecteer de beschikbaarheid",
    "Employees.description": "Je wijziging is opgeslagen",
    "Employees.errorSaveMessage":
      "Je wijzigingen konden niet worden opgeslagen.",
    "Employees.create-employee": "Nieuwe medewerker",
    "Employees.information": "Informatie",
    "Employees.create-new-employee": "Medewerker aanmaken",
    "Employees.loginCredentials": "Inloggegevens",
    "Employees.resetEmail": "Stuur reset wachtwoord email",
    "Employees.resetPassword":
      "Er zullen instructies naar de medewerker gestuurd worden voor het resetten van het wachtwoord",
    "Employees.sendPassword":
      "Email voor het verzoek van een wachtwoord reset is verzonden naar: %em",
    "Employees.email": "Email",
    "Employees.password": "Wachtwoord",
    "Employees.initials": "Voorletters",
    "Employees.first_name": "Voornaam",
    "Employees.full_name": "Naam",
    "Employees.prefix": "Tussenvoegsel",
    "Employees.surname": "Achternaam",
    "Employees.last_name": "Achternaam",
    "Employees.birth_date": "Geboortedatum",
    "Employees.gender": "Geslacht",
    "Employees.street": "Straat",
    "Employees.street.2": "Straat",
    "Employees.state": "Stad",
    "Employees.zip-code": "Postcode",
    "Employees.mobile": "Telefoonnummer",
    "Employees.personalNumber": "Personeelsnummer",
    "Employees.creation.basicDetails": "Basis gegevens",
    "Employees.creation.addressDetails": "Adresgegevens",
    "Employees.schedule": "Inplannen",
    "Employees.reInvite": "Opnieuw uitnodigen",
    "Employees.schedule.confirmation": `Je staat op het punt om {items, plural,
        one {# medewerker}
        other {# medewerkers}    
      } in te plannen voor een dienst die al is gestart, weet je zeker dat je deze actie wilt uitvoeren?`,
    "Employees.reject": "Afwijzen",
    "Employees.accept": "Aanvaarden",
    "Employees.propose": "Voorstellen",
    "Employees.propose.confirmation": `Je staat op het punt om {items, plural,
        one {# medewerker}
        other {# medewerkers}    
      } voor te stellen voor een dienst die al gestart is, weet je zeker dat je deze actie wilt uitvoeren?`,
    "Employees.proposeExit": "Stel voor en afsluiten",
    "Employees.scheduleAndPropose": "Inplannen & voorstellen",
    "Employees.uninvite": "Intrekken",
    "Employees.cancel": "Annuleren",
    "Employees.distance": "Afstand",
    "Employees.numberOfEmployees": "Aantal werknemers",
    "Employees.invalidFormat": "Je hebt iets fout ingevoerd",
    "Employees.onlyText": "Alleen letters zijn mogelijk",
    "Employees.language": "Taal",
    "Employees.dutch": "Nederlands",
    "Employees.english": "Engels",
    "Employees.weekHours": "Weekuren",
    "JobRequests.title": "Dienst aanvragen",
    "Jobs.expandTableItems": "Klap de geselecteerde items uit",
    "Jobs.collapseTableItems": "Klap de geselecteerde items in",
    "Jobs.updateJobsSpecifications": "Informatie aanpassen",
    "Jobs.updateJobsSpecifications.allUpdated": "Alle diensten zijn bijgewerkt",
    "Jobs.updateJobsSpecifications.allNotUpdated":
      "Het was niet mogelijk om de diensten aan te passen",
    "Jobs.updateJobsSpecifications.oneNotUpdated":
      "Een of meer items zijn niet bijgewerkt, controleer de diensten",
    "Jobs.updateJobsSpecifications.onePartiallyUpdated":
      "Een of meer items zijn gedeeltelijk bijgewerkt, controleer de diensten",
    "Jobs.title": "Diensten",
    "Jobs.newJob": "Dienst",
    "Jobs.all": "Alle aankomende",
    "Jobs.allJobs": "Alle",
    "Jobs.filled": "Gevuld",
    "Jobs.pending": "Openstaand",
    "Jobs.past": "Afgelopen",
    "Jobs.open": "Openstaand",
    "Jobs.project": "Project",
    "Jobs.projectManager": "Projectmanager",
    "Jobs.sales": "Sales",
    "Jobs.connection": "Connectie",
    "Jobs.acceptJob": "Dienst aanvraag accepteren",
    "Jobs.rejectJob": "Dienst aanvraag afwijzen",
    "Jobs.requests": "Dienst aanvragen",
    "Jobs.request.accepted": "De aanvraag is geaccepteerd",
    "Jobs.request.rejected": "De aanvraag is afgewezen",
    "Jobs.request.error": "Er ging iets mis bij het verwerken van je keuze",
    "EmployeeList.unSetException":
      "Uitzondering kan niet ongedaan worden gemaakt",
    "WorkFlow.unarchive": "Ga naar archief om te de-archiveren",
    "EmployeeList.unCancel": "Opnieuw inplannen",
    "EmployeeList.notExpired": "Opnieuw inplannen",
    "Employees.intake": "Contactmogelijkheid",
    "Employees.dateCreatedMin": "Datum aangemaakt (min)",
    "Employees.dateCreatedMax": "Datum aangemaakt (max)",
    "Employees.archived": "{items} medewerkers gearchiveerd",
    "Employees.archivedinfo":
      "Gearchiveerd op: - {archivedAt}<br/> Gearchiveerd door: - {archivedBy}",
    "Employees.export": "Exporteren",
    "Employees.sendOnboarding": "Stuur de onboarding email",
    "Employees.sendMessage": "Stuur een bericht",
    "Employees.additionalFields": "Vrije velden",
    "Employees.nameOfTheField": "Naam van het veld",
    "Employees.contentOfTheField": "Inhoud van het veld",
    "Employees.single.setCards": "Setkaart",
    "Employees.single.collection.title": "Collectie",
    "Employees.single.collection.description": "Vul je collectie aan met geschikte foto’s voor op de setkaarten.",
    "Employees.single.collection.upload.photo.title": "Kies foto",
    "Employees.single.collection.upload.photo.description": "Klik hier en upload foto’s",
    "Employees.setCardsError": "* <b>{item}</b> afbeeldingen vereist",
    "Employees.setCards": "maak setkaart aan",
    "Employees.setCardsModal.description": "Maak een setkaart aan voor je medewerkers door een top drie aan foto’s te kiezen. Geef aan wat de kenmerken van de medewerker zijn. Bekijk het voorbeeld, ben je tevreden? Klik op genereer setkaart.",
    "Employees.setCardsModal.dropdownOne.title": "Algemeen",
    "Employees.setCardsModal.dropdownTwo.title": "Kenmerken",
    "Employees.setCardsModal.dropdownThree.title": "Vrije velden",
    "Employeees.setCardsmodal.submitButton": "Setkaarten genereren",
    "Hours.extraCost": "Extra kosten",
    "Hours.acceptedTab": "Geaccepteerd",
    "Jobs.occupation": "Bezetting",
    "Jobs.address": "Adres",
    "Jobs.city": "Stad",
    "Jobs.time": "Start-eindtijd",
    "Jobs.date": "Datum",
    "Jobs.dates": "Datums",
    "Jobs.cityNote": "Stad/Notitie",
    "Jobs.export": "Exporteren",
    "Jobs.houseNumber": "Huisnummer",
    "Jobs.houseNumber2": "Nummer",
    "Jobs.houseNumberExt": "Huisnummer toevoeging",
    "Jobs.houseNumberExt2": "Toevoeging",
    "Jobs.zipCode": "Postcode",
    "Jobs.extraNote": "Interne notitie",
    "Jobs.logo": "Logo",
    "Jobs.projectName": "Projectnaam",
    "Jobs.client": "Opdrachtgever",
    "Jobs.coordinator": "Coördinator",
    "Jobs.startDate": "Startdatum",
    "Jobs.endDate": "Einddatum",
    "Jobs.zipCodeMin": "Postcode (min)",
    "Jobs.zipCodeMax": "Postcode (max)",
    "Jobs.resetFilters": "Verwijder filters",
    "Jobs.Single.employees": "Medewerkers",
    "Jobs.Single.scheduled": "Ingepland",
    "Jobs.Single.pending": "Openstaand",
    "Jobs.Single.details": "Details",
    "Jobs.Single.media": "Media",
    "Jobs.Single.instructions": "Instructies",
    "Jobs.Single.jobSettings": "Dienst instellingen",
    "Jobs.Details.workLocation": "Dienst locatie",
    "Jobs.Details.streetName": "Straatnaam",
    "Jobs.Details.number": "Huisnummer",
    "Jobs.Details.addition": "Toevoeging",
    "Jobs.Details.zipCode": "Postcode",
    "Jobs.Details.city": "Stad",
    "Jobs.Details.employees": "Medewerkers",
    "Jobs.Details.dateTime": "Datum & tijd",
    "Jobs.Details.pool": "Huidige pool",
    "Jobs.Details.addPool": "Voeg een pool toe",
    "Jobs.Details.updatePool": "Update pool",
    "Jobs.Details.date": "Datum",
    "Jobs.Details.time": "Tijd",
    "Jobs.Details.maxCancellationTime": "Max. annuleringstijd",
    "Jobs.Details.maximumCancellationTime": "Maximale annuleringstijd",
    "Jobs.Details.description": "Beschrijving",
    "CreationFlow.modal.success.title": "Gelukt!",
    "CreationFlow.modal.success.description":
      "Je hebt <b>{items}</b> dienst(en) aangemaakt.",
    "CreationFlow.modal.success.description.project":
      "Je hebt zojuist het project: <b>{project_name}</b> aangemaakt.",
    "CreationFlow.modal.success.description.employee":
      "Je hebt <b>{items}</b> medewerker(s) aangemaakt.",
    "CreationFlow.modal.actions.sendAMessage": "Stuur een bericht",
    "CreationFlow.modal.actions.shareJobs": "Dienst delen",
    "CreationFlow.modal.actions.publishOnAdmin":
      "Publiceer dienst op {admin_name}",
    "CreationFlow.modal.actions.goToTheJobsTable": "Ga naar diensten tabel",
    "CreationFlow.modal.actions.done": "Afronden",
    "CreationFlow.modal.actions.createJobs": "Maak gelijk diensten aan",
    "CreationFlow.modal.actions.goToProjectDetails": "Bekijk detail pagina",
    "CreationFlow.modal.actions.goToProjectsTable":
      "Ga terug naar tabel overzicht",
    "CreationFlow.modal.actions.scheduleNow": "Direct inplannen",
    "CreationFlow.modal.actions.goToTheEmployeesTable": "Ga naar de tabel",
    "Jobs.Details.documents": "Documenten",
    "Jobs.Details.address-description": "Adres beschrijving",
    "Jobs.Details.project": "Project",
    "Jobs.Details.jobRequestDetails.title": "Dienst aanvraag details",
    "Jobs.Details.jobRequestDetails.employees":
      "Aantal aangevraagde medewerkers",
    "Jobs.Details.jobRequestDetails.remark": "Opmerking",
    "Jobs.planSomeoneIn": "Inplannen",
    "Jobs.searchForAnEmployee": "Zoeken",
    "Jobs.schedule": "Medewerker inplannen",
    "Jobs.addressDescription": "Adres beschrijving",
    "Jobs.hours": "Uren",
    "Jobs.name": "Adres",
    "Jobs.type": "Dienst type",
    "Jobs.workflow": "Workflow-sjablonen",
    "Jobs.selectWorkflow": "Selecteer sjabloon",
    "Jobs.new": "Nieuwe dienst",
    "Jobs.fullAddress": "Locatie dienst",
    "Jobs.teamInstructions.successUpload": "De teaminstructies zijn toegevoegd",
    "Jobs.teamInstructions.errorUpload":
      "Er is een probleem opgetreden bij het uploaden van de teaminstructies",
    "Jobs.Details.poolDescription":
      "Zet je dienst uit naar de groep medewerkers die geschikt zijn om hier te werken. Moet de dienst zichtbaar zijn voor iedereen of de pool van het project? voeg dan geen pool toe. Je dienst is nu zichtbaar voor %fu medewerkers.",
    "Jobs.plannedJobTimes": "Geplande diensttijden",
    "Jobs.emptyState.title": "Er zijn nog geen diensten aangemaakt",
    "Jobs.emptyState.description":
      "Klik op de paarse knop rechtsboven en maak een nieuwe dienst aan.",
    "Jobs.shareJobs.emptyState.title": "Diensten delen in Fleks",
    "Jobs.shareJobs.emptyState.description": `Werken jullie samen met externe partijen om de diensten op te vullen? Je kunt deze diensten nu ook direct vanuit Fleks sturen. Zo kun je toch de voortgang van je planning toch nog centraal in Fleks houden.<br/><br/>Neem contact op met Fleks voor een verdere toelichting of stuur een mail naar <a href:"mailto:support@fleks.info">support@fleks.info</a> om de connectie op te vragen. Zorg er dan wel voor dat de samenwerkende partij hier ook van op de hoogte is.`,
    "Jobs.shareJobs.emptyState.closeModal": "Duidelijk! Sluit weergave",
    "Jobs.shareJobs.confirmation": `Je staat op het punt om {items, plural,
      one {# job}
      other {# jobs}    
    } dienst(en) te delen die al gestart zijn of in het verleden liggen, weet je zeker dat je deze actie wilt doorvoeren?`,
    "Jobs.shareJobs.restrictShareJobWithIncomingEmployee":
      "Je kunt deze dienst niet delen met een ander bedrijf aangezien een of meer inkomende werknemers op deze dienst zijn ingepland.",
    "Jobs.sharedJobs.restrictMultipleShareJobWithIncomingEmployee": `Je kunt deze geselecteerde dienst(en) niet delen omdat {incomingEmployees} diensten een extern ingeplande medewerker hebben`,
    "Jobs.timeNoCluster": "Tijd (is niet getoond in een cluster)",
    "Jobs.numberOfPendingSpots": "Status en aantal openstaande posities",
    "Jobs.numberOfSchedules": "Aantal inplanningen",
    "Jobs.startTime": "Starttijd",
    "Jobs.endTime": "Eindtijd",
    "Jobs.requested": "Aangevraagd",
    "Jobs.requested.resume": "Aangevraagd ({totalAccepted}/{totalShared})",
    "Jobs.shared": "Gedeelde",
    "Jobs.sharedAt": "Aangevraagd op",
    "Jobs.cancelledAt": "Geannuleerd op",
    "Jobs.cancelledBy": "Geannuleerd door",
    "Jobs.employeeRequested": "Aantal medewerkers aangevraagd {tenant_name}",
    "Jobs.remarkMessage": "Opmerking",
    "Jobs.employeeRequested.supplier": "Aangevraagd door {tenant_name}",
    "Jobs.scheduledEmployees": "Ingeplande medewerkers",
    "Jobs.appliedEmployees": "Ingeschreven medewerkers",
    "Jobs.owner": "Eigenaar",
    "Jobs.accepted": "Geaccepteerd",
    "Jobs.accepted.resume": "Geaccepteerd ({totalAccepted}/{totalShared})",
    "Jobs.accepted.by": "Van ({tenantName})",
    "Jobs.cancelled": "Geannuleerd",
    "Jobs.cancelled.resume": "Geannuleerd ({totalAccepted}/{totalShared})",
    "Jobs.notRequested": "Niet aangevraagd",
    "Jobs.alreadyFilled": "Al gevuld",
    "Jobs.createTemplate":
      "Maak standaard diensten aan voor: {client_name} - {standard_project_name}",
    "Jobs.searchCandidates": "Zoek geschikte medewerkers",
    "Jobs.times": "Tijd",
    "Jobs.street": "Straatnaam",
    "Jobs.internalNote": "Interne notitie",
    "Jobs.teamInstructions": "Teaminstructies",
    "Jobs.teamInstructions.choice1": "Ja",
    "Jobs.teamInstructions.choice2": "Nee",
    "Jobs.jobStatus": "Status, inschrijvingen en uitnodigingen",
    "Jobs.archive.confirmation": `Je staat op het punt om
    {items, plural,
      one {# dienst}
      other {# diensten}    
    } te {archive, select,
      true {de-archiveren}
      false {archiveren}
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Jobs.cancel.confirmation": `{withMyEmployees, select,
      true {One or more jobs have at least of one your employees working on it.}
      false {}
    } Alle inplanningen van externe partijen zullen worden geannuleerd, wil je doorgaan?`,
    "Jobs.cancel.success": `{items, plural,
      one { # dienst geannuleerd}
      other { # diensten geannuleerd }
    }`,
    "Jobs.cancelJob": "Dienst annuleren",
    "Jobs.cancelJobs": "Annuleer dienst(en)",
    "Jobs.ReasonOptional": "Reden (optioneel)",
    "Jobs.cancellationPolicyWarning":
      "Je kunt de dienst tot <b>{day}</b> <b>{month_name}</b> <b>{year}</b> vrijblijvend annuleren, weet je zeker dat je de dienst wilt annuleren?",
    "Jobs.activeApplicationsWarning":
      "Er zijn nog een of meerdere geldige inschrijvingen voor deze dienst, door de actie te bevestigen worden ze automatisch geannuleerd.",
    "Jobs.cancelSuccessful": "Dienst is geannuleerd",
    "Jobs.DateTimeModal.title": "Datum en tijd aanpassen",
    "Jobs.plannedEmployees": "Ingepland",
    "Jobs.proposalEmployees": "Voorgesteld",
    "Jobs.proposedEmployees": "Voorgesteld",
    "Jobs.registeredEmployees": "Ingeschreven",
    "Jobs.invitedEmployees": "Uitgenodigd",
    "Jobs.adjustDescription": "Wijzig beschrijving",
    "Jobs.adjustAddressDescription": "Wijzig dienst adres",
    "Jobs.archived": `{items, plural,
      one { # dienst gearchiveerd }
      other { # diensten gearchiveerd }
    }`,
    "Jobs.proposeEmployee": "Voorstellen",
    "Jobs.import.error":
      "Er is een fout opgetreden bij het lezen van het Google-blad. Zorg ervoor dat de kolomnamen overeenkomen, met de template/sjabloon.",
    "Jobs.plannedFreelancers": "Ingeplande freelancers",
    "Jobs.appliedFreelancers": "Ingeschreven freelancers",
    "Jobs.appliedFreelancers.applicationDate": "Ingeschreven op",
    "Jobs.positionsOfTheJob": "Aantal posities van de dienst",
    "Jobs.jobCategory": "Functie",
    "Jobs.jobFunction": "Dienst functie",
    "Jobs.details.single": "Een dag",
    "Jobs.details.multiple": "Meerdere dagen",
    "Jobs.details.repetitiveJobs": "Repeterende dienst",
    "Jobs.details.dateAndTime": "Datum en tijd",
    "Jobs.details.from": "Van",
    "Jobs.details.until": "Tot",
    "Jobs.details.description": "Beschrijving (optioneel)",
    "Jobs.description.title": "Functieomschrijving",
    "Jobs.selectFileToImport": "Selecteer een te importeren bestand",
    "Jobs.description1":
      "Dupliceer het <a href='https://docs.google.com/spreadsheets/d/1MTRrhjcDpj5jquhJo5iMJYILS00R-MCvO9TwGXgEDvM/edit#gid=0' target='_blank'>volgende Google Sheet</a> voorbeeld naar jouw eigen Google Drive account.",
    "Jobs.description2":
      "Is het import bestand op een correcte manier ingevuld? Dan kan je dit bestand kiezen door op de onderste knop te klikken en beginnen met importeren.",
    "Jobs.description3":
      "Het limiet voor de Personeelsnummers voor een taak is 70. Daarnaast kun je in totaal 50 diensten importeren inclusief Personeelsnummer",
    "Jobs.associateProject":
      "Onder welk project wil je de diensten importeren?",
    "Jobs.selectAProject": "Kies een project",
    "Jobs.chooseTheFile": "Kies het bestand",
    "Jobs.chooseTheFileFromGoogle": "Kies een bestand via google",
    "Jobs.chooseTheFileFromLocal": "Kies een bestand van je computer",
    "Jobs.warnings.planInAndPropose":
      "Je hebt eigen en externe diensten geselecteerd, weet je zeker dat je wilt inplannen & voorstellen?",
    "Jobs.warnings.numberOfEmployees":
      "Het is niet mogelijk om het aantal medewerkers aan te passen, op één of meer diensten staan meer medewerkers ingepland",
    "Jobs.creation.warning.customNumbersLimit":
      "Het is niet mogelijk om de dienst aan te maken, omdat het limiet van het aantal Personeelsnummers 70 is.",
    "Jobs.creation.warning.JobLimitWithCustomNumbers":
      "Het is niet mogelijk om dienst aan te maken, omdat het limiet voor het maken van diensten inclusief personeelsnummers 50 is",
    "Jobs.goToProjects": "Ga naar project",
      "NewEmployers.title": "Nieuwe werkgevers",
    "NewEmployers.companyName": "Bedrijfsnaam",
    "NewEmployers.city": "Stad",
    "NewEmployers.address": "Adres",
    "NewEmployers.createdAt": "Aanmaak datum",
    "NewEmployers.contactPerson": "Contact medewerker",
    "NewEmployers.emptyState.title": "Geen nieuwe werkgevers gevonden",
    "NewEmployers.emptyState.description": "",
    "NewEmployers.contactPersonFirstName": "Voornaam contactpersoon",
    "NewEmployers.verified": "{items} werkgevers geverifieerd",
    "NewEmployers.verify.confirmation": `Je staat op het punt om {items, plural,
      one {# werkgever}
      other {# werkgevers}    
    } te verifiëren, weet je zeker dat je deze actie wilt doorvoeren?`,
    "Employers.title": "Werkgevers",
    "Employers.companyName": "Bedrijfsnaam",
    "Employers.city": "Stad",
    "Employers.address": "Adres",
    "Employers.activationDate": "Activatie datum",
    "Employers.createdAt": "Aanmaak datum",
    "Employers.contactPerson": "Contact medewerker",
    "Employers.emptyState.title": "Geen werkgevers gevonden",
    "Employers.emptyState.description": "",
    "Employers.contactPersonFirstName": "Voornaam contactpersoon",
    "Employers.chamberOfCommerceNumber": "KvK nummer",
    "Employees.title": "Medewerkers",
    "Employees.incoming.title": "Externe medewerkers",
    "Employees.internals": "Intern",
    "Employees.externals": "Extern",
    "Employees.newEmployee": "Medewerkers toevoegen",
    "Employees.all": "Alle",
    "Employees.name": "Naam",
    "Employees.number": "Telefoon",
    "Employees.city": "Stad",
    "Employees.comments": "Opmerkingen",
    "Employees.editComments": "Opmerking bewerken",
    "Employees.editComments.success": "Reactie succesvol bewerkt",
    "Employees.comments.extra": "Belnotities",
    "Employees.age": "Leeftijd",
    "Employees.lastActive": "Laatste inschrijving",
    "Employees.activeSince": "Actief sinds",
    "Employees.firstLogin": "Eerste login",
    "Employees.lastLogin": "Laatst ingelogd",
    "Employees.phase": "Fase",
    "Employees.houseNumber": "Huisnummer",
    "Employees.houseNumberExt": "Toevoeging (optional)",
    "Employees.idType": "ID type",
    "Employees.documentNumber": "Documentnummer",
    "Employees.dateCreation": "Aangemaakt op",
    "Employees.lastInvitation": "Uitgenodigd op",
    "Employees.bankAccountNumber": "Bankrekeningnummer",
    "Employees.initial": "Voorletters",
    "Employees.bsn": "Burgerservicenummer (BSN)",
    "Employees.startDate": "Startdatum",
    "Employees.endDate": "Einddatum",
    "Employees.firstName": "Voornaam",
    "Employees.lastName": "Achternaam",
    "Employees.locationRadius": "Locatie max (km)",
    "Employees.noMaxLocationRadius": "Geen max KM's",
    "Employees.locationKm": "Max (km)",
    "Employees.zipCodeFrom": "Postcode vanaf",
    "Employees.zipCodeFromMin": "Postcode (min)",
    "Employees.zipCodeTill": "Postcode tot",
    "Employees.zipCodeTillMax": "Postcode (max)",
    "Employees.categories": "Categorieën",
    "Employee.specifications": "Medewerker specificaties",
    "Employees.categoryName": "Function name",
    "Employees.activeContract": "Actief contract",
    "Employees.whitContract": "Zonder contract",
    "Employees.drivingLicense": "Rijbewijs",
    "Employees.car": "Auto",
    "Employees.sectors": "Functies",
    "Employees.trainings": "Trainingen",
    "Employees.qualifications": "Kwalificaties",
    "Employees.travelInformation": "Reisinformatie",
    "Employees.additionalInformation": "Aanvullende informatie",
    "Employees.travelSpecifications": "Reisspecificaties",
    "Employees.faculties": "Specificaties",
    "Employees.addFaculties": "Specificatie aanpassen",
    "Employees.resetFilters": "Verwijder filters",
    "Employees.IBAN": "IBAN",
    "Employees.socialSecurityNumber": "BSN nummer",
    "Employees.expirationDate": "Vervaldatum",
    "Employees.frontSideId": "Voorkant ID",
    "Employees.backSideId": "Achterkant ID",
    "Employees.viewImg": "Bekijken",
    "Employees.upload": "Upload",
    "EmployeeModal.title": "Informatie Medewerker exporteren",
    "Employees.phoneNumber": "Telefoonnummer",
    "Employees.validation.birthDate": "Minimaal 18 jaar",
    "Employees.validation.zipCode":
      "Postcode moet 4 cijfers bevatten en daarna 2 letters.",
    "Employees.validation.password":
      "Wachtwoord moet minimaal 8 karakters en maximaal 32 karakters bevatten.",
    "Employees.validation.email": "E-mail niet bekend.",
    "Employees.validation.email.length":
      "E-mail moet minimaal 8 karakters bevatten.",
    "Employees.validation.fields": "Sommige velden zijn verkeerd ingevoerd.",
    "Employees.validation.fields.description":
      "De medewerker is aangemaakt, maar nog niet geverifieerd aangezien er velden fout ingevuld waren. Je kan hem/haar vinden bij sollicitanten.",
    "Employees.validation.exists": "De medewerker bestaat al.",
    "Employees.registrationDate": "Registratie datum",
    "Employees.circlePhase": "%n heeft %t keer gewerkt afgelopen 30 dagen.",
    "Employees.checkPhase": "%n heeft zijn ID & bankgegevens opgegeven",
    "Employees.checkPhase2": "%n heeft nog geen ID & bankgegevens opgegeven",
    "Employees.checkPhase3":
      "%n heeft nog geen profielfoto toegevoegd en/of de NAWT gegevens nog niet compleet gemaakt",
    "Employees.filePhase": "%n heeft momenteel geen contract",
    "Employees.filePhase2":
      "%n heeft momenteel het volgende contract getekend: %cn, het contract eindigt op %ed",
    "Employees.filePhase3":
      "%n heeft het volgende contractaanbod: %cn - het contract eindigt op %ed",
    "Employees.filePhase4":
      "%n heeft momenteel het volgende contract getekend: %cn - het contract eindigt op %ed. Er staat een nieuw contractvoorstel klaar: %ucn - dit contract start op: %sd",
    "Employees.filePhase5":
      "Het volgende contractvoorstel staat klaar: %ucn - dit contract start op: %sd",
    "Employees.birthDate": "Geboortedatum",
    "Employees.birthDate.2": "Geboortedatum",
    "Employees.birthDate.3": "Geboortedatum",
    "Employees.address": "Adres",
    "Employees.jobCategory": "Job categorie",
    "Employees.isVerified": "Fase 1",
    "Employees.isRegComplete": "Fase 2",
    "Employees.birthDateDtGte": "Leeftijd (max)",
    "Employees.birthDateDtLte": "Leeftijd (min)",
    "Employees.socialNumber": "Burgerservicenummer",
    "Employees.wageTaxRebate": "loonheffingskorting",
    "Employees.wageTaxRebateDate": "loonheffingsdatum datum wijziging",
    "EmployeesImport.title": "Importeer medewerkers",
    "EmployeesImport.sheetName": "Sheet naam",
    "EmployeesImport.initialCell": "Cel linksboven",
    "EmployeesImport.finalCell": "Cel rechtsonder",
    "EmployeesImport.reserve": "Reserve",
    "EmployeesImport.quiz": "Quiz van toepassing",
    "Employees.minNumberTimes": "Aantal keer gewerkt (min)",
    "Employees.maxNumberTimes": "Aantal keer gewerkt (max)",
    "Employees.stepsEqual": "Registratiestatus",
    "Employees.stepsGte": "Registratiestatus",
    "Employees.stepsLte": "Registratiestatus",
    "Employees.phase_1_incomplete": "Fase 1 incompleet",
    "Employees.phase_1_complete": "Fase 1 compleet",
    "Employees.phase_2_incomplete": "Fase 2 incompleet",
    "Employees.phase_2_complete": "Fase 2 compleet",
    "Employees.isApproved": "Geaccepteerd",
    "Employees.isExported": "Verwerkt",
    "Employees.startPeriodJobs": "Start periode dienst",
    "Employees.endPeriodJobs": "Eind periode dienst",
    "Employees.numberOfJobsMin": "Aantal diensten (min)",
    "Employees.numberOfJobsMax": "Aantal diensten (max)",
    "Employees.hasWorked": "Heeft gewerkt",
    "Employees.wageTaxRebateGte": "Wijziging loonheffing (min)",
    "Employees.wageTaxRebateLte": "Wijziging loonheffing (max)",
    "Employees.hasWorkedGte": "Eerste werkdag (min)",
    "Employees.hasWorkedLte": "Eerste werkdag (max)",
    "Employees.fullName": "Naam",
    "Employees.search": "Zoek medewerker",
    "Employees.newEmployees": "Nieuwe medewerkers",
    "Employees.newApplication.invitation": "Medewerker is uitgenodigd",
    "Employees.newApplication.schedule": "Medewerker is ingepland",
    "Employees.newApplication.rejected": "Medewerker is afgewezen",
    "Employees.newApplication.proposed": "Medewerker is voorgesteld",
    "Employees.newApplication.cancel": "Medewerker is succesvol geannuleerd",
    "Employees.options": "Opties",
    "Employees.monday": "Ma",
    "Employees.tuesday": "Di",
    "Employees.wednesday": "Wo",
    "Employees.thursday": "Do",
    "Employees.friday": "Vr",
    "Employees.saturday": "Za",
    "Employees.sunday": "Zo",
    "Employees.night": "Nacht",
    "Employees.morning": "Ochtend",
    "Employees.afternoon": "Middag",
    "Employees.evening": "Avond",
    "Employees.jobPermission": "Gekwalificeerd",
    "Employees.history.absent": "Absent",
    "Employees.history.notifications": "Ontvangen push notificaties",
    "Employees.customNumber": "Personeels ID",
    "Employees.fullAddress": "Medewerker adres",
    "Employees.insertion": "Tussenvoegsel",
    "Employees.sendOnboarding.explain": "Stuur de onboarding email",
    "Employees.invitedAt": "Uitgenodigd op",
    "Employees.openedAt": "Geopend op",
    "Employees.registeredAt": "Geregistreerd op",
    "Employees.firstJobAt": "Eerste werkdag op",
    "Employees.lastJobAt": "Laatste dienst op",
    "Employees.emptyState.title": "Geen medewerkers gevonden of toegevoegd",
    "Employees.emptyState.description":
      "Klik op de paarse knop rechts boven in de hoek om een medewerker toe te voegen.",
    "Employees.newEmployeesCreated":
      "Nieuwe medewerker aangemaakt met: {employee_email}.",
    "Employees.withdrawInvite": "Intrekken",
    "Employees.workedRecently": "Actief (30 dagen)",
    "Employees.workedRecently.hover": "Gewerkt in de afgelopen 30 dagen",
    "Employees.LoggedInInactive.hover": "Niet ingelogd in de afgelopen 60 dagen",
    "Employees.LoggedIn.hover": "Laatst ingelogd afgelopen 30 dagen",
    "Employees.LoggedInPast.hover": "Ingelogd in de afgelopen 30-60 dagen",
    "Employees.worked": "Actief (60 dagen)",
    "Employees.worked.hover": "Werkte in de vorige maand",
    "Employees.inactive": "Inactief",
    "Employees.inactive.hover":
      "Werkte niet in de afgelopen maand of deze maand",
    "Employees.waiting": "Nieuw",
    "Employees.waiting.hover": "Wacht op de eerste dienst!",
    "Employees.archive.confirmation": `Je staat op het punt om
    {items, plural,
      one {# medewerker}
      other {# medewerkers}    
    } te {archive, select,
      true {de-archiveren}
      false {archiveren}
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Employees.single.contract.payroll": "Loon-/inkomstenbelasting",
    "Employees.single.contract.payroll.wage":
      "Recht op loon-/ inkomstenbelasting?",
    "Employees.single.contract.payroll.wage.yes": "Ja, vanaf {date}",
    "Employees.single.contract.payroll.wage.no": "Nee",
    "Employees.single.contract.business": "Zakelijke gegevens",
    "Employees.single.contract.business.iban": "IBAN",
    "Employees.single.contract.contracts": "Contracten",
    //"Employees.single.contract.contracts.dateRange": "{from} tot en met {to}",
    "Employees.single.contract.contracts.dateRange":
      "{from} {to, plural, =null {} other {tot en met}} {to}",
    "Employees.single.contract.contracts.add": "Contract toevoegen",
    "Employees.single.contract.contracts.addOne": "Voeg een contract toe",
    "Employees.single.contract.shareEmployee.titleButtonModal":
      "Connectie contract updaten",
    "Employees.single.contract.shareEmployee.titleButtonModalEdit":
      "Connectie contract updaten",
    "Employees.single.contract.shareEmployee.titleCard": "Deel een medewerker",
    "Employees.single.contract.shareEmployee.titleCardEdit":
      "Pas Medewerker Delen aan",
    "Employees.single.contract.shareEmployee.textButtonAdd":
      "Deel de medewerker",
    "Employees.single.contract.shareEmployee.descriptionCard":
      "Kies een connectie om de medewerker mee te delen en voeg een periode en/of losse dagen toe.",
    "Employees.single.contract.shareEmployee.descriptionCardEdit":
      "Wijzig de periode en/of voeg losse dagen toe.",
    "Employees.single.contract.shareEmployee.labelTextTitleConnection":
      "Betreft connectie",
    "Employees.single.contract.shareEmployee.labelTextConnection":
      "Selecteer een connectie",
    "Employees.single.contract.shareEmployee.labelTextPeriod":
      "Selecteer een periode",
    "Employees.single.contract.shareEmployee.labelTextWeekdays":
      "Optioneel: Specificeer weekdagen in deze periode",
    "Employees.single.contract.shareEmployee.labelTextSingleDays":
      "Selecteer losse dagen",
    "Employees.single.contract.shareEmployee.labelTextHoursPerWeek":
      "Optioneel: Hoeveel uur per week?",
    "Employees.single.contract.shareEmployee.labelInputHoursAmount":
      "Uren aantal",
    "Employees.single.contract.shareEmployee.labelWeekdaysSelected":
      "Alle weekdagen zijn geselecteerd.",
    "Employees.id": "ID",
    "Employees.forgotPassword": "Wachtwoord vergeten? Klik hier",
    "Employees.lastContact": "Laatste contact",
    "EmployeesImport.sheetName.explain":
      "Wanneer je dit veld leeg laat wordt de eerste sheet geïmporteerd, vul hier de naam van de sheet in als je een uitzondering wilt maken",
    "EmployeesImport.initialCell.explain":
      "Standaard wordt de cel A1 gekozen, vul hier een de cel in al een uitzondering voor de linker boven cel wilt maken",
    "EmployeesImport.finalCell.explain":
      "Standaard wordt de laatste geldige cel gekozen, vul hier een de cel in al een uitzondering voor de rechter onder cel wilt maken",
    "EmployeesImport.import.explain":
      "Bij het importeren van een sheet moet de eerste rij de namen van de cel bevatten",
    "EmployeesImport.explain":
      "Importeer medewerkers met de volgende informatie, de eerste rij moet de volgende namen bevatten: Email, First name, last name, initials, address, house number, house number ext, phone number, post code, city, birth date, is verified, is reg complete, job categories",
    "EmployeesImport.wrongCell": "Geen geldige cel",
    "EmployeesImport.discardedEmployees":
      "Medewerkers zijn niet geïmporteerd omdat het emailadres mist",
    "EmployeesImport.discardedFreeFields": `De volgende vrije velden konden niet worden gevonden: {free_fields}`,
    "EmployeesImport.import": "Importeer",
    "EmployeesImport.error": "Er ging iets mis bij het importeren",
    "EmployeesImport.error-explain":
      "Er ging iets mis, kloppen de ingevulde sheetnaam, begin en/of eindcel?",
    "EmployeesImport.toCreate": "Medewerkers importeren",
    "EmployeesImport.created": "Aangemaakte medewerkers",
    "EmployeesImport.success": "Medewerkers zijn aangemaakt",
    "EmployeesImport.error-save":
      "Volgende medewerkers zijn niet aangemaakt omdat het emailadres al in gebruik is",
    "EmployeesImport.results": "Geïmporteerde medewerkers",
    "EmployeesImport.errorWhenSendOnboardingEmail":
      "De email is niet verzonden, controleer het emailadres",
    "EmployeesImport.sendOnboardingEmail":
      "Informeer en stuur de onboarding email direct naar de nieuwe medewerker(s)",
    "EmployeesImport.sendOnboardingEmail.single":
      "Informeer de medewerker met de onboarding email over het gebruik van Fleks.",
    "EmployeesImport.descriptionWithTemplate":
      "Gebruik <a href='https://docs.google.com/spreadsheets/d/1nc3h3X7-4K3DId-Q85Uj1oNiY8Mqz0BbY2q7COVQ3IQ/edit#gid=0' target='_blank'>following Google Sheet</a> als een template voor het importeren van je medewerkers.",
    "Employees.filePhase6": "Het laatste contract is geëindigd op: %ed",
    "Employees.BasicInformationModal.title": "Algemene informatie aanpassen",
    "Jobs.jobFreeFieldsModal.title": "Dienst vrije velden informatie aanpassen",
    "Employees.TravelSpecificationsModal.title": "Pas de reisinformatie aan",
    "Employees.CharacteristicsModal.title": "Kenmerken aanpassen",
    "Employees.AdditionalInformationModal.title":
      "Aanvullende informatie aanpassen",
    "Employees.FacultiesModal.title": "Specificaties aanpassen",
    "Employees.FacultiesModal.footer": "Let op! Bestaande categoriën die al zijn toegevoegd met een eindatum zullen overschreden worden.",
    "Employees.SpecificationsModal.title": "Voeg categorie toe",
    "Employees.modalSave.tittleButton": "Toevoegen",
    "Employees.BusinessDataModal.title": "Wijzig zakelijke informatie",
    "Employees.BusinessDataModal.adjustBankData.title":
      "Bankgegevens aanpassen",
    "Employees.PayrollIncomeTaxModal.title":
      "Wijzig loonheffingskorting details",
    "Employees.adjustContract": "Contractinformatie",
    "Employees.modal.save": "Algemene informatie opslaan",
    "Employees.contactMoment": "Contactmogelijkheden",
    "Employees.sendMessageScheduledEmployees": "Stuur roostermail",
    "Employees.selectMaximumDate": "Selecteer de maximale datum",
    "Employees.agenda": "Agenda",
    "Employees.inviteToJob": "Nodig uit voor deze dienst",
    "Employees.planInForThisJobs": "Plan in voor deze dienst",
    "Employees.proposeForThisJob": "Voorstellen voor deze dienst",
    "Employees.shareEmployees": "Deel medewerkers",
    "Employees.shareEmployees.alreadyShared": `Minstens een van de geselecteerde medewerkers is al gedeeld met {tenant_name}`,
    "Employees.shareEmployees.success": `De geselecteerde medewerkers worden gedeeld met {tenant_name}`,
    "Applicants.title": "Sollicitanten",
    "Applicants.newApplicant": "Sollicitant toevoegen",
    "Applicants.accepted": "Goedgekeurd",
    "Applicants.applicants": "Sollicitanten",
    "Applicants.createApplicant.title": "Nieuwe Sollicitant",
    "Applicants.createApplicant.save": "Sollicitant aanmaken",
    "Applicants.sendOnboardingEmail.check":
      "Informeer de sollicitant met de onboarding email over het gebruik van Fleks.",
    "Applicants.newApplicantCreated":
      "Nieuwe sollicitant aangemaakt met: {employee_email}.",
    "Applicants.newAccepted": "Nieuwe geaccepteerde sollicitant",
    "Applicants.confirm-accept-checked":
      "Weet je zeker dat je sollicitant wilt doorzetten naar de volgende fase?",
    "Applicants.emptyState.title": "Er zijn nog geen sollicitanten toegevoegd",
    "Applicants.emptyState.description":
      "Klik op de paarse knop rechts boven in de hoek om een medewerker toe te voegen.",
    "Applicants.registered": "Geregistreerd",
    "Applicants.link": "Bekijk de link voor het inschrijfformulier",
    "Applicants.link.title": "Deel de link voor het inschrijfformulier",
    "Applicants.link.description":
      "Kopieer de onderstaande link en publiceer deze waar potentiële medewerkers deze kunnen lezen en kunnen inschrijven voor je organisatie!",
    "Applicants.agreement":
      "Door een account aan te maken ga je akkoord met de privacyverklaring / overeenkomst",
    "History.totalJobs": "Totaal aantal diensten",
    "History.extraHours": "Totaal extra uren",
    "History.grossSalary": "Totaal brutosalaris",
    "History.travelTime": "Totale reistijd",
    "History.travelTimeFee": "Totale reistijd vergoeding",
    "History.kmDriven": "Totaal aantal km",
    "History.otherCost": "Totaal overige kosten",
    "History.sick": "Aantal keer ziek",
    "History.noShow": "Aantal keer no-show",
    "Notifications.error": "Er ging iets mis",
    "Notifications.beAware": "Let op",
    "Notifications.confirm-accept": "Weet je het zeker?",
    "Notifications.confirm-accept-checked":
      "Weet je het zeker dat je alle sollicitanten wilt accepteren?",
    "Notifications.successful": "Succesvol",
    "Notifications.archived": "gearchiveerd",
    "Notifications.unarchived": "De-archiveren",
    "Notifications.activate":
      "Je kunt nu notificaties ontvangen van Fleks, geef aan of je deze wilt ontvangen",
    "Notifications.confirm-accept-declarations":
      "Je staat op het punt de geselecteerde urenregistratie(s) te accepteren. Wilt u doorgaan?",
    "Hours.function_based": "Bedrag",
    "Hours.isException": "Uitzondering?",
    "Hours.isCompleted": "Workflow afgerond?",
    "Hours.employeeZipCode": "Postcode",
    "Hours.employeePhoneNumber": "Telefoonnummer",
    "Hours.employeeEmail": "Email medewerker",
    "Hours.employeeCity": "Stad",
    "Hours.employeeAddress": "Adres",
    "Hours.employeeFullAddress": "Medewerker adres",
    "Hours.employeeHouseN": "Huisnummer",
    "Hours.employeeHouseNExt": "Extensie",
    "Hours.jobZipCode": "Postcode van de dienst",
    "Hours.jobCity": "Stad van de dienst",
    "Hours.jobAddress": "Adres van de dienst",
    "Hours.jobFullAddress": "Locatie dienst",
    "Hours.jobHouseN": "Huisnummer van de dienst",
    "Hours.jobHoouseNExt": "Extensie van de dienst",
    "Hours.jobCategory": "Functie",
    "Hours.startDateMin": "Startdatum (min)",
    "Hours.startDateMax": "Startdatum (max)",
    "Hours.bonus": "Bonus",
    "Hours.travelDistance": "Reisafstand",
    "Hours.hostNumber": "Personeels nr",
    "Hours.name": "Naam",
    "Hours.lastname": "Achternaam",
    "Hours.city": "Stad",
    "Hours.totalHours": "Totaal aantal uur",
    "Hours.extra": "Extra",
    "Hours.travelCosts": "Reiskosten",
    "Hours.totalCost": "Totale prijs",
    "Hours.title": "Urenregistratie",
    "Hours.newEmployee": "Nieuwe medewerker",
    "Hours.employeeId": "Personeelsnummer", //
    "Hours.all": "Alle",
    "Hours.open": "Open",
    "Hours.outliers": "Uitschieters",
    "Hours.accepted": "Geaccepteerd",
    "Hours.notSent": "Workflow incompleet",
    "Hours.checkTheHours": "Check de uren",
    "Hours.incompleted": "Incompleet",
    "Hours.processed": "Verwerkt",
    "Hours.initial": "Voorletters",
    "Hours.prefix": "Tussenvoegsel",
    "Hours.birthDay": "Geboortedatum",
    "Hours.age": "Leeftijd",
    "Hours.projectTitle": "Projectnaam",
    "Hours.startDate": "Startdatum",
    "Hours.workedDay": "Werkuren",
    "Hours.additionalHours": "Extra uren",
    "Hours.breakHours": "Pauze uren (min)",
    "Hours.appliedPaymentMethod": "Toegepaste betaalmethode",
    "Hours.hourlyWage": "Uurloon",
    "Hours.fixedHourlyWage": "Vast uurloon",
    "Hours.fixedAmount": "Vast bedrag",
    "Hours.fixedReserveRate": "Vaste reservetarief",
    "Hours.travelHours": "Reistijden",
    "Hours.travelHoursRate": "Reisuren tarief",
    "Hours.noteStartTime": "Bij het bijwerken van de begin- of eindtijd zijn beide vereist.",
    "Hours.noteBreak": "Alleen de pauze updaten is een mogelijkheid.",
    "Hours.calculate-error":
      "Er ging iets fout bij het berekenen van de reiskosten, klopt de locatie?",
    "Hours.calculate-success": "De reiskosten zijn berekend",
    "Hours.no-calculate":
      "Het is niet mogelijk om de reiskosten te berekenen. Kan je checken of de adresgegevens van de medewerker en de dienst kloppen?",
    "Hours.numberDrivenKm": "Aantal gereden kilometers",
    "Hours.ratePerKm": "Tarief per km",
    "Hours.drivenKmFee": "Gereden km-kosten",
    "Hours.publicTransportFee": "OV-vergoeding",
    "Hours.advances": "Voorschotten",
    "Hours.projectNumber": "Projectnummer",
    "Hours.declarationNumber": "Dienst nummer",
    "Hours.note": "Notities",
    "Hours.status": "Status",
    "Hours.endDate": "Einddatum",
    "Hours.details": "Details",
    "Hours.accepted-success": "Urenregistraties zijn geaccepteerd",
    "Hours.close-success": "Urenregistraties zijn verwerkt",
    "Hours.open-to-approved-success":
      "De uren worden op de achtergrond goedgekeurd, de wijzigingen worden binnen enkele minuten in de tabel weergegeven. Dit kan tot een uur duren",
    "Hours.approved-to-processed-success":
      "De uren worden op de achtergrond verwerkt, de wijzigingen worden binnen enkele minuten in de tabel weergegeven. Dit kan tot een uur duren",
    "Hours.toinvoiced-to-invoiced-success":
      "De uren worden op de achtergrond gefactureerd, de wijzigingen worden binnen enkele minuten in de tabel weergegeven. Dit kan tot een uur duren",
    "Hours.otherCost": "Overige kosten",
    "Hours.checkTimes": "Geklokte tijd",
    "Hours.projectID": "Projectnummer",
    "Hours.travelDistanceCost": "KM kosten",
    "Hours.travelTimesCost": "Reistijd kosten",
    "Hours.transportationCost": "OV-kosten",
    "Hours.declarationPhoto": "Declaratie foto(s)",
    "Hours.both": "Declaratie foto's",
    "Hours.fixed_rate": "Vast bedrag",
    "Hours.hourly_rate": "Vast bedrag per uur",
    "Hours.custom_age_related_rate": "Op leeftijd",
    "Hours.reserve_rate": "Reserve bedrag",
    "Hours.declaration": "Urenregistratie",
    "Hours.costs": "Brutosalaris",
    "Hours.travel": "Reiskosten",
    "Hours.travelDistanceApplicable": "Aantal gereden km",
    "Hours.travelTime": "Reistijd",
    "Hours.travelTimeCompensation": "Reistijd uurtarief",
    "Hours.travelTimeFee": "Reistijd vergoeding",
    "Hours.travelTimeRate": "Uurtarief reistijd",
    "Hours.publicTransport": "OV-kosten zijn declarabel",
    "Hours.preCalculateTravel": "Reiskosten worden betekend met Google maps",
    "Hours.grossSalary": "Brutosalaris (excl. reistijd vergoeding)",
    "Hours.otherCosts": "Overige kosten",
    "Hours.kmDrivingCost": "Km vergoeding",
    "Hours.netCosts": "Totale kosten",
    "Hours.gross-salary": "Brutosalaris",
    "Hours.needCheckAndPlan":
      "De urenregistratie moet nog worden gecheckt door planning",
    "Hours.checkedAndNeedPlan":
      "De urenregistratie is geaccordeerd door planning en moet nog worden gecheckt door financiën",
    "Hours.checkedAndPlanned":
      "De urenregistratie is geaccordeerd door planning en financiën",
    "Hours.worked": "Gewerkt",
    "Hours.approvedExported": "Status",
    "Hours.wasScheduleBefore.warning":
      "'[full_name_employee]' is eerder afgewezen voor deze dienst, weet je zeker dat je medewerker wilt inplannen?",
    "Hours.actions.correction": "Maak een correctie",
    "Hours.actions.comment": "Opmerking plaatsen",
    "Hours.actions.sendToProcessed": "Stuur naar verwerkt",
    "Hours.actions.acceptDeclaration": "Accepteer urenregistratie",
    "Hours.actions.calculateTravelExpenses": "Bereken reiskosten",
    "Hours.actions.acceptDeclaration.allHours": "Keur declaratie(s) goed",
    "Hours.actions.acceptDeclaration.allHours.isNotHourOwner":
      "Je bent niet de eigenaar van de uren voor {japs} van de geselecteerde onderdelen, controleer de geselecteerde rijen voordat je doorgaat.",
    "Hours.correction": "Nieuwe urenregistratie",
    "Hours.correction.success": "Correctie is aangemaakt",
    "Hours.actions.invoice.allHours":
      "Geselecteerde uren naar gefactureerd sturen",
    "Hours.correction.error": "Het is niet gelukt om de correctie aan te maken",
    "Hours.update.success": "De uren zijn aangepast",
    "Hours.update.error": "De uren zijn niet aangepast",
    "Hours.newDeclaration": "Nieuwe urenregistratie",
    "Hours.invitePending": "Uitnodiging in afwachting",
    "Hours.appliedPending": "Inschrijving in afwachting",
    "Hours.proposed": "Voorgesteld",
    "Hours.proposal": "Voorstel",
    "Hours.activeFixedRate": "Vast bedrag",
    "Hours.applicableTravelTime": "Reistijd",
    "Hours.totalNetCost": "Netto kosten",
    "Hours.activePaymentType": "Toegepaste betaalmethode",
    "Hours.totalTravelTime": "Reistijd",
    "Hours.numberJobApplications": "Aantal keer gewerkt",
    "Hours.age_hourly_rate": "Op leeftijd uitbetaald",
    "Hours.contract_based": "Op contract",
    "Hours.highestHourlyRate": "Uurloon eind periode",
    "Hours.hoursHourlyRate": "Aantal uren met uurtarief",
    "Hours.hoursReserveRate": "Aantal uren met vast reserve tarief",
    "Hours.hoursFixedRate": "Aantal uren met vast tarief",
    "Hours.totalHourlySalary": "Brutosalaris met uurtarief",
    "Hours.totalReserveSalary": "Totaal reserve vergoeding",
    "Hours.totalFixedSalary": "Totaal vast tarief vergoeding",
    "Hours.makeSalaryRun": "Start salarisrun",
    "DownloadModal.totalSalary": "Salaris run",
    "Hours.correction.info":
      "De correctie zal in de open urenregistratie geplaatst worden",
    "Hours.correction.disabled": `Je kunt de uren niet meer aanpassen, aangezien deze al verwerkt zijn door {email}, in dit geval kun je een correctie doorvoeren. Ga naar het verwerkte tabblad van de Intern per inplanning tabel en klik op de tijden om snel een correctie door te voeren.`,
    "Hours.notCalculated":
      "Het is niet gelukt om de reiskosten en reistijd automatisch te berekenen",
    "Hours.isRejected": "Afgewezen",
    "Hours.emptyState.title": "Oh nee! We kunnen geen urenregistratie vinden",
    "Hours.emptyState.description":
      "Vernieuw de pagina en probeer het opnieuw.",
    "Hours.log": "Status",
    "Hours.hour": "Uren",
    "Hours.startAndEndTime": "Start en eindtijd",
    "Hours.hoursWorked": "Werkuren",
    "Hours.extraHours": "Bonus uren",
    "Hours.rates": "Tarieven",
    "Hours.travelExpenses": "Reiskosten",
    "Hours.amountOfKm": "Aantal KM",
    "Hours.publicTransportationCost": "OV kosten",
    "Hours.toolTip.title": "Reisspecificaties",
    "Hours.tooltip.car": "Auto",
    "Hours.tooltip.license": "Rijbewijs",
    "Hours.tooltip.publicTransportCard": "OV",
    "Hours.notOwner":
      "Je kunt de urenregistratie niet bekijken, de urenregistratie wordt gecontroleerd door de eigenaar van de dienst",
    "Hours.supplier": "Leverancier",
    "Hours.singles": "Per inplanning",
    "Hours.singles.title": "Intern per inplanning",
    "Hours.totals.title": "Intern per medewerker",
    "Hours.workedHoursAndBreakHours": `- Geplande uren: {plannedHours}<br/>- Aanmeldtijd: {checkIn}<br/>- Geklokte uren: {clockedHours}<br/>- Definitieve uren: {definitiveHours}<br/>- Werkuren: {workedHours}<br/>- Pauze uren: {breakCompensation}<br/>- Aftekentijd (manager): {sign_off_time}<br/>- Aftekentijd (medewerker): {employee_sign_off_time}`,
    "Hours.workedHoursAndBreakHours.allHours": `- Geplande uren: {plannedHours}<br/>- Aanmeldtijd: {checkIn}<br/>- Geklokte uren: {clockedHours}<br/>- Definitieve uren: {definitiveHours}<br/>- Voorgestelde uren: {suggestedHours}<br/>- Opmerking: {comment}`,
    "Hours.travelTimeAndApplicableTravelTime": `- Reistijd: {travelTime}<br/>- Reistijd van toepassing: {applicableTravelTime}`,
    "Hours.travelDistanceAndTravelDistanceApplicable":
      "Aantal KM {travelDistance}, KM van toepassing {travelDistanceApplicable}",
    "Hours.archive.confirmation": `Je staat op het punt om
    {items, plural,
      one {# urenregistratie}
      other {# urenregistratie}    
    } te {archive, select,
      true {de-archiveren}
      false {archiveren}
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Hours.proposedDate": "Voorgesteld op",
    "Hours.registeredDate": "Ingeschreven op",
    "Hours.invitedDate": "Uitgenodigd op",
    "Hours.toInvoice": "Te factureren",
    "Hours.invoiced": "Gefactureerd",
    "Hours.initialCheck": "Open",
    "Hours.feebackLoopStatus": "Controlerooster status",
    "Hours.suggestionMade": "Geplaatste opmerking",
    "Hours.feedbackLoopStatus.not_started": "Nog niet gestart",
    "Hours.feedbackLoopStatus.active": "Actief",
    "Hours.feedbackLoopStatus.approved": "Goedgekeurd",
    "Hours.feedbackLoopStatus.rejected": "Afgekeurd",
    "Hours.feedbackLoopStatus.expired": "Verlopen",
    "Hours.options.sendToFinqle": "Stuur naar  Finqle", 
    "Hours.sendToFinqleSuccess": "De geselecteerde uren en kosten zijn naar Finqle verstuurd. Staat de Billing request nog niet in Finqle? Kijk dan in het Connections portaal om de fout te bekijken.", 
    "Hours.sendToFinqlePartialSuccess": "Een deel van de geselecteerde uren en kosten is naar Finqle verstuurd. Niet alles kon worden aangemaakt. Bekijk het Connections portaal voor de succesvolle en niet-succesvolle diensten.", 
    "Hours.sendToFinqleFailed": "De geselecteerde uren en kosten zijn niet naar Finqle verstuurd er heeft een probleem plaatsgevonden. Neem contact op met het support team van Fleks.", 
    "Hours.sendToFinqleConfirmTitle": "Weet je het zeker ?",
    "Hours.sendToFinqleConfirmDescription": "De uren worden naar Finqle verzonden, reeds verzonden uren en kosten worden niet opnieuw verzonden.",
    "Hours.startFeedbackLoop.selectedJapOwn": `Je hebt {japWithoutShared, plural,
      one { # eigen inplanning }
      other { # eigen inplanningen }
    } geselecteerd, maar kunt het controlerooster niet versturen. Om dit te doen, moet je alleen gedeelde diensten selecteren.`,
    "Hours.startFeedbackLoop.selectedJapNotHourOwner": `Je hebt {japNotHourOwner, plural,
      one { # eigen inplanning }
      other { # eigen inplanningen }
    } geselecteerd, maar kunt het controlerooster niet versturen. Om dit te doen, moet je eigenaar van de uren zijn.`,
    "Hours.startFeedbackLoop.selectedJapWithFeedbackloopActive": `Je hebt {japWithFeedbackloopActive, plural,
      one { # inplanning }
      other { # inplanningen }
    } geselecteerd die al zijn verstuurd. Je kunt deze onderdelen niet nog een keer versturen. Selecteer gedeelde diensten waarvoor nog geen controlerooster is gestuurd.`,
    "Hours.startFeedbackLoop.empty.title": "Verstuur controlerooster",
    "Hours.startFeedbackLoop.empty.subtitle": `Je hebt {jap, plural, one { # inplanning } other { # inplanningen} } geselecteerd om de gewerkte uren te laten controleren en om goedkeuring of feedback te vragen`,
    "Hours.startFeedbackLoop.modal.title": "Stuur naar gecontroleerd",
    "Hours.startFeedbackLoop.modal.subtitle": `Je hebt {jap, plural, one { # inplanning } other { # inplanningen} } verstuurd om te laten controleren. Selecteer een deadline en connectie(s) om de uren te laten controleren.`,
    "Hours.startFeedbackLoop.modal.deadline": "Deadline",
    "Hours.startFeedbackLoop.modal.error.deadline":
      "Selecteer de juiste deadline",
    "Hours.startFeedbackLoop.modal.button.titleSend": "Verstuur uren",
    "Hours.startFeedbackLoop.modal.checkbox.selectAll":
      "Selecteer alle connecties",
    "Hours.statusFeedbackLoop.no_feedback.hourOwner": "Gecontroleerd",
    "Hours.statusFeedbackLoop.no_feedback.hourSupplier": `Gecontroleerd door {hourOwner}`,
    "Hours.statusFeedbackLoop.feedback_requested.hourSupplier":
      "Controleer de uren",
    "Hours.statusFeedbackLoop.feedback_added.hourSupplier":
      "Suggestie toegevoegd",
    "Hours.statusFeedbackLoop.feedback_sent.hourOwner":
      "Suggestie verstuurd door {hourSupplier}",
    "Hours.statusFeedbackLoop.feedback_sent.hourSupplier":
      "Suggestie verstuurd",
    "Hours.statusFeedbackLoop.hours_approved.hourOwner": `Goedgekeurd door {hourSupplier}`,
    "Hours.statusFeedbackLoop.automatically_accepted.hourOwner":
      "Controleperiode verlopen",
    "Hours.statusFeedbackLoop.automatically_accepted.hourSupplier":
      "Automatisch geaccepteerd",
    "Hours.statusFeedbackLoop.hourApprovalRequest": "Urencontrole aangevraagd",
    "Hours.statusFeedbackLoop.suggestionApproved": "Suggestie goedgekeurd",
    "Hours.statusFeedbackLoop.suggestionDenied": "Suggestie afgekeurd",
    "Hours.statusFeedbackLoop.approved": "Goedgekeurd",
    "Hours.popoverModal.hourSupplier.title": "Stuur een suggestie",
    "Hours.popoverModal.hourOwner.title": "Aangevraagde suggestie",
    "Hours.popoverModal.hourSupplier.titleButton.add": "Voeg suggestie toe",
    "Hours.popoverModal.hourSupplier.titleButton.approved": "Keur uren goed",
    "Hours.popoverModal.hourOwner.titleButton.accept": "Accepteer suggestie",
    "Hours.popoverModal.hourOwner.titleButton.decline": "Weiger",
    "Hours.popoverModal.hourOwner.titlePopConfirm.decline":
      "Je staat op het punt om de suggestie te weigeren, wil je doorgaan?",
    "Hours.popoverModal.hourOwner.titlePopConfirm.accept":
      "je staat op het punt om de suggestie goed te keuren, wil je doorgaan?",
    "Hours.allHours.button.sendReviewedHours": "Verstuur gecontroleerde uren",
    "Hours.allHours.button.error.sendReviewedHours":
      "Beoordeelde uren kunnen alleen worden verstuurd als ze worden goedgekeurd of als er een suggestie is toegevoegd.",
    "Hours.invoices": "Facturen",
    "Hours.invoices.title": "Facturen",
    "Hours.invoices.actions.makeCounterProposal": "Tegenvoorstel maken",
    "Hours.invoices.freelancers": "Freelancers",
    "Hours.invoices.lastProposal": "Meest recente voorstel",
    "Hours.invoices.invalidatedProposals": "Vervallen voorstellen",
    "Hours.invoices.creater": "Aangemaakt door",
    "Hours.invoices.breakTime": "Pauzetijd",
    "Hours.invoices.breakDuration": "Pauze uren",
    "Hours.invoices.currentDeclaration": "Huidig voorstel",
    "Hours.invoices.newCorrection": "Nieuw voorstel",
    "Hours.invoices.sendCounterProposal": "Stuur tegenvoorstel",
    "Hours.invoices.modal.counterProposal.title": "Maak tegenvoorstel aan",
    "Hours.invoices.accepted-success": "Factuur is geaccepteerd",
    "Hours.invoices.created-success": "Tegenvoorstel is aangemaakt",
    "Hours.invoices.viewInvoices": "Bekijk factuur",
    "Hours.correctionModal.adjustHoursQuickly": "Pas snel de uren aan",
    "Hours.correctionModal.markAsApproved": "Markeer als goedgekeurd",
    "Hours.correctionModal.textButton.approve": "Keur goed",
    "Hours.correctionModal.textButton.updateAndApprove":
      "Bijwerken en goedkeuren",
    "Hours.feedbackLoop.title.button": "Verstuur controlerooster",
    "Hours.externals": "Extern per inplanning",
    "Hours.externals.amountOfHours": "Uren",
    "Hours.externals.status.pending": "Controleer de uren",
    "Hours.externals.status.reviewed": "Geaccepteerd",
    "Hours.externals.status.processed": "Verwerkt",
    "Hours.externals.lastProposal": "Meest recente voorstel",
    "Hours.externals.previousProposals": "Verlopen voorstel(len)",
    "Hours.externals.creator": "Aangemaakt door",
    "Hours.externals.billableHours": "Uren",
    "Hours.externals.creationDate": "Creatie",
    "Hours.creationDetails": "Creatie details",
    "Hours.externals.approvalDate": "Geaccepteerd op",
    "Hours.externals.rejectionDate": "Afgewezen op",
    "Hours.externals.currentProposal": "Huidige voorstel",
    "Hours.externals.employeeName": "Naam",
    "Hours.externals.newProposal": "Nieuw voorstel",
    "Hours.externals.status": "Status",
    "Hours.externals.accept.confirmation": `Je staat op het punt om
    {items, plural,
      one {# voorstel}
      other {# voorstellen}    
    } te accepteren
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Hours.externals.accept": "Accepteer voorstel",
    "Hours.externals.accepted-success": "Voorstellen is geaccepteerd",
    "Hours.externals.editBillableHours":
      "Er zijn factureerbare uren gekoppeld aan deze informatie. Update ook de factureerbare uren.",
    "Hours.externals.createBillableHours": "Maak factureerbare uren aan",
    "Hours.externals.currentBillableHours": "Huidige factureerbare uren",
    "Hours.externals.newBillableHours": "Nieuwe factureerbare uren",
    "Hours.externals.sendBillableHours": "Stuur factureerbare uren",
    "Single.plannedHours": "Gepland aantal uur",
    "Single.totalWorked": "Totaal aantal diensten",
    "Single.totalHours": "Totaal aantal uren",
    "Single.lastActive": "Laatst ingeschreven",
    "Single.calendar": "Kalender",
    "Single.overview": "Overzicht",
    "Single.details": "Details",
    "Single.contract": "Contract",
    "Single.history": "Geschiedenis",
    "Single.media": "Media",
    "Single.comments": "Opmerkingen",
    "Single.hours-registration": "Uren registratie",
    "Single.jobs": "Diensten",
    "Single.pools": "Pools",
    "Single.dateRegistered": "Datum geregistreerd",
    "Single.payment": "Betaling",
    "Single.candidates": "Kandidaten",
    "Details.general": "Algemeen",
    "Details.characteristics": "Kenmerken",
    "Details.clothingSize": "Kledingmaat",
    "Details.length": "Lengte",
    "Details.shoeSize": "Schoenmaat",
    "Details.piercingsTattoo": "Piercings/Tattoo",
    "Details.no": "Nee",
    "Details.yes": "Ja",
    "Details.planIn": "Inplannen",
    "Details.notVisible": "Ja, maar niet zichtbaar",
    "Details.travel": "Reizen",
    "Details.publicTransport": "Openbaar vervoer",
    "Details.pt": "OV",
    "Details.week": "Week",
    "Details.weekend": "Weekend",
    "Details.license": "Rijbewijs",
    "Details.car": "Auto",
    "Details.notAlways": "Soms",
    "Details.normal": "Normaal",
    "Details.discount": "Korting",
    "Details.sometimes": "Soms",
    "Details.unknown": "Onbekend",
    "Details.education": "Opleiding",
    "Details.hearAboutUs": "Hoe heb je over ons gehoord?",
    "Details.friend": "Vriend",
    "Details.socialMedia": "Social media",
    "Details.other": "Anders",
    "Details.branch": "Functies",
    "Details.trainings": "Trainingen",
    "Details.qualifications": "Kwalificaties",
    "Details.languages": "Talen",
    "Details.languages.adjust": "Pas de taalinformatie aan",
    "Details.employeeLanguages": "Talen van medewerkers",
    "Details.employeeLanguages.addOne": "Talen toevoegen",
    "Details.information": "Informatie",
    "Details.payment": "Betaling",
    "Details.description": "Beschrijving",
    "Details.briefing": "Briefing",
    "Details.questions": "Vragen",
    "Details.numberOfQuestions": "Aantal vragen",
    "Details.evaluation": "Evaluatie",
    "Details.evaluationLink": "Link",
    "Details.no-evaluation": "Vul de evaluatie link in",
    "Details.contact-person": "Contact medewerker",
    "Details.documents": "Documenten",
    "Details.registered": "Ingeschreven",
    "Details.proposed": "Voorgesteld",
    "Media.photo": "Profielfoto",
    "Media.dates": `{date} op {start_time} tot {end_time}`,
    "Media.filters.jobFullAddress": `Dienst adres`,
    "Details.applications": "Inschrijvingen",
    "Details.invitations": "Uitgenodigd",
    "Details.scheduled": "Ingepland",
    "Details.team": "Team",
    "Details.cancelled": "Geannuleerd",
    "Details.proposals": "Voorgesteld",
    "Details.customNumber": "ID",
    "Creation.jobs.pool.predefined": "Standaard pool (optioneel)",
    /* The common strings are using text in different views. */
    "common.sharedType": "Deel type",
    "common.shared": "Gedeeld",
    "common.combined": "Gecombineerd",
    "common.internal": "Intern",
    "common.all": "Alle",
    "common.doanloadAll": "Download alles",
    "Common.downloadAll": "Download alles",
    "Common.downloadMedia": "Media downloaden",
    "Common.downloadMedia.declarationPhoto": "Workflow foto's",
    "Common.downloadMedia.publicTransport": "Bonnen openbaar vervoer",
    "Common.downloadMedia.otherCosts": "Bonnen overige kosten",
    "Common.downloadMedia.all": "Alle typen",
    "Common.downloadMedia.description":
      "Selecteer de afbeeldingstypen die u wilt downloaden:",
    "Common.downloadAllBeta": "Probeer de nieuwe download functie!",
    "Common.downloadAll.confirmation": `U staat op het punt alle geselecteerde mediatypen te downloaden. Weet u zeker dat u deze actie wilt uitvoeren?`,
    "Common.downloadAll.success": `Wij zetten een bestand klaar om te downloaden, je zult een notificatie en email ontvangen wanneer het bestand gereed is`,
    "common.cancel": "Annuleer",
    "Common.save": "Opslaan",
    "Common.saving": "Opslaan",
    "Common.savingChangesError": "Opslaan van de wijzigingen is niet gelukt",
    "Common.yes": "Ja",
    "Common.no": "Nee",
    "Common.cancel": "Annuleer",
    "Common.lastname": "Achternaam",
    "Common.usedForPlanning": "Zichtbaar tijdens plannen",
    "Common.notUsedForPlanning": "Niet zichtbaar tijdens plannen",
    "Common.shareable": "Gedeeld veld met connectie(s)",
    "Common.notShareable": "Intern veld",
    "Common.name": "Naam",
    "Common.city": "Stad",
    "Common.age": "Leeftijd",
    "Common.gender": "Geslacht",
    "Common.email": "E-mail",
    "Common.mailbox": "Email voor algemene notificaties",
    "Common.noReply": "No reply e-mail",
    "Common.zipCode": "Postcode",
    "Common.address": "Adres",
    "Common.houseNumber": "Huisnummer",
    "Common.houseNumberExt": "Extensie",
    "Common.postCode": "Postcode",
    "Common.country": "Land",
    "Common.saveAndExit": "Opslaan en afsluiten",
    "Common.save-success": "Opgeslagen",
    "Common.save-error": "Er ging iets fout bij het opslaan",
    "Common.try-later": "Probleempje, probeer later opnieuw",
    "Common.required": "Dit veld is verplicht",
    "Common.min": "Het wachtwoord moet minstens [number] karakters bevatten",
    "Common.max": "Dit vel mag niet meer dan {number} karakters bevatten",
    "Common.inputMinLength": `Dit veld moet op zijn minst {number} karakters bevatten`,
    "Common.inputMaxLength": `Dit veld max maximaal {number} karakters bevatten`,
    "Common.inputFixedLenght": `Het veld moet exact {number} tekens bevatten`,
    "Common.created-success": "Aangemaakt",
    "Common.created-error": "Er ging iets mis bij het aanmaken",
    "Common.maxLength": "Het maximaal aantal tekens is overschreden",
    "Common.length": "Geen match met de benodigde lengte",
    "Common.copyLink": "Kopieer link",
    "Common.clickToCopy": "Klik om de link te kopiëren",
    "Common.copiedLink": "De link is naar het klembord gekopieerd",
    "Common.load-error": "Er ging iets mis bij het laden van de gegevens",
    "Common.seeComments": "Zie opmerkingen",
    "Common.hideComments": "Verberg opmerkingen",
    "Common.errorOccurred": "Er ging iets fout",
    "Common.exit": "Afsluiten",
    "Common.addFilter": "Toepassen",
    "Common.archive": "Archiveren",
    "Common.filters.resetAll": "Verwijder filters",
    "Common.edit": "Aanpassen",
    "Common.editTime": "Wijzig tijd",
    "Common.delete": "Verwijderen",
    "Common.next": "Volgende",
    "Common.duplicate": "Dupliceren",
    "Common.viewJobs": "Bekijk diensten",
    "Common.search": "Zoeken",
    "Common.period": "Periode",
    "Common.periodDays": "Periode (dagen)",
    "Common.specifications": "Specificaties",
    "Common.periodFrom": "Start periode",
    "Common.periodTill": "Eind periode",
    "Common.remove": "Verwijderen",
    "Common.optional": "Optioneel",
    "Common.archived": "Gearchiveerd",
    "Common.returned": "Materiaal geretourneerd",
    "Common.noShow": "No-show",
    "Common.calledInSick": "Ziekgemeld",
    "Common.undoCancellation": "Annulering intrekken",
    "Common.stepBack": "Ga terug",
    "Common.watchOut": "Opgelet!",
    "Common.export": "Exporteren",
    "Common.exportAll": "Exporteer resultaten ({total})",
    "Common.exportAllNoTotal": "Exporteer resultaten",
    "Common.exportAllBeta": "Probeer de nieuwe bèta-export!",
    "Common.exportAll.confirmationAll": `Je staat op het punt om alle gegevens te exporteren, weet je zeker dat je deze actie wilt uitvoeren?`,
    "Common.exportAll.confirmation": `Je staat op het punt om alle gegevens te exporteren ({total}), weet je zeker dat je deze actie wilt uitvoeren?`,
    "Common.exportAll.success": `We exporteren momenteel uw gegevens, u ontvangt een e-mail en een melding wanneer het bestand klaar is`,
    "Common.exportPdf": "Print PDF",
    "Common.exportPdfExplain": "Print selectie naar PDF ",
    "Common.exportPdfAll": "Print selectie naar PDF ({total})",
    "Common.exportPdfMessageAll":
      "Je staat op het punt de huidige selectie van ({total}) naar PDF af te drukken.",
    "Common.exportPdfMessage":
      "Je ontvangt de downloadlink naar de pdf in je notificatie box, wanneer deze klaar is.",
    "Common.woman": "Vrouw",
    "Common.women": "Vrouw",
    "Common.man": "Man",
    "Common.men": "Man",
    "Common.applied": "Ingeschreven",
    "Common.invited": "Uitgenodigd",
    "Common.scheduled": "Ingepland",
    "Common.savePreset": "Opslaan",
    "Common.unarchive": "De-archiveer",
    "Common.loadMore": "Meer laden ({total})",
    "Common.seeProfile": "Naar profiel",
    "Common.moreDetails": "Meer details",
    "Common.seeJob": "Naar dienst",
    "Common.noEmployeesScheduled": "Er zijn geen medewerkers ingepland",
    "Common.noExternalsScheduled": "Geen externe medewerkers ingepland",
    "Common.noFreelancersScheduled": "Geen freelancers ingepland",
    "Common.noApplications": "Geen inschrijvingen",
    "Common.noInvites": "Geen medewerkers uitgenodigd",
    "Common.noCancelledApplications": "Geen geannuleerde inschrijvingen",
    "Common.noQualifiedCandidates": "Geen gekwalificeerde medewerkers gevonden",
    "Common.noEmployees": "Geen medewerker(s)",
    "Common.noAppliedFreelancers": "Geen aangemelde freelancers",
    "Common.noPlannedFreelancers": "Geen ingeplande freelancers",
    "Common.noFreelancers": "Geen freelancer(s)",
    "Common.noConnections": "Geen connecties",
    "Common.noProjects": "Geen projecten gevonden",
    "Common.noEmployeesProposed": "Geen kandidaten voorgesteld",
    "Common.hours": `{hours, plural,
      =0 { geen uren }
      one { uur }
      other { uren } 
    }`,
    "Common.close": "Sluiten",
    "Common.allDay": "Hele dag",
    "Common.accept": "Accepteer",
    "Common.reject": "Afwijzen",
    "Common.download": "Download",
    "Common.pagination":
      "{first, number}-{last, number} van de {total, number}",
    "Common.rebuildPagination":
        "van",
    "Common.rebuildPaginationMultiple":
        "meerdere",
    "Common.settings": "Instellingen",
    "Common.logged": "Ingelogd als",
    "Common.selected": "{items} geselecteerd",
    "Common.unsubscribe": "Afmelden",
    "Common.makeAnExport": "Maak een export",
    "Common.details": "Details",
    "Common.Share": "Dienst delen",
    "Common.ShareJobs": "Diensten delen",
    "Common.sendToMarketplace": "Publiceren op de marktplaats",
    "Common.status.job_applied": "Ingeschreven",
    "Common.status.job_accepted": "Ingepland",
    "Common.status.quiz_completed": "Quiz gemaakt",
    "Common.status.quiz_complete": "Quiz gemaakt",
    "Common.status.check_in": "Aangemeld",
    "Common.status.check_in_location": "Op locatie",
    "Common.status.upload_photo": "Foto's geüpload",
    "Common.status.evaluation": "Evaluatie",
    "Common.status.check_out": "Uitgecheckt",
    "Common.status.hour_declaration": "Urenregistratie opgestuurd",
    "Common.invalidStates.auto_canceled": "Niet gekozen",
    "Common.invalidStates.cancelled": "Geannuleerd",
    "Common.invalidStates.rejected": "Afgewezen",
    "Common.invalidStates.expired": "Verlopen",
    "Common.invalidStates.refused": "Afgewezen",
    "Common.invalidStates.already_scheduled": "Al ingepland",
    "Common.invalidStates.unsubscribed": "Uitgeschreven",
    "Common.invalidStates.sick": "Ziek gemeld",
    "Common.invalidStates.no_show": "No show",
    "Common.invalidStates.cancelled_by_supplier": "Geannuleerd door connectie",
    "Common.invalidStates.cancelled_by_owner": "Geannuleerd door werkgever",
    "Common.goToNewTables": "Wilt u de nieuwe tabellen proberen?",
    "ClientsModal.title": "Opdrachtgevers exporteren",
    "Common.goToNewPage": "Wil je de nieuwe pagina proberen?",
    "Common.goToOldDesigns": "Heb je de oude ontwerpen nog nodig?",
    "Common.basicDetails": "Basisgegevens",
    "Common.finance": "Financiën",
    "Common.yesterday": "Gisteren",
    "Common.today": "Vandaag",
    "Common.adjustAvailability": "Pas beschikbaarheid aan",
    "Common.todayAt": "Vandaag om {hour}",
    "Common.contacted": "Contact gehad",
    "Common.customNumber": "ID",
    "Common.verify": "Verifiëren",
    "Common.uploadImageOnly": "Je kunt alleen een afbeelding uploaden!",
    "Common.uploadSmallerImage": "Afbeelding moet minder dan 2mb zijn!",
    "Common.categories": "Categorieën",
    "Common.functions": "Op functie",
    "Common.seeDetails": "Bekijk details",
    "Common.shouldBeNumber": "Dit veld moet een nummer zijn",
    "Common.permissions": "Machtigingen",
    "Common.noProposals": "Geen voorstel aangemaakt",
    "Common.addingFiles": "Bestanden toevoegen",
    "Common.optionalInformation": "Optionele informatie",
    "Common.introduction": "Introductie",
    "Common.seeMore": "Uitgebreid zoeken",
    "Common.EmployeeInformation": "Medewerker informatie",
    "Common.noBillableHours": "Geen externe uren",
    "Common.allSpotsFilled": "Alle positie van de dienst zijn gevuld",
    "Common.uploadedFiles": "Bestanden toegevoegd",
    "Common.documents": "Aanvullende documenten (maximaal 5)",
    "Common.documentsDescription":
      "Upload hier aanvullende PDF documenten (CV & certificaten etc.).",
    "Common.nameAlreadyTaken":
      "De naam is al in gebruik, kies een andere naam of pas de oude aan",
    "Common.jobOwner": "Dienst eigenaar",
    "Common.employeeOwner": "Geregistreerd bij",
    "Common.exceptionStateName": "Bijzonderheidsstatus",
    "Common.exceptionStateId": "Bijzonderheidsstatus ID",
    "Common.invalidEmail": "Het emailadres is ongeldig",
    "Common.invalidGender":
      "De waarde voor gender is ongeldig ingevoerd (De optie is 'men' of 'women')",
    "Common.invalidCar":
      "An invalid car was provided (The option to be 'Yes', 'No', 'Not always' or 'sometimes')",
    "Common.invalidLicense":
      "De waarde voor license is ongeldig (De optie is 'Yes' of 'No')",
    "Common.invalidPublicTransportCard":
      "An invalid public transport card was provided (The option to be 'No', 'Week', 'Weekend', 'Discount' or 'Normal')",
    "Common.invalidFirstName":
      "De kolom voornaam is verplicht, deze kun je niet leeglaten",
    "Common.invalidCustomNumber":
      "Het aangepaste nummerveld mag maximaal 50 tekens bevatten",
    "Common.invalidPhoneNumber":
      "Het telefoonnummer is geen correct nummer, vergeet niet om alleen cijfers toe te voegen of ook het landcodenummer aan het begin toe te voegen, beginnend met '+'",
    "Common.invalidHouseNumber": "Het huisnummerveld is geen nummer",
    "Common.invalidContract":
      "De contractnaam is niet geregistreerd in Fleks, maak deze aan onder bij de instellingen of check je bestand voor een typefout",
    "Common.invalidFormatStartDateContract":
      "De startdatumformat voor het contract is ongeldig",
    "Common.invalidFormatEndDateContract":
      "De einddatumformat voor het contract is ongeldig",
    "Common.invalidDependeDateContract":
      "Het veld voor de begindatum van het contract en voor het einde van het contract is vereist als de contractnaam wordt toegevoegd",
    "Common.invalidFormatBirthDate": "De geboortedatumformat is ongeldig",
    "Common.invalidFormatExpiryDate": "De verloopdatumformat is ongeldig",
    "Common.invalidFormatRebateDate": "De datum van de loonheffing is ongeldig",
    "Common.invalidRebate":
      "Het invuldveld voor loonheffingskorting is ongeldig (De opties zijn 'Yes' of 'No')",
    "Common.invalidShoeSize": "De schoenmaat is geen nummer",
    "Common.invalidSocialNumber": "Het veld voor BSN is geen nummer",
    "Common.invalidEducation":
      "Een veld voor de opleiding is ongeldig (De opties zijn HBO, WO, MBO, No of Leeg)",
    "Common.invalidShirtSize":
      "Er is een ongeldige shirtmaat opgegeven (De opties zijn S, M, L, XL of XXL )",
    "Common.invalidLanguages":
      "Het taalveld is verplicht en het mag niet leeg zijn, ook de talen die zijn opgenomen, moeten in het systeem bestaan (Fleks ondersteunt talen zoals 'nl' of 'en')",
    "Common.invalidJobCategories":
      "De volgende functiegroep(en) zijn niet gevonden in het systeem: {categories}",
    "Common.invalidFreeFields":
      "De volgende vrije veld(en) hebben waarden die niet overeenkomen met de vooraf ingestelde waarden in het systeem: {freefields}",
    "Common.invalidHeight":
      "Het veld voor de lengte van de medewerker is dient ingevuld te worden als een nummer",
    "Common.invalidBankNumber": "Het rekeningnummer moet 18 tekens lang zijn",
    "Common.invalidPiercingsTattos":
      "Er werden ongeldige keuze voor piercings en tatoeages ingevuld (De opties zijn 'Yes', 'No', 'Yes but not visible' of 'unknown')",
    "Common.knowledgebase": "Kennisbank",
    "Common.AssignToTheJob": "Direct inplannen",
    "Common.AssignToTheJobAndExit": "Inplannen en afsluiten",
    "Common.kmDriven": "KM's gereden",
    "Common.searchProject": "Zoek project",
    "Common.reset2fa": "Reset 2FA methode",
    "Common.reset2fa.message.confirm":
      "U staat op het punt de verificatiemethode van deze gebruiker opnieuw in te stellen. De volgende keer dat hij zich probeert aan te melden bij Fleks, moet hij een nieuwe authenticatiemethode instellen. Wil je doorgaan?",
    "Common.additionalDates": "Extra data",
    "Common.sharedWith": "Gedeeld met",
    "Common.empty": " ",
    "HoursModal.title": "Gegevens exporteren",
    "JobsModal.title": "Gegevens exporteren",
    "ProjectsModal.title": "Gegevens exporteren",
    "shiftsModal.title": "Inplanningen exporteren",
    "DownloadModal.content":
      "Uw huidige selectie wordt als Excel-bestand geëxporteerd.",
    "DownloadModal.numberItems":
      "Dit is het aantal items dat zal worden geëxporteerd: ",
    "DownloadModal.customExportHere": "Pas hier uw export aan:",
    "DownloadModal.fullTable": "Standaard export",
    "DownloadModal.fullData": "Vooraf exporteren",
    "DownloadModal.salaryRun": "Salaris run",
    "DownloadModal.simplifiedSalaryRun": "Salaris run vereenvoudigt",
    "DownloadModal.invoiceInformation": "Factuur informatie",
    "DownloadModal.attendanceList": "Presentielijst",
    "JobsModal.projectName": "Naam van het project",
    "JobsModal.projectNumber": "Projectnummer",
    "JobsModal.startDate": "Startdatum",
    "JobsModal.endDate": "Einddatum",
    "JobsModal.city": "Stad",
    "JobsModal.address": "Adres",
    "JobsModal.name": "Naam",
    "JobsModal.prefix": "Tussenvoegsel",
    "JobsModal.lastName": "Achternaam",
    "JobsModal.phoneNumber": "Telefoonnummer",
    "Employees.phoneNumber.2": "Telefoonnummer",
    "Employees.invalidPhone": "Ongeldig telefoonnummer",
    "Employees.updateFunctions": "Voeg categorieën toe",
    "JobsModal.ShoeSize": "Schoenmaat",
    "JobsModal.height": "Lengte",
    "JobsModal.note": "Notitie",
    "ProjectsModal.projectName": "Project naam",
    "ProjectsModal.clientName": "Opdrachtgever naam",
    "ProjectsModal.jobCategoryName": "Categorie naam",
    "ProjectsModal.jobsNumber": "Aantal diensten",
    "ProjectsModal.jobsPending": "Aantal openstaande diensten",
    "ProjectsModal.projectManager": "Project manager",
    "ProjectsModal.startDate": "Startdatum",
    "ProjectsModal.endDate": "Einddatum",
    "ProjectsModal.projectNumber": "Projectnummer",
    "Contract.no": "Nee",
    "Contract.yes": "Ja",
    "Contract.essentials": "Essentials",
    "Contract.bankInformation": "Bank informatie",
    "Contract.tax": "Loonbelasting / inkomstenbelasting",
    "Contract.contracts": "Contracten",
    "Contract.active": "Actief?",
    "Contract.signed": "Ondertekend?",
    "Contract.contractPerWeek": "Contracturen per week",
    "Contract.howContractsWork": "Hoe werkt dit?",
    "Contract.howContractsWorkDescription":
      "Wanneer je met een medewerker hebt afgesproken dat hij of zij een standaard aantal uur per jaar/maand/week moet werken, kan je gebruik maken van deze instelling. Fleks rekent standaard met contracturen per week.",
    "Contract.contractDays": "Afgesproken contract dagen",
    "Contract.contractHours": "Contracturen",
    "Contract.minContractHours": "Contracturen (min)",
    "Contract.maxContractHours": "Contracturen (max)",
    "Contract.noValidContract": "Geen contract",
    "Contract.contractHours.hours": `{hours, plural,
      one { # uur }
      other { # uren }
    }`,
    "Contract.isSigned": "Is getekend?",
    "Contract.type": "Contract type",
    "Contract.duration-start": "Startdatum",
    "Contract.duration-end": "Einddatum",
    "Contract.month": "Maand(en)",
    "Contract.new": "Nieuw Contract",
    "Contract.noHoursContract": "Nul uren contract",
    "Contracts.no-contract": "Zonder contract",
    "Contracts.upcoming-to-expire": "Aflopend",
    "Contracts.no-signed": "Nog niet getekend",
    "Contracts.documents": "Documenten",
    "Contracts.hourPerWeek.onlyNumbers":
      "In dit veld kunnen alleen cijfers worden ingevoerd",
    "NewProjects.addLocation": "Vaste locatie toevoegen",
    "NewProjects.addBriefing": "Briefing (pdf) toevoegen",
    "NewProjects.addQuiz": "Maak direct een quiz aan",
    "NewProjects.addDeclaration": "Stel het declaratieformulier in",
    "NewProjects.chooseAClient": "Kies een opdrachtgever",
    "NewProjects.projectDescription": "Beschrijving project",
    "NewProjects.addAnImage": "Sfeerafbeelding toevoegen",
    "NewJobs.name": "Naam",
    "NewJobs.applicants": "Aantal medewerker(s)",
    "NewJobs.address": "Adres",
    "NewJobs.city": "Stad",
    "NewJobs.time": "Tijd",
    "NewJobs.startDate": "Startdatum",
    "NewJobs.criteria1": "Quiz verplicht?",
    "NewJobs.criteria2": "Reserve dienst?",
    "NewJobs.workflow": "Workflow-sjabloon",
    "NewJobs.isSuccessful": "Dienst aangemaakt",
    "NewJob.createJobs": "Maak dienst(en) aan",
    "NewJob.ongoingProjects": "1. Selecteer projecten",
    "NewJob.information": "2. Informatie",
    "NewJob.overview": "3. Overzicht",
    "NewStandardJob.information": "1. Informatie",
    "NewStandardJob.overview": "2. Overzicht",
    "NewJob.next": "Selecteer project",
    "NewJob.selectProject": "Selecteer een lopend project",
    "NewJob.filterByProject": "Filter op projectnaam",
    "NewJob.filterByClient": "Filter op opdrachtgever",
    "NewJob.addToConcepts": "Voeg toe aan concepten",
    "NewJob.concepts.list": "Concepten ({amount})",
    "NewJob.concepts.feedback":
      "Je hebt {amount} concepten toegevoegd, scroll naar beneden om ze te bekijken.",
    "NewJob.clearForm": "Leegmaken",
    "NewJob.useStandardJob": "Standaard dienst gebruiken",
    "NewJob.useStandardJob.description": "Kies een standaard dienst",
    "NewJob.useStandardJob.selectClient": "Selecteer een opdrachtgever",
    "NewJob.useStandardJob.selectAStandardJob": "Kies een standaard dienst",
    "NewJob.useStandardJob.useThis": "Gebruik standaard dienst",
    "NewJob.addTeamInstructions": "Voeg teaminstructies toe (pdf)",
    "NewJob.addExtraDocument": "Extra documenten toevoegen",
    "NewJob.addAutoScheduled": "Goedkeuringsinstellingen voor planning",
    "Step2.fillIn": "Vul in",
    "Step2.general": "Algemeen",
    "Step2.startDate": "Begindatum:",
    "Step2.startEndTime": "Begin- en eindtijd:",
    "Step2.name": "Naam:",
    "Step2.repeatJob": "Repeterende dienst",
    "Step2.notRepeat": "De dienst wordt niet herhaald",
    "Step2.daily": "Dagelijks",
    "Step2.weekly": "Wekelijks",
    "Step2.monthly": "Maandelijks",
    "Step2.customize": "Repeterende dienst",
    "Step2.amount": "Aantal medewerker(s):",
    "Step2.criteria": "Workflow stappen voor de medewerker",
    "Step2.quiz": "Medewerker moet een quiz maken",
    "Step2.reserve": "Dit is een reservedienst",
    "Step2.add": "Toevoegen",
    "Step2.newJobs": "Het volgende aantal diensten zal worden aangemaakt: ",
    "Step2.newStandardJobs": "Standaard diensten aan te maken: ",
    "Step2.addAnotherJobs": "Je kan nog %j diensten toevoegen",
    "Step2.addAnotherStandardJobs":
      "Je kan nog %j standaard diensten toevoegen",
    "Step2.totalJobs": "Maximaal aantal diensten: %t (Max 200)",
    "Step2.searchAddress": "Zoek je adres via Maps",
    "Step2.createdJobs": "Aan te maken diensten:",
    "Step2.createdStandardJobs": "Aangemaakte standaard diensten:",
    "Step2.previous": "Vorige",
    "Step2.upload": "'Dienst(en) aanmaken",
    "Step2.selectCriteria": "Selecteer workflow stappen",
    "Step2.zipCode": "Postcode",
    "Step2.state": "Stad",
    "Step2.city": "Stad & postcode",
    "Step2.ext": "Toevoeging",
    "Step2.number": "Huisnummer",
    "Step2.streetName": "Straat",
    "Step2.address": "Adres",
    "Step2.location": "Locatie",
    "Step2.wrong-date": "De startdatum moet in het huidige jaar vallen",
    "Step2.wrong-startTime": "De starttijd moet later zijn dan 00:00",
    "Step2.wrong-endTime": "De eindtijd moet later zijn dan 00:00",
    "Step2.empty-address": "Je hebt nog geen adresgegevens opgegeven",
    "Step2.empty-city": "Je hebt nog geen stad opgegeven",
    "Step2.empty-required-fields": "Huisnummer is een verplicht veld",
    "Step2.empty-workflow": "Je hebt nog geen workflow-sjabloon geselecteerd",
    "Step2.empty-category": "Je hebt nog geen functie geselecteerd",
    "Step2.empty-name": "Je hebt nog geen naam geselecteerd",
    "Step2.importInformation":
      "Zorg ervoor dat de sheet correct uitgelezen wordt, door de volgende velden in de eerste rij te zetten: Start date, Start time, End date, End time, Number of employees, City, Street, Number, Number extension, Zip code.",
    "Step2.importWithSheets": "Importeer diensten met sheets",
    "Step2.importJobs": "Importeer diensten",
    "Step2.edit": "Aanpassen",
    "Step2.importWarning.times": `De doorgegeven tijd is ongeldig`,
    "Step2.importWarning.dates": `De doorgegeven datum is ongeldig`,
    "Step2.importWarning.jobCategory":
      "De opgegeven functies zijn niet bekend in het systeem. Check of de namen overeenkomen",
    "Step2.importWarning.invalidProject":
      "Het projectnummer is niet gevonden. Check of dit overeen komt met het projectnummer in het systeem",
    "Step2.importWarning.pools":
      "De volgende pools zijn niet gevonden in het systeem: {pools}",
    "Step2.endDate": "Dit is een nachtdienst",
    "Step2.useWorkflow": "Workflow stappen vereist",
    "Step2.useWorkflow.false":
      "Er is geen actie vereist van de medewerker tijdens de dienst",
    "Step2.useWorkflow.true": "Specificeer de benodigde stappen",
    "Step2.useQuiz": "Quiz",
    "Step2.useQuiz.false":
      "De medewerker hoeft geen quiz te maken tijdens de dienst",
    "Step2.useQuiz.true":
      "Quiz vereist, niet vergeten deze aan het project toe te voegen!",
    "Step2.useReserve": "Reserve dienst",
    "Step2.useReserve.true":
      "Dit is een reserve dienst (op standby), de medewerker dient zich alleen aan te melden",
    "Step2.useReserve.false": "Dit is geen reserve dienst",
    "Step2.requiredFieldsToUpload":
      "Zorg ervoor dat alle verplichte velden ingevuld zijn",
    "Step2.empty-documents":
      "Zorg ervoor dat alle documenten een naam en beschrijving hebben",
    "NewEmployee.conceptList.textButtonUploadEmployee":
      "Medewerker(s) aanmaken",
    "RepeatWindow.defaultRepeated": "De dienst wordt elke dag herhaald",
    "RepeatWindow.repeat": "Herhaal deze dienst",
    "RepeatWindow.every": "Elke:",
    "RepeatWindow.day": "Dag(en)",
    "RepeatWindow.week": "Week/weken",
    "RepeatWindow.month": "Maand(en)",
    "RepeatWindow.mon": "Ma",
    "RepeatWindow.tue": "Di",
    "RepeatWindow.wed": "Wo",
    "RepeatWindow.thu": "Do",
    "RepeatWindow.fri": "Vr",
    "RepeatWindow.sat": "Za",
    "RepeatWindow.sun": "Zo",
    "RepeatWindow.monday": "maandag",
    "RepeatWindow.tuesday": "dinsdag",
    "RepeatWindow.wednesday": "woensdag",
    "RepeatWindow.thursday": "donderdag",
    "RepeatWindow.friday": "vrijdag",
    "RepeatWindow.saturday": "zaterdag",
    "RepeatWindow.sunday": "zondag",
    "RepeatWindow.ends": "Eind:",
    "RepeatWindow.never": "Nooit",
    "RepeatWindow.occurrences": "voorvallen",
    "RepeatWindow.on": "Op",
    "RepeatWindow.done": "Gedaan",
    "RepeatWindow.repeatedEvery": "De dienst wordt herhaald op/per:",
    "RepeatWindow.and": "en",
    "RepeatWindow.occurs": "De selectie zal zich",
    "RepeatWindow.times": "keer herhalen",
    "RepeatWindow.until": "Tot",
    "RepeatWindow.after": "Na",
    "Step3.overview": "Overzicht",
    "Step3.youCreated": "Je hebt het volgende aangemaakt",
    "Step3.jobsFor": "diensten voor",
    "Step3.standardJobsFor": "standaard diensten voor",
    "Step3.done": "Klaar",
    "EmployeeList.selectCancellation": "Selecteer de annulering",
    "EmployeeList.unDoCancellation": "Annulering ongedaan maken",
    "EmployeeList.cancelJob": "Annuleren",
    "EmployeeList.reject": "Afwijzen",
    "EmployeeList.noShow": "No-show",
    "EmployeeList.calledOff": "Ziekgemeld",
    "EmployeeList.expired": "Inschrijving verlopen",
    "EmployeeList.archive": "Archiveren",
    "EmployeeList.setException":
      "Markeer als Uitzondering (Specificeer de uitzondering doormiddel van een opmerking)",
    "EmployeeList.calculateTravelExpenses": "Bereken reiskosten",
    "EmployeeList.edit": "Aanpassing maken",
    "EmployeeList.workingHours": "Werkuren",
    "EmployeeList.extra": "Extra",
    "EmployeeList.breakHours": "Pauze uren",
    "EmployeeList.travelTime": "Reistijd",
    "EmployeeList.publicTransport": "OV-kosten",
    "EmployeeList.other": "Overig",
    "EmployeeList.schedule": "Inplannen",
    "EmployeeList.distance": "Afstand(km)",
    "EmployeeList.search": "Zoek",
    "EmployeeList.open": "Open",
    "EmployeeList.since": "sinds",
    "EmployeeList.onStandby": "Standby",
    "EmployeeList.activate": "Activeren",
    "EmployeeList.reserve": "Reserve",
    "EmployeeList.checkedIn": "Aanmelden",
    "EmployeeList.atLocation": "Op locatie",
    "EmployeeList.photos": "Foto's",
    "EmployeeList.areTaken": "Foto's",
    "EmployeeList.checkOut": "Uitgecheckt",
    "EmployeeList.hours": "Uren",
    "EmployeeList.areSent": "Declaratie",
    "EmployeeList.quiz": "Quiz",
    "EmployeeList.unarchive": "De-archiveren",
    "EmployeeList.notChosen": "Niet gekozen",
    "EmployeeList.jobAccepted": "Dienst geaccepteerd",
    "EmployeeList.evaluation": "Evaluatie",
    "EmployeeList.disabledButtonEdit":
      "Dit is een externe medewerker van: {tenantName}. Het is niet mogelijk om informatie aan te passen, alleen de externe partij kan dit aanpassen",
    "employee.confirmSaveTitle": "Wijziging opslaan",
    "employee.confirmSave": "Wil je de wijziging opslaan?",
    "Settings.profileSettings": "Je Profiel",
    "Settings.actions": "Acties",
    "Settings.name": "Naam",
    "Settings.phoneNumber": "Telefoonnummer",
    "Settings.accountSettings": "Inloggegevens",
    "Settings.email": "E-mail",
    "Settings.password": "Wachtwoord",
    "Settings.users": "Systeemgebruikers",
    "Settings.materiallist": "Materialen", 
    "Settings.materiallist.new": "Nieuwe",
    "Settings.materiallist.addMaterial": "Nieuw materiaal", 
    "Settings.materiallist.changeMaterial": "Wijzig materiaal",
    "Settings.materiallist.categoryField": "Categorie", 
    "Settings.materiallist.materialName": "Naam materiaal",
    "Settings.materiallist.review": "Opmerking", 
    "Settings.materiallist.addCategory": "Voeg een nieuwe categorie toe",
    "Settings.materiallist.saveMaterial": "Opslaan",
    "Settings.materiallist.cancelMaterial": "Annuleer",
    "Settings.materiallist.remark":"Opmerking", 
    "Settings.materiallist.deleteTitle":"Materiaal verwijderen",
    "Settings.materiallist.deleteFirstTextSplitFirst": "Weet je zeker dat je",
    "Settings.materiallist.deleteFirstTextSplitSecond": "wilt verwijderen ?",
    "Settings.materiallist.deleteSecondText": "Dit kan niet ongedaan gemaakt worden.",
    "Settings.materiallist.deleteNotification": "Materiaal <b>{item}</b> is succesvol verwijderd.",
    "Settings.materiallist.addNotification": "Materiaal <b>{item}</b> is succesvol toegevoegd",
    "Settings.materiallist.editNotification": "Materiaal <b>{item}</b> is succesvol bewerkt",
    "Settings.materiallist.example": "Bijv. t-shirt",
    "Settings.workflow": "Workflow",
    "Settings.defaultWorkflow": "Standaard workflow",
    "Settings.workflow.new": "Maak een nieuwe workflow aan",
    "Settings.declarationSettings.tittle": "Werkuren instellingen",
    "Settings.declarationSettings.description":
      "Gebruikers en medewerkers kunnen de werkuren van diensten aanpassen in stappen van <b class='avenir-next-demi-bold>{step} minuten</b>",
    "Settings.declarationSettings.edit.description":
      "Gebruikers en medewerkers kunnen de werkuren van diensten aanpassen in stappen van:",
    "Settings.declarationSettings.edit.minutes": "minuten",
    "Settings.declarationSettings.edit.timeInterval": "Tijdsinterval",
    "Settings.securitySettings.tittle": "Beveiliging",
    "Settings.securitySettings.description":
      "De tweefactorauthenticatie is <b class='avenir-next-demi-bold'>{status}</b> voor de admins en planners van de organisatie",
    "Settings.securitySettings.edit.2fa.tittle": "2-factor authenticatie",
    "Settings.securitySettings.edit.2fa.description":
      "Wilt u de Twee Factor Authenticatie inschakelen voor alle planners & admins van uw bedrijf?",
    "Settings.securitySettings.enabled": "ingeschakeld",
    "Settings.securitySettings.disabled": "uitgeschakeld",
    "Settings.standardDeclarationForm": "Standaard declaratieformulier",
    "Settings.standardDeclarationForm.description":
      "Dit declaratieformulier wordt standaard ingevuld wanneer je een project aanmaakt",
    "Settings.sharedDeclarationForm":
      "Declaratieformulier voor inkomende diensten",
    "Settings.sharedDeclarationForm.description":
      "Dit declaratieformulier wordt standaard gebruikt voor inkomende diensten van connecties",
    "Settings.connections": "Connecties",
    "Settings.connectionName": "Connectie naam",
    "Settings.activeSince": "Actief sinds",
    "Settings.jobsShared": "Aantal gedeelde diensten",
    "Settings.status": "Status",
    "Settings.connnectionActive": "Actief",
    "Settings.connnectionDeactivated": "Gedeactiveerd",
    "Settings.activationConfirm": `Weet je zeker dat je zeker dat je de connectie wilt activeren? <b> Je kunt weer diensten delen bij de connectie.</b>`,
    "Settings.deactivationConfirm": `Weet je zeker dat je de connectie wilt deactiveren? Je zult geen aanvragen meer kunnen sturen naar deze connectie <b> Alle diensten die al gedeeld zijn en gepland zullen nog steeds geldig zijn </b> `,
    "Settings.contractSettings": "Contracten voor de medewerkers",
    "Settings.categorySettings": "Categorieën voor poolmanagement",
    "Settings.sectors": "Functies",
    "Settings.qualifications": "Kwalificaties",
    "Settings.trainings": "Trainingen",
    "Settings.contractName": "Contractnaam",
    "Settings.workflowName": "Sjabloon naam",
    "Settings.workflowSteps": "Stappen",
    "Settings.paymentType": "Betaalmethode",
    "Settings.paidOnAge": "Op leeftijd",
    "Settings.fixedAmountPer": "Vast uurloon",
    "Settings.fixedAmount": "Vast bedrag",
    "Settings.rate": "Tarief",
    "Settings.cancel": "Annuleren",
    "Settings.create": "Aanmaken",
    "Settings.update": "Update",
    "Settings.documents": "Documenten",
    "Settings.hourlyRate.sub-header":
      "De uurlonen wanneer een project op leeftijd wordt uitbetaald",
    "Settings.hourlyRate": "Betaling op leeftijd",
    "Settings.from": "Van",
    "Settings.still": "tot",
    "Settings.hourlyRatesCreate": "Voeg uurlonen toe met start en einddatum",
    "Settings.hourlyRatesUpdate": "Pas de uurlonen voor de periode aan",
    "Settings.appointments": "Contactmogelijkheden",
    "Settings.appointments.description":
      "Maak hier mogelijkheden waaruit sollicitanten kunnen kiezen tijdens de registratie",
    "Settings.appointment.location": "Locatie",
    "Settings.appointment.dateTime": "Datum & tijd (optioneel)",
    "Settings.appointment.options": "Acties",
    "Settings.appointment.saved": "Nieuw contactmoment succesvol aangemaakt",
    "Settings.appointment.updated": "Contactmoment is aangepast",
    "Settings.appointment.archived": "Contactmoment gearchiveerd",
    "Settings.tenant.title": "Basisinformatie",
    "Settings.tenant.description":
      "Voeg hier de bedrijfsinformatie toe, dit zal zichtbaar zijn voor de medewerkers in de mobiele app op de informatiepagina.",
    "Settings.tenant.hours": "Jouw pakket",
    "Settings.tenant.hoursDone": "{currentHours} van {planHours} uren",
    "Settings.tenant.remainingMonth": "Te gebruiken tot {endOfMonth}",
    "Settings.tenant.checkOutPlans": "Bekijk de andere pakketten",
    "Settings.page.company": "Bedrijfsinformatie in de app",
    "Settings.page.company.desc":
      "Voeg hier de algemene informatie van je organisatie toe. De informatie en de documenten zijn terug te vinden op de over ons pagina in de mobiele app.",
    "Settings.page.company.adjust": "Pas de basisinformatie aan",
    "Settings.page.hr": "HR & Financiën",
    "Settings.page.users": "Gebruikers",
    "Settings.page.poole": "Poolmanager",
    "Settings.page.admin.changePassword": "Wachtwoord wijzigen? Klik hier",
    "Settings.page.admin": "Admin",
    "Settings.page.templates": "Poolmanager",
    "Settings.page.sharing": "Diensten delen",
    "Settings.page.sharing.desc":
      "Op deze pagina zijn alle instellingen wat betreft het diensten delen terug te vinden. Diensten delen geeft de mogelijkheid om een openstaande dienst aan te vragen bij andere organisatie.",
    "Settings.page.sharing.connections.pooleCombinations": "Pool combinaties",
    "Settings.page.sharing.connections.pooleCombinations.saved":
      "Opgeslagen pool combinaties",
    "Settings.page.sharing.connections.linkedPool": "Gekoppelde pool",
    "Settings.page.sharing.connections.employees": "Medewerker bereik",
    "Settings.page.sharing.connections.lastModified": "Laatst aangepast op",
    "Settings.page.sharing.connections.activeSince": `Connectie actief sinds {date}`,
    "Settings.page.sharing.connections.adjusting": `Je bent de pool combinatie aan het aanpassen voor de functie {function}.`,
    "Settings.page.sharing.connections.deleted":
      "De pool combinatie is verwijderd",
    "Settings.page.sharing.connections.edited":
      "De pool combiantie is aangepast",
    "Settings.page.sharing.connections.deleteConfirmation": `Je staat op het punt deze combinatie te verwijderen, weet je zeker dat je deze actie wilt uitvoeren? `,
    "Settings.page.rateCards": "Toeslagkaarten",
    "Settings.page.rateCards.ratecards.hoursCrossed": "Overlappende uren",
    "Settings.page.rateCards.ratecards.insufficientTime": "Onvoldoende tijd",
    "Settings.rateCards": "Toeslagkaarten",
    "Settings.rateCards.new": "Maak een nieuwe toeslagkaart aan",
    "Settings.rateCards.update": "Toeslagkaart aanpassen",
    "Settings.rateCards.name": "naam",
    "Settings.rateCards.percentage": "Percentage",
    "Settings.page.rateCards.surcharges": "Toeslagen",
    "Settings.page.rateCards.surcharges.creation": "Toeslag aanmaken",
    "Settings.page.rateCards.surcharges.modification": "Toeslag aanpassen",
    "Settings.page.rateCards.surcharges.new": "Nieuwe toeslag",
    "Settings.page.rateCards.surcharges.create": "Nieuwe toeslag aanmaken",
    "Settings.page.rateCards.surcharges.modify": "Toeslag aanpassen",
    "Settings.page.rateCards.surcharges.name": "Naam",
    "Settings.page.rateCards.surcharges.percentage": "Percentage",
    "Settings.page.rateCards.holidays": "Feestdagen",
    "Settings.page.rateCards.holidays.creation": "Feestdag toevoegen",
    "Settings.page.rateCards.holidays.modification": "Feestdag aanpassen",
    "Settings.page.rateCards.holidays.new": "Nieuwe feestdag",
    "Settings.page.rateCards.holidays.create": "Toevoegen",
    "Settings.page.rateCards.holidays.modify": "Aanpassen",
    "Settings.page.rateCards.holidays.name": "Naam",
    "Settings.page.rateCards.holidays.date": "datum",
    "Settings.page.reports": "Rapportages",
    "Settings.page.reports.title": "Templates voor rapportages",
    "Settings.page.reports.desc":
      "Maak zelf de templates voor de rapporten die nodig zijn voor je organisatie. Maak zelf onderscheid tussen admin & planning rapportages door de template voor planning beschikbaar te stellen.",
    "Settings.page.reports.new": "Export template toevoegen",
    "Settings.page.reports.item.columns": "kolommen",
    "Settings.pools": "Poolmanager",
    "Settings.pools.name": "Naam",
    "Settings.pools.total": "Aantal medewerkers",
    "Settings.pools.actions": "Acties",
    "Settings.pools.subText":
      "Maak en pas hier je standaard pools aan, deze kunnen aan projecten worden toegevoegd",
    "Settings.pools.create": "Nieuwe standaard pool",
    "Settings.pools.edit": "Standaard pool aanpassen",
    "Settings.pools.archive.confirmation":
      "Weet je het zeker? De pool zal verwijderd worden van de dienst(en) en project(en) waar de pool reeds is toegevoegd! Het is dus mogelijk dat openstaande diensten zichtbaar zullen worden voor ongekwalificeerde medewerker(s)",
    "Settings.afasImport": "AFAS importeren",
    "Settings.afasIntegration": "AFAS integratie",
    "Settings.afasIntegration.clientIDs": "Werkgever IDs",
    "Settings.afasIntegration.clientIDs.note":
      "Opmerking: Werkgever-ID's moeten worden gescheiden door komma's",
    "Settings.afasIntegration.clientIDs.spaces":
      "Er mogen geen spaties tussen de Werkgever-ID's staan.",
    "Settings.afasIntegration.settingTitle": "AFAS Medewerkers",
    "Settings.afasIntegration.settingDescription":
      "Geef aan of je connecties alleen medewerkers vanuit AFAS mogen voorstellen.",
    "Settings.afasIntegration.active":
      "Je connecties mogen alleen medewerkers vanuit AFAS voorstellen",
    "Settings.afasIntegration.inactive":
      "Je connecties mogen alle medewerkers voorstellen",
    "Settings.generateConnection": "Nieuwe connectie",
    "Settings.generateConnection.title": "Maak connectie",
    "Settings.generateConnection.description":
      "Kopieer de onderstaande link en stuur deze naar het bedrijf waarmee u een connectie wilt maken!",
    "Settings.username": "Gebruikersnaam",
    "Settings.functions": "Functies",
    "Settings.functions.subText": "Bewerk en maak hier je functies aan",
    "Settings.functions.create": "Voeg een functie toe",
    "Settings.functions.edit": "Wijzig functie",
    "Settings.functions.name": "Naam",
    "Settings.functions.description": "Beschrijving",
    "Settings.functions.actions": "Acties",
    "Settings.notificationsAndEmails": "Notificaties en e-mails",
    "Settings.organizationAdmin": "Admin organisatie",
    "Settings.organizationAdmin.desc":
      "Op deze pagina kun je de desktopgebruikers toevoegen, machtigingen beheren en workflows maken.",
    "Settings.userRoles": "Gebruikersrechten",
    "Settings.userRoles.admin": "Admin",
    "Settings.userRoles.admin.desc": "Alle bevoegdheden.",
    "Settings.userRoles.manager": "Manager",
    "Settings.userRoles.manager.desc":
      "De manager kan de Fleks-managerapplicatie gebruiken om via de app inzicht te krijgen wie er ingepland of op locatie is. Daarnaast kan de managerapp ook gebruikt worden voor het in en uitchecken met de QR-code.",
    "Settings.userRoles.planner": "Planner",
    "Settings.userRoles.planner.desc":
      "Gebruikers kunnen de planning en verklaringen beheren. Ze kunnen de privacy gevoelige persoonsgegevens van de medewerkers echter niet lezen.",
    "Settings.userRoles.client": "Opdrachtgever",
    "Settings.userRoles.client.desc":
      "Laat je opdrachtgever zelf diensten aanmaken, zijn pool inplannen en kandidaten accepteren. Opdrachtgevers krijgen geen inzicht in de details van de medewerkers en data te zien van andere opdrachtgevers.",
    "Settings.accounts.active": "Actieve accounts",
    "Settings.accounts.inactive": "Gedeactiveerde accounts",
    "Settings.accounts.new": "Nieuwe gebruiker",
    "Settings.accounts.creation": "Account aanmaken",
    "Settings.accounts.modification": "Account wijzigen",
    "Settings.accounts.new.description":
      "Maak een systeemgebruiker voor het dashboard.",
    "Settings.accounts.create": "Gebruiker aanmaken",
    "Settings.accounts.modify": "Gebruiker wijzigen",
    "Settings.accounts.2fa.messageResetSuccess":
      "De 2FA-methode voor de gebruiker is met succes gereset.",
    "Settings.accounts.2fa.messageResetError":
      "Er is een probleem opgetreden bij het resetten van de 2FA-methode voor de gebruiker.",
    "Settings.accounts.optional.clients": "Je kunt optioneel een opdrachtgever toevoegen aan een planner gebruiker. De planner kan op deze manier alleen plannen voor de geselecteerde opdrachtgever(s).",
    "Settings.freeFields.add": "Vrij veld toevoegen",
    "Settings.freeFields": "Vrije velden voor medewerkers",
    "Settings.jobFreeFields": "Dienst vrije velden",
    "Settings.freeFields.new": "Nieuw veld",
    "Settings.freeFields.creation": "Veld aanmaken",
    "Settings.freeFields.modification": "Veld aanpassen",
    "Settings.freeFields.create": "Veld aanmaken",
    "Settings.freeFields.modify": "Veld aanpassen",
    "Settings.freeFields.fillableSwitch":
      "In te vullen door medewerkers in het sollicitatieformulier",
    "Settings.freeFields.visibleSwitch": "Zichtbaar voor medewerkers en sollicitanten",
    "Settings.freeFields.mandatorySwitch": "Is verplicht",
    "Settings.freeFields.checkboxEnableValuesOption":
      "Specificeer de waarden van dit veld",
    "Settings.freeFields.inputEnableValuesOption": "Waarden",
    "Settings.freeFields.jobContactPerson": "Dienst contactpersoon",
    "Settings.exceptionState.tittle": "Bijzonderheidsstatus",
    "Settings.exceptionState.add": "Status toevoegen",
    "Settings.exceptionState.add.id": "ID",
    "Settings.exceptionState.creation": "Bijzonderheidsstatus aanmaken",
    "Settings.exceptionState.modification": "Bijzonderheidsstatus aanpassen",
    "Settings.exceptionState.create": "Bijzonderheidsstatus aanmaken",
    "Settings.exceptionState.modify": "Bijzonderheidsstatus aanpassen",
    "Settings.exceptionState.switchBreakInvoiced.title":
      "Pauze wordt gefactureerd",
    "Settings.exceptionState.switchBreakPayedOut.title":
      "Pauze wordt uitbetaald",
    "Settings.exceptionState.switchWorkingHoursInvoiced.title":
      "Werkuren worden gefactureerd",
    "Settings.exceptionState.switchWorkingHoursPayedOut.title":
      "Werkuren worden uitbetaald",
    "Settings.exceptionState.switchCostsInvoiced.title":
      "Kosten worden gefactureerd",
    "Settings.exceptionState.switchCostsPayedOut.title":
      "Kosten worden uitbetaald",
    "Settings.tenantSettings": "Externe uren",
    "Settings.tenantSettings.billable hours": "Externe uren",
    "Settings.tenantSettings.createBillableHoursDescription":
      "Met de externe uren kun je het aantal uren specificeren dat voor een dienst wordt gefactureerd, onafhankelijk van de werkuren. Als je deze optie inschakelt, kun je via Fleks de uren met je connectie beheren (geldt dus alleen voor gedeelde diensten)",
    "Settings.tenantSettings.applyBreakToBillableHours":
      "Trek de pauze uren af van de te factureren uren",
    "Settings.salaryProfiles": "Loonprofielen",
    "Settings.salaryProfiles.add": "Nieuw loonprofiel",
    "Settings.salaryProfiles.activeFrom": "Actief vanaf {start_date}",
    "Settings.salaryProfiles.create": "Nieuw loonprofiel aanmaken",
    "Settings.salaryProfiles.update": "Loonprofiel aanpassen",
    "Settings.salaryProfiles.name": "Naam",
    "Settings.salaryProfiles.customId": "ID",
    "Settings.salaryProfiles.description":
      "Maak uurtarieven aan in het loonprofiel. Deze kunnen worden gekoppeld aan een contract, functie of direct aan een medewerker.",
    "Settings.salaryProfiles.maximumAgeMessage":
      "Medewerkers die <b>{age}</b> jaar of ouder zijn krijgen een uurloon van <b>€{wage}</b>",
    "Settings.salaryProfiles.addAgeRatePair":
      "Je moet minimaal een leeftijd / bedrag per uur koppel hebben per periode",
    "Settings.salaryProfiles.newRatePeriod": "Voeg nieuwe tariefperiode toe",
    "Settings.salaryProfiles.deleteRatePeriod":
      "Je staat op het punt de tariefperiode te verwijderen, weet je het zeker?",
    "Settings.availabilityPeriodLimit":
      "Periode vastzetten beschikbaarheid (in dagen)",
    "Settings.availabilityPeriodLimit.adjust": "Pas de periode aan",
    "Settings.availabilityPeriodLimit.subtitle":
      "Kies het aantal dagen in de toekomst dat de medewerker geen beschikbaarheid meer kan opgeven.",
    "Setting.pooleSettings.confirmDelete":
      "Weet je het zeker? Reeds aangemaakte diensten zullen dezelfde functie behouden. De categorie wordt echter wél verwijderd van medewerkers en pools. Het is dus mogelijk dat openstaande diensten zichtbaar zullen worden voor ongekwalificeerde medewerker(s)",
    "Settings.organizationSettings": "KM vergoeding regel",
    "Settings.organizationSettings.adjust": "vergoeding aanpassen",
    "Settings.organizationSettings.subtitle": "Eerste aantal KM's niet vergoed",
    "Settings.organizationSettings.labelKM": "Afstand (KM)",
    "Setting.acceptingHours.card.title": "Uren goedkeuren",
    "Setting.acceptingHours.card.subtitle":
      "Selecteer wie verantwoordelijk is voor de uren. De andere partij kan deze uren niet aanpassen",
    "Setting.acceptingHours.card.titleAdjust": "Pas uren goedkeuren aan",
    "Setting.acceptingHours.card.switchDisable":
      "De eigenaar van de uren is mijn organisatie",
    "Setting.acceptingHours.card.switchEnable":
      "De eigenaar van de uren is de eigenaar van de externe medewerkers.",
    "Setting.autoScheduledEmployee.card.title":
      "Automatisch inplannen door medewerkers",
    "Setting.autoScheduledEmployee.card.subtitle":
      "Geef aan of je wil dat medewerkers zichzelf automatisch kunnen inplannen voor diensten van connecties.",
    "Setting.autoScheduledEmployee.card.titleAdjust":
      "Pas automatisch inplannen door medewerkers aan",
    "Setting.autoScheduledEmployee.card.switchEnable":
      "Je medewerkers kunnen zichzelf automatisch inplannen voor diensten van connecties.",
    "Setting.autoScheduledEmployee.card.switchDisable":
      "Je medewerkers kunnen zichzelf niet automatisch inplannen voor diensten van connecties.",
    "EditTenantData.name": "Bedrijfsnaam",
    "EditTenantData.description": "Bedrijfsbeschrijving",
    "EditTenantData.email": "Info email",
    "EditTenantData.phone": "Telefoon",
    "EditTenantData.mobile": "Mobiel",
    "EditTenantData.logo": "Logo",
    "EditTenantData.updatedError":
      "De nieuwe informatie van de opdrachtgever is niet opgeslagen",
    "CreateAppointment.city": "Stad",
    "CreateAppointment.city.required": "Het invullen van een stad is verplicht",
    "CreateAppointment.city.max": "Stad mag maximaal 100 karakters bevatten",
    "CreateAppointment.street": "Straat",
    "CreateAppointment.street.required":
      "Het invullen van een straat is verplicht",
    "CreateAppointment.street.max":
      "Straat mag maximaal 100 karakters bevatten",
    "CreateAppointment.houseNumber": "Huisnummer",
    "CreateAppointment.houseNumber.max":
      "Huisnummer mag maximaal 10 karakters hebben",
    "CreateAppointment.houseNumberExt": "Huisnummer toevoeging",
    "CreateAppointment.houseNumberExt.max":
      "Huisnummer toevoeging mag maximaal 15 karakters bevatten",
    "CreateAppointment.zipCode": "Postcode",
    "CreateAppointment.zipCode.max":
      "Postcode mag maximaal 6 karakters bevatten",
    "CreateAppointment.host": "Host",
    "CreateAppointment.datetime": "Datum & tijd (optioneel)",
    "HourlyRates.dateRange": "Selecteer de periode",
    "HourlyRates.newRate": "Voeg een leeftijd en uurloon toe",
    "HourlyRates.created": "Uurloon is succesvol aangemaakt",
    "HourlyRates.updated": "Uurloon is aangepast",
    "HourlyRates.age": "Leeftijd",
    "HourlyRates.rate": "Bedrag per uur",
    "HourlyRates.start_date": "Ingangsdatum",
    "HourlyRates.end_date": "Einddatum",
    "HourlyRates.no-data": "Nog geen uurloon aangemaakt",
    "HourlyRates.age-duplicated":
      "Een uurloon voor deze leeftijd is al aangemaakt",
    "HourlyRates.minAge": "De minimale leeftijd is 16.",
    "Planner.header.filters": "Filter de getoonde kaarten",
    "Planner.header.settings": "Planner voorkeuren",
    "Planner.header.newJob": "Nieuwe dienst aanmaken",
    "Planner.newJobModal.title": "Dienst aanmaken",
    "Planner.newJobModal.date": "Datum",
    "Planner.newJobModal.client": "Opdrachtgever",
    "Planner.newJobModal.project": "Project",
    "Planner.newJobModal.jobDetails": "Naar dienst details",
    "Planner.newJobModal.stepOneCompleted.desc": `Je maakt een dienst voor <b>{date}</b> het project <b>{project_name}</b> bij <b>{client_name}</b>`,
    "Planner.newJobModal.stepOneCompleted.adjust": "Pas deze informatie aan",
    "Planner.newJobModal.stepOneCompleted.adjust.bankInformation":
      "Bankgegevens aanpassen",
    "Planner.newJobModal.details": "Dienst details",
    "Planner.newJobModal.standardJob": "Standaard dienst (Optioneel)",
    "Planner.newJobModal.jobCategory": "Type functie",
    "Planner.newJobModal.pools": "Pool (Optioneel)",
    "Planner.newJobModal.street": "Straatnaam",
    "Planner.newJobModal.houseNumber": "Huisnummer",
    "Planner.newJobModal.additionalInfo": "Toevoeging (Optioneel)",
    "Planner.newJobModal.postalCode": "Postcode",
    "Planner.newJobModal.city": "Stad",
    "Planner.newJobModal.startTime": "Starttijd",
    "Planner.newJobModal.endTime": "Eindtijd",
    "Planner.newJobModal.available": "Aantal posities",
    "Planner.newJobModal.workflow": "Worfklow sjabloon",
    "Planner.newJobModal.createJob": "Maak dienst(en) aan",
    "Planner.newJobModal.jobCreatedSuccess": "De dienst is aangemaakt",
    "Planner.title": "Planner",
    "Planner.Week.today": "Vandaag",
    "Planner.Week.week": "Week",
    "Planner.noJobs": "Geen diensten",
    "Planner.stats.positions": "posities",
    "Planner.Details.jobLocation": "Geen vaste locatie",
    "Planner.Details.jobInformation": "Info van de dienst",
    "Planner.Details.scheduled": "Ingepland",
    "Planner.Details.registered": "Ingeschreven",
    "Planner.Details.candidates": "Kandidaten",
    "Planner.Details.extensiveSearch": "Uitgebreid zoeken",
    "Planner.Details.search": "Zoek",
    "Planner.Details.showMore": "Zie meer",
    "Planner.Details.seeAll": "Zie alle",
    "Planner.Details.employees": "Medewerkers",
    "Planner.Details.noJobSelected": "Je hebt nog geen dienst geselecteerd",
    "Planner.Details.selectAJob":
      "Selecteer eerst een dienst om de bijbehorende informatie te zien.",
    "Planner.Details.invited": "Uitgenodigd",
    "Planner.Details.clusterInformation": "Cluster informatie",
    "Planner.fullJobs": "Zie volle diensten",
    "Planner.filters.client": "Opdrachtgever",
    "Planner.jobBecomeFull": "Dienst is al vol",
    "Planner.filters.name": "Opdrachtgever",
    "Planner.filters.project": "Projectnaam",
    "Planner.filters.startDate": "Startdatum",
    "Planner.filters.endDate": "Einddatum",
    "Planner.filters.projectManager": "Project manager",
    "Planner.filters.typeOfWork": "Functie",
    "Planner.filters.week": "Week",
    "Planner.filters.city": "Adres",
    "Planner.filters.isFull": "Zie volle diensten",
    "Planner.filters.cluster": "Cluster",
    "Planner.clusterView": "Clusters",
    "Planner.closeDetails": "Sluit zijbalk",
    "Planner.settings": "Planner instellen",
    "Planner.settings.back": "Terug naar planner",
    "Planner.settings.cancel": "Annuleer",
    "Planner.settings.save": "Sla wijzigingen op",
    "Planner.settings.setUp": "Jouw planner instellen",
    "Planner.settings.setUp.desc":
      "Dit zijn je planner instellingen. Pas de volgende informatie aan zodat het beter past bij jouw voorkeuren.",
    "Planner.settings.jobCluster": "Dienstkaartjes clusteren",
    "Planner.settings.jobCluster.desc":
      "Kies hier of jij je diensten wilt clusteren. Wanneer je de clustering aanzet worden de diensten geclusterd op opdrachtgever, project en functieniveau.",
    "Planner.settings.jobCluster.toggleAction": "Groepeer mijn diensten",
    "Planner.settings.jobCluster.notApplied":
      "Jouw diensten zijn niet geclusterd.",
    "Planner.settings.jobCluster.applied": "Jouw diensten zijn geclusterd.",
    "Planner.settings.fullJobs": "Gevulde diensten",
    "Planner.settings.fullJobs.desc":
      "Kies hier of jij ook alle gevulde diensten wilt zien in de planner.",
    "Planner.settings.fullJobs.toggleAction": "Toon gevulde diensten",
    "Planner.settings.fullJobs.notApplied":
      "Gevulde diensten worden niet getoond.",
    "Planner.settings.fullJobs.applied": "Gevulde diensten worden getoond.",
    "Planner.settings.sortCandidates": "Sorteer geschikte kandidaten",
    "Planner.settings.sortCandidates.desc":
      "Kies hoe jij je geschikte kandidaten wilt sorteren in de zijbalk.",
    "Planner.settings.sortCandidates.availability":
      "Altijd eerst op beschikbaarheid sorteren",
    "Planner.settings.sortCandidates.distance": "Afstand",
    "Planner.settings.sortCandidates.hoursWorked":
      "Gewerkte uren / contracturen",
    "Planner.settings.sortCandidates.timesWorked":
      "Aantal keren bij opdrachtgever gewerkt",
    "Planner.settings.jobCardInformation":
      "Dienstkaartjes informatie en groepering",
    "Planner.settings.jobCardInformation.desc":
      "Selecteer welke informatie je wilt zien in je dienstkaartjes en of de diensten moeten worden gegroepeerd. Hoe meer elementen aanstaan, hoe groter een dienstkaartje wordt.",
    "Planner.settings.jobCardInformation.cardLooks":
      "Zo gaan jouw dienstkaartjes eruit zien",
    "Planner.settings.customizeCandidates": "Instellingen voor kandidaten",
    "Planner.settings.customizeCandidates.desc":
      "Kies de instellingen voor je kandidaten",
    "Planner.settings.customizeCandidates.applied": "Zie alle kandidaten",
    "Planner.settings.customizeCandidates.notApplied":
      "Geef alle kandidaten weer",
    "Planner.settings.customizeCandidates.excludeUsers":
      "Sluit de kandidaten uit die:",
    "Planner.settings.customizeCandidates.noValidContract":
      "Geen geldig contract hebben",
    "Planner.settings.customizeCandidates.invitedToAnotherJob":
      "Al uitgenodigd zijn",
    "Planner.settings.customizeCandidates.scheduledToAnotherJob":
      "Al ingepland zijn",
    "Planner.goToOld": "Behoefte aan de oude planner?!",
    "Planner.jobCard.pendingSpots": "Openstaand ({pendingSpots})",
    "Planner.jobCard.totalSchedules": `{totalSchedules, plural,
        =0 { Geen inplanningen }
        other { # inplanningen }
      }`,
    "Planner.jobCard.full": "Gevuld",
    "Planner.Details": "Details",
    "Planner.Details.close": "Sluit zijbalk",
    "Planner.Employees.planned": "Ingepland",
    "Planner.Employees.pending": "In afwachting",
    "Planner.Employees.proposed": "Voorgesteld",
    "Planner.Employees.proposals": "Voorstellen",
    "Planner.pending.registered": "Ingeschreven",
    "Planner.pending.invited": "Uitgenodigd",
    "Planner.pending.canceled": "Geannuleerd",
    "Planner.pending.sick": "Ziek",
    "Planner.pending.noShow": "No-show",
    "Planner.employees.internals": "Intern",
    "Planner.employees.externals": "Extern",
    "Planner.Employees.incoming": "Externe medewerkers",
    "Planner.Employees.incoming.count": "Externe medewerkers - {total}",
    "Planner.Employees.candidates": "Kandidaten - {total}",
    "Planner.candidates.search": "Zoek medewerker",
    "Planner.clusterDetails": "Cluster details",
    "Planner.clusteredJobs": "Geclusterde diensten",
    "Planner.clusteredJobs.planClusterIn": "Plan cluster in",
    "Planner.clusteredJobs.overview.contains": "Deze cluster bevat",
    "Planner.clusteredJobs.overview.contains.totalJobs": `diensten, waarvan`,
    "Planner.clusteredJobs.overview.contains.sharedJobs": `{totalSharedJobs, plural,
      one {is uitgezet}
      other {is uitgezet}
    }`,
    "Planner.clusteredJobs.overview.furthermore": "Daarnaast rekenen wij:",
    "Planner.clusteredJobs.overview.furthermore.openSpots":
      "openstaande posities met",
    "Planner.clusteredJobs.overview.furthermore.applications": "inschrijvingen",
    "Planner.clusteredJobs.overview.furthermore.invitations":
      "verstuurde uitnodigingen",
    "Planner.startPlanning": "Begin met plannen",
    "Planner.jobDetails": "Dienst details",
    "Planner.openPositions": `{pendingSpots, plural,
      0= { Geen openstaande posities }
      one { # Openstaande positie }
      other { # Openstaande posities }
    }`,
    "Planner.selectedEmployees": "Geselecteerd",
    "Planner.selectedEmployeesInfo": "Deselecteer hier de medewerkers",
    "Planner.selectEmployees": "Kandidaten",
    "Planner.selectProposed": "Voorgestelde externe kandidaten",
    "Planner.selectFreelancers": "Ingeschreven freelancers",
    "Planner.applicationDate": "Ingeschreven op",
    "Planner.selectEmployeesToBeProposed":
      "Selecteer de voor te stellen medewerkers",
    "EmployeeCard.goBack": "Terug naar informatie",
    "Planner.afasIntegration.notPropose":
      "Je kan geen medewerkers voorstellen of inplannen die niet zijn geïmporteerd vanuit AFAS.",
    "Planner.noData.header": "Geen diensten selecteerbaar",
    "Planner.noData.callToAction":
      "Selecteer een week met diensten om in te plannen",
    "EmployeeCard.reschedule.attentionText": "Let op!",
    "EmployeeCard.reschedule.warning": " De medewerker staat al ingepland op dit tijdstip.",
    "EmployeeCard.reschedule.reason": "Wil je de oude dienst annuleren en de medewerker inplannen op de geselecteerde dienst?",
    "EmployeeCard.reschedule.overlappingText": "De geselecteerde dienst overlapt met de onderstaande dienst:",
    "EmployeeCard.cancellation.reason": "Waarom annuleer je de inplanning?",
    "EmployeeCard.cancellation.reason.noShow":
      "De kandidaat is niet komen opdagen",
    "EmployeeCard.cancellation.reason.sick":
      "De kandidaat heeft zich ziekgemeld",
    "EmployeeCard.cancellation.reason.noReason": "Andere reden",
    "EmployeeCard.cancellation.reason.include": "Meenemen in de salarisrun",
    "EmployeeCard.cancellation.beforeJobStarts":
      "Je annuleert de inplanning {expiration} voordat de dienst begint.",
    "EmployeeCard.cancellation.reason.save": "Reden opslaan",
    "EmployeeCard.cancellation.confirmation.cancellationNoPast":
      "Weet je zeker dat je de inplanning wilt annuleren? Je kan de inplanning zonder consequenties annuleren tot {date} om {hour}.",
    "EmployeeCard.cancellation.confirmation.cancellationPast":
      "Je annuleert binnen de datum van het annuleringsbeleid. De annuleringsdatum was {date} om {hour}. ",
    "EmployeeCard.cancellation.confirmation.save": "Bevestig annulering",
    "EmployeeCard.cancellation.confirmation.noCancellationPolicy":
      "U kunt deze dienst vrijelijk annuleren omdat er geen annuleringsbeleid is ingesteld",
    "Scheduler.header.settings": "Roosterbord voorkeuren",
    "Scheduler.settings": "Roosterbord instellingen",
    "Scheduler.settings.back": "Terug naar roosterbord",
    "Scheduler.settings.explication.title": "Jouw roosterbord instellen",
    "Scheduler.settings.explication.description":
      "Dit zijn je roosterbord instellingen. Pas de volgende informatie aan zodat het beter past bij jouw voorkeuren.",
    "Scheduler.settings.job.title": "Dienstkaartjes informatie",
    "Scheduler.settings.job.description":
      "Selecteer welke informatie je wilt zien in je dienstkaartjes. Hoe meer elementen aanstaan, hoe groter een dienstkaartje wordt.",
    "Scheduler.settings.job.preview": "Zo gaan jouw dienstkaartjes eruit zien:",
    "Scheduler.settings.order.title": "Medewerker sortering",
    "Scheduler.settings.order.description":
      "Hoe moeten de medewerkers worden gesorteerd in het roosterbord?",
    "Scheduler.settings.orderAtoZ": "Naam A-Z",
    "Scheduler.settings.orderZtoA": "Naam Z-A",
    "Scheduler.settings.orderUnplannedHoursAsc": "Ongeplande uren oplopend",
    "Scheduler.settings.orderUnplannedHoursDesc": "Ongeplande uren aflopend",
    "Scheduler.settings.orderZipCodeAsc": "Postcode - oplopend",
    "Scheduler.settings.orderZipCodeDesc": "Postcode - aflopend",
    "Scheduler.filters.isAvailableOn": "Is beschikbaar op",
    "Scheduler.filters.isAvailableOn.and": "En",
    "Scheduler.filters.isAvailableOn.or": "Of",
    "Notifications.header": "Notificaties",
    "Notifications.footer.clearAll": "Verwijder alle notificaties",
    "Notifications.footer.clearAllPopconfirm":
      "Weet u zeker dat u alle notificaties wilt verwijderen?",
    "Notifications.info.close": "Sluit weergave",
    "Notifications.info.goToPage": "Ga naar pagina",
    "Notifications.info.download": "Download nu",
    "Notifications.delete": "Verwijderen",
    "Notifications.finqle.approveError": `Je kunt de uren niet naar verwerkt zetten vanuit fleks omdat de uren en kosten eerst verwerkt moeten worden in Finqle, kijk op app.connections.fleks.works om de status van de uren op te zoeken.`,
    "Notifications.finqle.acceptError": `De organisatie heeft nog geen toeslagkaart geselecteerd waarmee rekening moet houden  gehouden voor de koppeling met finqle, neem contact op met fleks.`,
    "Notifications.deleteSuccess": "De kennisgeving is verwijderd",
    "Planner.Connections": "Connecties",
    "Candidates.available.title": "Beschikbaar",
    "Candidates.partlyAvailable.title": "Deels beschikbaar",
    "Candidates.notAvailable.title": "Niet beschikbaar",
    "Candidates.noData": "Geen medewerker(s) gevonden",
    "Candidates.notUpdated.title": "Onbekend",
    "Candidates.errors.load":
      "[section_name]: We konden de medewerkers niet inladen",
    "AvailabilityPercentage.partlyAvailable":
      "[first_name] heeft aangegeven beschikbaar te zijn van [start_time] tot [end_time]",
    "AvailabilityPercentage.contact": "Telefoon: [phone_number]",
    "AvailabilityPercentage.wholeDay":
      "[first_name] heeft aangegeven de hele dag beschikbaar te zijn.",
    "AvailabilityPercentage.wholeDayNotAvailable":
      "[first_name] heeft aangegeven de hele dag niet beschikbaar te zijn.",
    "AvailabilityPercentage.alreadyScheduled":
      "[first_name] staat al ingepland op een dienst",
    "MiniWorkFlow.operationNotAllowed": "Handeling niet mogelijk",
    "Shifts.all": "Alle",
    "Shifts.confirm-archive-checked":
      "Weet je het zeker? De inplanning komt in het archief te staan",
    "Shifts.title": "Inplanningen",
    "Shifts.isCompleted": "Workflow voltooid?",
    "Shifts.activeStatus": "Huidige stap",
    "Shifts.uncompletedStatus": "Stap incompleet",
    "Shifts.validOnly": "Alleen geldig",
    "Shifts.invalidState": "Annulering status",
    "Shifts.upcoming": "Aankomende",
    "Shifts.emptyState.title": "Er zijn geen medewerkers ingepland",
    "Shifts.emptyState.description":
      "Keer terug naar de medewerkers pagina en voeg een nieuwe medewerker toe.",
    "Shifts.inProgress": "Aan het werk",
    "Shifts.workflowStatus": "Workflow status",
    "Shifts.toOldDesign": "Klik hier voor het oude overzicht?",
    "Shifts.goToTablePreferences": "Ga naar tabel voorkeuren",
    "Shifts.advancedFilters.close": "Sluit zijbalk",
    "Shifts.advancedFilters.title": "Alle filters",
    "Shifts.archived": "{items} inplanningen gearchiveerd",
    "Shifts.client": "Opdrachtgever",
    "Shifts.clientId": "Opdrachtgever ID",
    "Shifts.telephoneNumber": "Telefoonnummer",
    "Shifts.project": "Project",
    "Shifts.time": "Tijd",
    "Shifts.workflowModalTitle": "Bevestig de in- en uitchecktijd",
    "Shifts.worflowLocationButton": "Bevestigen",
    "Shifts.checkIn": "Inchecktijd",
    "Shifts.checkOut": "Uitchecktijd",
    "Shifts.archive.confirmation.material": "U staat op het punt een bepaald artikel te retourneren, weet u het zeker?",
    "Shifts.archive.confirmation": `Je staat op het punt om
    {items, plural,
      one {# inplanning}
      other {# inplanningen}    
    } te {archive, select,
      true {de-archiveren}
      false {archiveren}
    },weet je zeker dat je deze actie wilt uitvoeren?`,
    "Shift.workflow.confirmation":
      "Weet je zeker dat je de stap wilt voltooien?",
    "Shift.jobAccepted": "Dienst geaccepteerd",
    "Shift.quizComplete": "Quiz voltooid",
    "Shift.checkIn": "Aangemeld",
    "Shift.checkInLocation": "Op locatie",
    "Shift.uploadPhoto": "Foto's geüpload",
    "Shift.checkOut": "Uitgecheckt",
    "Shift.hourDeclaration": "Uren verstuurd",
    "Shift.evaluation": "Evaluatie",
    "Shift.exceptionState.modal.title": "Bijzonderheidsstatus toevoegen",
    "Shift.exceptionState.modal.select": "Selecteer de status",
    "Shift.updateJobApplications.allUpdated":
      "Alle inplanningen zijn aangepast",
    "Shift.updateJobApplications.updated": "de inplanning is aangepast",
    "Shift.updateJobApplications.updated.exceptionState":
      "De bijzonderheidsstatus is aangepast",
    "Shift.updateJobApplications.notUpdated.exceptionState":
      "De bijzonderheidsstatus is niet aangepast",
    "Shift.updateJobApplications.allNotUpdated":
      "Het was niet mogelijk om de inplanningen aan te passen",
    "Shift.updateJobApplications.oneNotUpdated":
      "Éen of meer inplanningen zijn niet aangepast",
    "Shift.updateJobApplications.onePartiallyUpdated":
      "Een of meer inplanningen zijn gedeeltelijk bijgewerkt, kijk bij de inplanningen",
    "Shift.updateJobApplications.selectedSharedJobApp.withMe": `Het toevoegen van een bijzonderheidsstatus is niet mogelijk. {jobApplicationsSharedWithMe, plural,
      one { # inplanning }
      other { # inplanningen }
    } is/zijn gedeeld. Om de inplanningen aan te passen dien je alleen eigen diensten te selecteren.`,
    "Shift.assignMaterials":"Materialen toewijzen",
    "Shift.assignMaterials.selectMaterials": "Selecteer materialen",
    "Shift.assignMaterials.givenMaterials": "Toegewezen materialen",
    "Shift.assignMaterials.materialName": "Materiaal naam",
    "Shift.assignMaterials.remark": "Opmerking",
    "Shift.assignMaterials.404.primary": "Je hebt momenteel nog geen materialen toegewezen aan de medewerker.",
    "Shift.assignMaterials.404.secondary": "Selecteer links materialen om toe te wijzen.",
    "Shift.assignMaterials.footer.giveMaterials": "Sluit en bevestig materialen",
    "Shift.assignMaterials.footer.completeStep": "Voltooi de workflowstap",
    "Shift.assignMaterials.search": "Zoek materiaal of categorie",
    "Shift.assignMaterials.category": "Categorie",
    "Shift.gatherMaterials":"Materialen inleveren",
    "Shift.gatherMaterials.handed":"Ingeleverd",
    "Shift.gatherMaterials.notHanded":"Niet ingeleverd",
    "Shift.gatherMaterials.complete": "Bevestig en voltooi workflowstap",
    "Shift.gatherMaterial.returned": "Alle artikelen geretourneerd",
    "Shift.gatherMaterials.404":"Kruis de bovenstaande items af wanneer ze niet zijn ingeleverd",
    "Shift.assignMaterials.footer.handed.confirm": "Bevestig ingeleverde materialen ",
    "Shift.managerSignOff": "Handtekening", 
    "Shift.managerSignOff.footer.handed.confirm": "Bevestig handtekening",
    "CreateCategory.categoryCreated":
      "De nieuwe project categorie is aangemaakt",
    "CreateCategory.newCategory": "Nieuwe categorie",
    "CreateCategory.notCreated": "De categorie kon niet worden aangemaakt",
    "CreateCategory.createCategory": "Nieuwe categorie",
    "CreateCategory.inputName": "Vul een naam in",
    "CreateCategory.name": "Naam",
    "CreateCategory.pleaseSelectType": "Selecteer een soort",
    "CreateCategory.selectType": "Selecteer een soort",
    "CreateCategory.regular": "Functies",
    "CreateCategory.trainings": "Training",
    "CreateCategory.qualifications": "Kwalificaties",
    "CreateCategory.addDescription": "Voeg een beschrijving toe",
    "CreateCategory.description": "Beschrijving",
    "CreateCategory.categoryType": "Categorie type",
    "CreateCategory.salesPrice": "Verkoopprijs",
    "CreateCategory.categoryName": "Naam",
    "CreateCategory.salaryProfile": "Loonprofielen",
    "CreateCategory.selectSalaryProfile": "Verplichte einddatum",
    "CreateCategory.mandatoryExpirationDate":
      "De categorie moet inclusief einddatum ingevoerd worden.",
    "CreateCategory.idCategory": "Bij het toevoegen aan een medewerker moet de categorie een ID bevatten",
    "CreateCategory.optionalHourlyRate": "Ik wil optioneel een uurtarief toevoegen bij toevoeging aan een medewerker",
    "CreateCategory.expirationDateTitle":"Tot waneer is deze bevoegdheid geldig?",
    "CreateCategory.title": "Voeg categoriespecificaties toe",
    "CreateCategory.idMandatory": "ID-nummer toevoegen",
    "CreateCategory.hourlyRates": "Uitzondering uurtarief (optioneel)",
    "CreateCategory.idMandatory.placeholder": "ID-nummer",
    "CreateCategory.updateCategory": "Wijzig categorie",
    "CreateCategory.categoryUpdated": "De wijziging is opgeslagen",
    "CreateCategory.deleteCategory": "Verwijder functie",
    "CreateCategory.categoryDeleted": "De functie is succesvol verwijderd",
    "CreateCategory.notDeleted": "De functie kon niet worden verwijderd",
    "CreateCategory.notUpdated": "De wijziging kon niet worden opgeslagen",
    "CreateCategory.id": "ID categorie",
    "CreateContract.createContract": "Maak een contract aan",
    "CreateContract.contractName": "Contract naam",
    "CreateContract.inputName": "Contract naam is verplicht",
    "CreateContract.contractType": "Contract type",
    "CreateContract.inputType": "Contract type is verplicht",
    "CreateContract.zzp": "ZZP",
    "CreateContract.ma": "MA",
    "CreateContract.sis": "SIS",
    "CreateContract.ghost": "GHOST",
    "CreateContract.paymentType": "Betaalmethode",
    "CreateContract.inputPayment": "Betaalmethode is verplicht",
    "CreateContract.amount": "Bedrag",
    "CreateContract.amountIn": "Bedrag in euro's",
    "CreateContract.inputAmount": "Bedrag is verplicht",
    "CreateContract.fixedRate": "Vast bedrag",
    "CreateContract.hourlyRate": "Vast uurloon",
    "CreateContract.ageRate": "Op leeftijd",
    "CreateContract.createdContract": "Nieuw contract aangemaakt",
    "CreateContract.updateContract": "Contract wijziging opgeslagen",
    "CreateContract.error":
      "Het was niet mogelijk om de contractinformatie op te slaan",
    "CreateContract.maxWeekHours": "Max aantal weekuren",
    "CreateDashboardUser.createDashboardUser": "Dashboard gebruiker aanmaken",
    "CreateDashboardUser.email": "E-mail",
    "CreateDashboardUser.inputEmail": "Vul een e-mailadres is",
    "CreateDashboardUser.username": "Gebruikersnaam",
    "CreateDashboardUser.inputUsername": "Vul een gebruikersnaam in",
    "CreateDashboardUser.password": "Wachtwoord",
    "CreateDashboardUser.inputPassword": "Vul een wachtwoord in",
    "CreateDashboardUser.inputPasswordLength":
      "Het wachtwoord moet minimaal 8 karakters bevatten",
    "CreateDashboardUser.firstName": "Voornaam",
    "CreateDashboardUser.inputFirstName": "Vul een voornaam in",
    "CreateDashboardUser.prefix": "Tussenvoegsel",
    "CreateDashboardUser.inputPrefix": "Vul een tussenvoegsel in",
    "CreateDashboardUser.lastName": "Achternaam",
    "CreateDashboardUser.inputLastName": "Vul een achternaam in",
    "CreateDashboardUser.phoneNumber": "Telefoonnummer",
    "CreateDashboardUser.invalidEmail": "Dit is geen geldig e-mail adres",
    "CreateDashboardUser.invalidNumber": "Dit is geen geldig nummer",
    "CreateDashboardUser.newDashboardUser": "Nieuwe dashboard gebruiker",
    "CreateDashboardUser.created": "De dashboard gebruiker is aangemaakt",
    "CreateDashboardUser.notCreated": `{errorCode, select,
        sr_user_11 {Er bestaat al een actief account met dit emailadres.}
        sr_user_10 {Gebruikersnaam bestaat al.}
        other {De dashboard gebruiker is niet aangemaakt}
      }`,
    "CreateDashboardUser.minLength":
      "De gebruikersnaam moet minstens 4 karakters bevatten",
    "CreateWorkflowTemplate.createWorkflowTemplate":
      "Selecteer workflow elementen",
    "CreateWorkflowTemplate.description":
      "In de workflow kun je aangeven welke onderdelen de medewerker zal uitvoeren in verband met de dienst. Maak hier je standaard workflows aan, deze kunnen tijdens het aanmaken van de dienst geselecteerd worden.",
    "CreateWorkflowTemplate.name": "Workflow naam",
    "CreateWorkflowTemplate.job_applied": "Inschrijven",
    "CreateWorkflowTemplate.job_accepted": "Ingepland",
    "CreateWorkflowTemplate.quiz_complete": "Quiz",
    "CreateWorkflowTemplate.quiz_completeDescription":
      "Met een quiz heb je de mogelijkheid om specifieke vragen te stellen aan de medewerker, deze dienen zij correct af te ronden om verder te kunnen in de workflow",
    "CreateWorkflowTemplate.item_distribution": "Materiaal uitgifte", 
    "CreateWorkflowTemplate.item_distributionDescription": "Hou bij welke materialen er zijn uitgegeven aan de medewerker tijdens de werkdag. Alle materialen die zijn toegevoegd onder Settings > Admin, worden meegenomen.", 
    "CreateWorkflowTemplate.item_gathering": "Materiaal verzamelen", 
    "CreateWorkflowTemplate.item_gatheringDescription": "Houd bij welke materialen aan de medewerker zijn verstrekt en zijn terug gegeven aan het eind van de werkdag. Alle materialen die zijn toegevoegd onder Instellingen > admin worden opgenomen.", 
    "CreateWorkflowTemplate.check_in": "Aanmelden",
    "CreateWorkflowTemplate.check_inDescription":
      "Laat de medewerker voorafgaande aan de dienst zichzelf aanmelden. Bepaal binnen welk tijdsbestek deze aanmelding moet plaatsvinden.",
    "CreateWorkflowTemplate.check_in_location": "Op locatie",
    "CreateWorkflowTemplate.check_in_locationDescription":
      "Laat de medewerker zichzelf inchecken wanneer hij/zij op locatie is.",
    "CreateWorkflowTemplate.check_in_qr": "Inchecken met QR-code",
    "CreateWorkflowTemplate.assignMaterials": "Materialen toewijzen",
    "CreateWorkflowTemplate.check_in_qrDescription":
      "Laat je medewerkers inchecken met een QR-code, de coördinator/manager op locatie moet de QR-code scannen (zie manager app)",
    "CreateWorkflowTemplate.upload_photo": "Foto's",
    "CreateWorkflowTemplate.upload_photoDescription":
      "Laat de medewerker foto's maken op locatie als bewijs van aanwezigheid.",
    "CreateWorkflowTemplate.evaluation": "Evaluatie",
    "CreateWorkflowTemplate.evaluationDescription":
      "Laat de medewerker een evaluatie schrijven na afloop van de dienst.",
    "CreateWorkflowTemplate.check_out": "Uitchecken",
    "CreateWorkflowTemplate.check_outDescription":
      "Laat je medewerkers uitchecken, Fleks registreert de tijd van het uitchecken",
    "CreateWorkflowTemplate.check_out_qr": "Uitchecken met QR code",
    "CreateWorkflowTemplate.check_out_qrDescription":
      "Laat je medewerkers uitchecken met een QR-code, de coördinator/manager op locatie moet de QR-code scannen (zie manager app)",
    "CreateWorkflowTemplate.hour_declaration": "Declareren",
    "CreateWorkflowTemplate.hour_declarationDescription":
      "Laat de medewerker een declaratie opsturen na afloop van de dienst.",
    "CreateWorkflowTemplate.add": "Workflow toevoegen",
    "CreateWorkflowTemplate.isReserved": "Is een reserve dienst",
    "CreateWorkflowTemplate.signing_off": "Digitale handtekening vanuit de manager app",
    "CreateWorkflowTemplate.signing_offDescription": "In de manager app kan een medewerker zijn handtekening zetten om uit te checken, geen discussie meer over de werktijden!",
    "CreateWorkflowTemplate.employee_signing_off":"Digitale handtekening medewerker app",
    "CreateWorkflowTemplate.employee_signing_offDescription": "In de medewerker app kan een leidinggevende op locatie een handtekening zetten en de gewerkte uren direct controleren.",
    "CreateWorkflowTemplate.message.invalidOrder":
      "Fout in de volgorde van de workflow",
    "CreateWorkflowTemplate.message.invalidOrder.description":
      "Uitchecken kan niet voor inchecken worden gezet",
    "CreateWorkflowTemplate.messageTooltipStep.completedAt":
      "Afgerond op: {completedAt}",
    "CreateWorkflowTemplate.messageTooltipStep.completedBy":
      "Afgerond door: {completedBy}",
    "CreateWorkflowTemplate.messageTooltipStep.completedByEmployee":
      "Afgerond door: Medewerker",
    "CreateWorkflowTemplate.messageTooltipStep.distance": "Afstand tot werklocatie : {distance}km",
    "CreateWorkflowTemplate.messageTooltipStep.locationButton": "Bekijk locatie",
    "AddPoolToShareJob.poolSpecification": "Poolspecificatie",
    "AddPoolToShareJob.description":
      "Je hebt <b>{job_number}</b> dienst(en) geaccepteerd. Wil je deze dienst(en) zichtbaar maken voor een specifieke groep of voor al jouw medewerkers?",
    "AddPoolToShareJob.selectYourPool": "Selecteer de pool",
    "AddPoolToShareJob.tip":
      "Tip: Om er zeker van te zijn dat de correcte medewerkers reageren op jouw dienst, voeg een poolspecificatie toe.",
    "AddPoolToShareJob.radio1":
      "Ik wil de dienst(en) zichtbaar maken voor een specifieke groep",
    "AddPoolToShareJob.radio1Info":
      "De pool specificatie kan altijd worden veranderd in de dienst detail pagina.",
    "AddPoolToShareJob.radio2":
      "Ik wil de dienst(en) zichtbaar maken voor al mijn medewerkers",
    "AddPoolToShareJob.save": "Sla mijn keus op",
    "AddPoolToShareJob.rememberCombination": "Combinatie onthouden",
    "AddPoolToShareJob.rememberCombinationWarning":
      "Alle dienst aanvragen van {tenant_owner} met de functies; {job_category} zullen in het vervolg automatisch geaccepteerd worden met de geselecteerde pool",
    "SaveCombinations.modal.title": "Dienst verificatie",
    "SaveCombinations.modal.description":
      "Controleer dat de informatie die gedeeld wordt in de marktplaats overeenkomt met jouw wensen.",
    "SaveCombinations.modal.employersName": "Naam opdrachtgever",
    "SaveCombinations.modal.checkbox1":
      "Altijd deze functie accepteren van deze werkgever",
    "SaveCombinations.modal.checkbox2":
      "Vraag de volgende keer of ik de dienst wil beoordelen",
    "SaveCombinations.save": "Sla mijn keus op",
    "ShareJobs.title": "Dienst aanvraag sturen",
    "ShareJobs.description":
      "Je hebt <b>{job_number}</b> diensten geselecteerd. Kies bij welke connecties je deze diensten wilt aanvragen.",
    "ShareJobs.searchPlaceholder": "Zoek een connectie",
    "ShareJobs.counterNotification":
      "Je hebt <b>{connections_number}</b> connecties geselecteerd.",
    "ShareJobs.sendRequests": "Stuur mijn aanvragen",
    "ShareJobs.alreadyShared": "Dienst is al gedeeld",
    "ShareJobs.updateJobCancellationDate": "Annuleringsdatum wijzigen",
    "ShareJobs.updateAllTheJobsCancellationDate":
      "Annuleringsdatum van alle diensten wijzigen",
    "ShareJobs.cancellationDate": "Annuleringsdatum",
    "ShareJobs.cancellationDate.optional": "Annuleringsdatum (Optioneel)",
    "ShareJobs.employeePropose.optional": "Aantal medewerkers (Optioneel)",
    "ShareJobs.employeePropose.error":
      "Het aantal aan te vragen medewerkers moet kleiner of gelijk zijn aan {jobRequestMax}",
    "ShareJobs.employeePropose.update": "Aanpassen",
    "ShareJobs.employeePropose.notificationSuccess": "De aanvraag is aangepast",
    "ShareJobs.cancellationDate.flexible":
      "Flexibel (tot 24u voor aanvang dienst)",
    "ShareJobs.cancellationDate.standard":
      "Standaard (tot 48u voor aanvang dienst)",
    "ShareJobs.cancellationDate.strict": "Streng (tot 72u voor aanvang dienst)",
    "ShareJobs.cancellationDate.superstrict":
      "Super streng (tot 168u voor aanvang dienst)",
    "ShareJobs.marketplace.title": `Jouw dienst op {tenant_name} plaatsen`,
    "ShareJobs.marketplace.description": `Om een dienst te kunnen plaatsen op {tenant_name} hebben wij de volgende informatie van jou nodig. LET OP: De bemiddelingskosten voor de freelancer staan tussen haakjes achter het uurloon`,
    "ShareJobs.marketplace.shareJobWith": "Publiceer op {tenant_name}",
    "ShareJobs.xJobsSharedFromY": "{x} van de {y} diensten zijn reeds gedeeld",
    "ShareJobs.publishOnX.description": "Publiceer je dienst op {tenant_name}",
    "ShareJobs.publishOnX.selectedSharedJob.disabled": `Publiceren is niet mogelijk {jobsSharedWithMarketplace, plural,
      one { # job }
      other { # jobs }
    } zijn reeds gepubliceerd op {tenant_name}`,
    "ProfileForm.editProfile": "Wijzig profiel",
    "ProfileForm.firstNameInput": "Voornaam is verplicht",
    "ProfileForm.lastNameInput": "Achternaam is verplicht",
    "ProfileForm.phoneNumberInput": "Telefoonnummer is verplicht",
    "ProfileForm.updated-success": "Profiel wijziging is opgeslagen",
    "ProfileForm.updated-error": "Profiel wijziging is niet opgeslagen",
    "ChangePassword.changePassword": "Wijzig wachtwoord",
    "ChangePassword.description":
      "Klik op de knop hieronder om een reset-code voor het wijzigen van je wachtwoord te ontvangen op: %e. Je kunt de reset code gebruiken voor het wijzigen van je wachtwoord.",
    "ChangePassword.sendCode": "Verstuur code",
    "ChangePassword.code": "Code",
    "ChangePassword.inputCode": "Vul de code in",
    "ChangePassword.password": "Wachtwoord",
    "ChangePassword.inputPassword": "Vul een wachtwoord in",
    "ChangePassword.confirmPassword": "Bevestig wachtwoord",
    "ChangePassword.inputConfirmPassword": "Bevestig je wachtwoord",
    "ChangePassword.codeSentSuccess": "De code is verstuurd, check je e-mail",
    "ChangePassword.codeSentError":
      "De code kon niet verstuurd worden, probeer opnieuw",
    "ChangePassword.changed": "Wachtwoord wijziging is opgeslagen",
    "ChangePassword.noChanged":
      "Er ging iets fout, klopt de code en heb je minimaal 8 karakters gebruikt?",
    "Employee.Overview.addJob": "Voeg dienst toe",
    "Employee.Overview.client": "Opdrachtgever",
    "Employee.Overview.schedule": "Inplannen",
    "Employee.Overview.jobsFor": "Openstaande diensten",
    "Employee.Overview.jobsFor.empty": "Geen diensten gevonden",
    "Employee.Overview.scheduleEmployee": "Inplannen",
    "Employee.Overview.scheduleSuccess":
      "Inplannen van de medewerker is gelukt",
    "Employee.Overview.partialSchedule":
      "Er zijn een aantal medewerkers niet ingepland",
    "Employee.Overview.acceptedProposal":
      "De voorgestelde kandidaat is succesvol ingepland",
    "Employee.Overview.rejectedProposal":
      "De voorgestelde kandidaat is succesvol afgewezen",
    "Overview.sick": "Ziek",
    "Overview.hadContact": "Contact gehad",
    "Overview.rejected": "Afgewezen",
    "Overview.noShow": "No show",
    "Overview.contacted": "Contact gehad",
    "Overview.wantToWork": "Is beschikbaar",
    "Overview.unavailability": "Niet beschikbaar",
    "Overview.availability": "Beschikbaarheid",
    "Overview.unavailability.text": "Is niet beschikbaar",
    "Overview.availability.error":
      "Het is niet gelukt de beschikbaarheid van [employee_name] aan te passen",
    "Overview.availability.success":
      "[employee_name] beschikbaarheid is aangepast",
    "Overview.availability.description": "Beschikbaarheid is aangepast",
    "Overview.availability.default": "heeft geen beschikbaarheid opgegeven",
    "Overview.availabilityRange": "[name] [availability] van [from] tot [to]",
    "Overview.available": "Onbekend",
    "Overview.status": "Status",
    "Overview.dashboardChange": "Beschikbaarheid is aangepast",
    "Overview.scheduledRange": "ingepland van [from] tot [to]",
    "Overview.updateAvailability": "Pas beschikbaarheid aan",
    "Overview.errorOccurred": "Er ging iets fout",
    "ScheduleButton.hasContract":
      "%n heeft een contract aanbod geldig tijdens %jsd maar deze is nog niet getekend.",
    "ScheduleButton.hasNoContract":
      "%n heeft geen contract geldig tijdens %jsd.",
    "ScheduleButton.unavailable": "%n staat op niet beschikbaar voor %jsd.",
    "ScheduleButton.hasAJob":
      "%n staat al ingepland voor %c, %a, van %jst tot %jet.",
    "ScheduleButton.hasMultiJobs":
      "%n staat al ingepland voor meerdere diensten op %jsd.",
    "ScheduleButton.areYouSure": "Weet je het zeker?",
    "ScheduleButton.Reschedule": "Omplannen",
    "ScheduleButton.Invite": "Uitnodigen",
    "ScheduleButton.Invite.disabled":
      "Een of meer van de geselecteerde diensten zijn al afgelopen, de medewerker kan hiervoor niet worden uitgenodigd",
    "ScheduleButton.errorSendInvitation":
      "Uitnodiging versturen is niet gelukt",
    "ScheduleButton.confirm": "Bevestig",
    "ScheduleButton.addExpireTime": "Vervaldatum",
    "ProposeButton.errorProposing": "Medewerker kon niet voorgesteld worden",
    "Archive.clients": "Opdrachtgevers",
    "Archive.projects": "Projecten",
    "Archive.jobs": "Diensten",
    "Archive.employees": "Medewerkers",
    "Archive.shifts": "Inplanningen",
    "Archive.applicants": "Sollicitanten",
    "Archive.hours": "Urenregistratie",
    "Archive.emptyState.title": "Je hebt nog niets gearchiveerd",
    "Archive.emptyState.description":
      "Klik op het archief icoontje om je bestanden te archiveren",
    "Archive.emptyState.description.1": "Klik op het",
    "Archive.emptyState.description.2": "om je bestanden te archiveren.",
    "Archive.selectedSharedJob.withMe": `Archiveren is niet mogelijk, je hebt {jobsSharedWithMe, plural,
      one {# dienst }
      other {# diensten }
    } geselecteerd die gedeeld met jou zijn`,
    "Archive.selectedSharedJob.byMe": `Archiveren is niet mogelijk, je hebt {jobsSharedByMe, plural,
      one {# dienst }
      other {# diensten }
    } geselecteerd die gedeeld zijn`,
    "Archive.selectedSharedJob.byMe.unique": `Het archiveren van de dienst is niet mogelijk, deze dienst is afkomstig van een connectie`,
    "Archive.selectedSharedJob.both": `Archiveren is niet mogelijk, je hebt {jobsSharedWithMe, plural,
      one {# dienst }
      other {# diensten }
    } geselecteerd die gedeeld zijn en {jobsSharedByMe, plural,
      one {# dienst }
      other {# diensten }
    } gedeeld`,
    "UpdateSpecifications.selectedSharedJob.withMe": `Het aanpassen van de gegevens is niet mogelijk {jobsSharedWithMe, plural,
      one { # job }
      other { # jobs }
    } je kunt alleen eigen diensten of alleen gedeelde diensten aanpassen`,
    "NotificationModal.sendMail": "Stuur een bericht",
    "NotificationModal.sendMailToEmployees": "Bericht inplanningen",
    "NotificationModal.emailsSent": "Bericht is verstuurd",
    "NotificationModal.sendNotification": "Stuur notificatie",
    "NotificationModal.notificationSent": "Notificatie is verzonden",
    "NotificationModal.emailsNotSent": "Het bericht is niet verzonden",
    "NotificationModal.subjectPlaceholder": "Voeg een onderwerp toe",
    "NotificationModal.subject": "Onderwerp",
    "NotificationModal.email": "Email",
    "NotificationModal.message": "Bericht",
    "NotificationModal.selectAll": "Selecteer alles",
    "NotificationModal.receivers": "Medewerkers",
    "NotificationModal.modalTitle": "Stuur bericht naar %si medewerkers%sj",
    "NotificationModal.about": "over",
    "NotificationModal.notification": "Push bericht in app",
    "NotificationModal.subjectLengthErrorDescription":
      "De titel van een push bericht mag niet langer zijn dan %si karaketers. Verklein het bericht om het toch als push notificatie te versturen of verstuur het enkel als email.",
    "NotificationModal.messageLengthErrorDescription":
      "De beschrijving van een push bericht mag niet langer zijn dan %si karakters. Verklein het bericht om het toch als push notificatie te versturen of verstuur het enkel als email.",
    "Documents.uploadText":
      "Klik hier of sleep een PDF in dit vak om te uploaden. Maximaal {max} MB",
    "Documents.error.tooBig": "'Het bestand is te groot'!",
    "Documents.uploadDescription":
      "Upload een relevant PDF document, er kunnen meerdere documenten tegelijk worden toegevoegd",
    "Documents.documentName": "Titel",
    "Documents.documentDescription": "Beschrijving",
    "Documents.documentOptions": "Acties",
    "Documents.uploadDocument": "Voeg een document toe",
    "Documents.success-upload": "Document is toegevoegd",
    "Documents.success-update": "Document is aangepast",
    "Documents.upload-error": "Het document is niet toegevoegd",
    "Documents.uploader": "Upload je document",
    "Documents.cancel": "Annuleer",
    "Documents.save": "Opslaan",
    "Documents.no-data": "Er zijn nog geen documenten toegevoegd",
    "GoogleFilePicker.defaultInfo":
      "Om de sheet goed uit te lezen moeten de eerste rij de Engelse kolomnamen bevatten en de datum in het volgende format zijn: dd-mm-jjjj",
    "GoogleFilePicker.textButton": "Importeer",
    "EmployeeRow.updateError": "Uitnodiging updaten",
    "EmployeeRow.defaultError": "Er ging iets, probeer het opnieuw",
    "ScheduleModal.JobItem.notHaveJob": "is beschikbaar voor de dienst",
    "ScheduleModal.JobItem.haveJob": "is al ingepland voor een dienst",
    "ScheduleModal.JobItem.willNotHasContract":
      "heeft geen contract tijdens de dienst",
    "ScheduleModal.JobItem.hasContractNoSigned":
      "heeft een contractvoorstel maar is nog niet getekend",
    "ScheduleModal.filters": "Filters",
    "ScheduleModal.empty":
      "Er zijn geen medewerkers gevonden met de toegepaste pool",
    "MainView.selected": "geselecteerd",
    "EditPools.hasACar": "Heeft een auto",
    "EditPools.hasDrivingLicense": "Heeft een rijbewijs",
    "EditPools.name": "Naam",
    "EditPools.name.placeholder": "Pool naam",
    "EditPools.branch.placeholder": "Selecteer functies",
    "EditPools.trainings.placeholder": "Selecteer trainingen",
    "EditPools.qualifications.placeholder": "Selecteer kwalificaties",
    "EditPools.distance": "Afstand (Km)",
    "EditPools.distance.placeholder": "Afstand",
    "EditPools.distance.toggle": "Maximale rijafstand",
    "EditPools.employee": "Medewerkers (#)",
    "EditPools.filters": "Filters",
    "EditPools.others": "Overig",
    "EditPools.created": "Pool is aangemaakt",
    "EditPools.updated": "Pool is aangepast",
    "EditPools.basic": "Algemeen",
    "EditPools.categories": "Categorieën",
    "EditPools.whitelist": "Uitzonderingen",
    "EditPools.delete.confirm":
      "Weet je zeker dat je de medewerker van de pool wilt verwijderen?",
    "EditPools.error.nameAlreadyExists": "De naam is al in gebruik",
    "EditFunctions.function": "Functie",
    "EditFunction.created": "Functie is aangemaakt",
    "EditFunction.updated": "Functie is aangepast",
    "EditFunctions.basic": "Algemeen",
    "EditFunctions.name": "Naam",
    "EditFunctions.name.placeholder": "Functienaam",
    "EditFunctions.description": "Beschrijving",
    "EditFunctions.description.placeholder": "Omschrijving",
    "EditFunctions.sharing": "Delen met connecties",
    "EditFunctions.isShareable": "Deel met mijn connecties",
    "GlobalSearch.employees.noFound": "Geen medewerker gevonden",
    "GlobalSearch.search": "Zoek medewerkers",
    "Availability.na": "-",
    "Availability.setForAJob":
      "Wat is de nieuwe beschikbaarheid van <strong className='avenir-next-demi-bold fz-12'>{name}</strong> op de dag <strong className='avenir-next-demi-bold fz-12'>{date}</strong>?",
    "ExpirationCountdown.timeLeft": "Vervalt over",
    "Invitation.expiredMessage": "Verlopen",
    "Invitation.alreadyInvited":
      "{fullName} - al uitgenodigd voor deze functie",
    "StandardProject.basicData": "Project info",
    "StandardProject.wrongLoad": "Er is geen projectsjabloon",
    "StandardProject.standardJobs": "Standaard diensten",
    "StandardProject.noStandardJobs": "Geen standaard diensten gevonden",
    "OnboardingInvitation.RegistrationForm.title": "Stel jezelf voor",
    "OnboardingInvitation.RegistrationForm.fillInformation":
      "Controleer of vul de ontbrekende informatie in.",
    "OnboardingInvitation.RegistrationForm.requiredFieldsNotice":
      "De invulvelden met een * zijn verplichte velden",
    "OnboardingInvitation.RegistrationForm.personal": "Persoonlijk",
    "OnboardingInvitation.RegistrationForm.contact": "Contact",
    "OnboardingInvitation.RegistrationForm.credentials": "Inloggegevens",
    "OnboardingInvitation.RegistrationForm.address": "Adres",
    "OnboardingInvitation.ThanksPage.title": "Bedankt, [employee_name]",
    "OnboardingInvitation.ThanksPage.notice":
      "We zijn blij dat jij je profiel compleet hebt gemaakt!",
    "OnboardingInvitation.ThanksPage.whatsNext": "Wat nu?!",
    "OnboardingInvitation.ThanksPage.downloadApp":
      "Download meteen de app in de app of playstore",
    "OnboardingInvitation.ThanksPage.descriptionApp":
      "Bekijk openstaande diensten en vul je beschikbaarheid in op de app. <br /> Als er nog geen openstaande diensten klaarstaan, kun je ook alvast onze huisregels bekijken op de 'over ons' pagina.",
    "OnboardingInvitation.loginError": "Whoops.",
    "OnboardingInvitation.invalidLink":
      "Je hebt geen toegang tot deze content, gebruik de link die is gegeven in de email.",
    "OnboardingEmail.sentTo": "[number_of_emails] email(s) zijn verstuurd",
    "OnboardingEmail.emailAlreadyTaken": "Dit emailadres is al in gebruik",
    "Filters.apply": "Pas filters toe",
    "Filters.advancedApply": "Pas filters toe ({filters_number})",
    "Filters.allFilters": "Alle filters",
    "Filters.clear": "Wis al mijn filters",
    "Filters.removeAllFilters": "Verwijder filters",
    "Filters.more": "Meer filters",
    "Filters.refresh": "Vernieuwen",
    "Comments.new": "Plaats een opmerking",
    "Comments.new.minLength": "Te korte opmerking!",
    "Comments.new.added": "Opmerking succesvol toegevoegd",
    "Comments.new.added.error": "De opmerking is niet toegevoegd",
    "Comments.new.add": "Opmerking toevoegen",
    "SalaryRun.invoiceRun": "Facturatierun",
    "SalaryRun.title": "Salaris export",
    "SalaryRun.periodAndSpecifications": "Periode en specificaties",
    "SalaryRun.declarations": "Urenregistratie",
    "SalaryRun.selectRateCard":
      "Selecteer de toeslagkaart die moet worden toegepast (optioneel)",
    "SalaryRun.select": "Selecteer",
    "SalaryRun.exportOptions": "Export opties",
    "SalaryRun.sentTo.accepted": "Stuur naar geaccepteerd",
    "SalaryRun.sentTo.processed": "Stuur naar verwerkt",
    "SalaryRun.sentTo.invoiced": "Markeer als gefactureerd",
    "SalaryRun.sentTo.back": "Ga terug naar de tabel",
    "SalaryRun.createReports": "Maak rapportages",
    "PeriodAndSpecifications.periodExplain":
      "Selecteer de periode van de salaris run",
    "PeriodAndSpecifications.specificationsExplain":
      "Selecteer wat je wilt exporteren. Wanneer je geen wijzigingen doorvoert, zal alles geëxporteerd worden.",
    "PeriodAndSpecifications.specifyTheContractTypes": "Filter op contracttype",
    "PeriodAndSpecifications.specifyClients": "Filter op opdrachtgever",
    "PeriodAndSpecifications.specifyTheProjects": "Filter op project",
    "PeriodAndSpecifications.specifyTheBranches": "Functies",
    "PeriodAndSpecifications.specifyTheQualifications": "Kwalificaties",
    "PeriodAndSpecifications.specifyTheTrainings": "Trainingen",
    "PeriodAndSpecifications.specifyTheConnections": "Selecteer connecties",
    "Declarations.summaryExplain": "Samenvatting van de salarisrun.",
    "Declarations.foundDeclarations": "Aantal registraties",
    "Declarations.corrections": "Correcties",
    "Declarations.noDeclarations": "Workflow niet afgerond",
    "Declarations.toExportOptions": "Naar export opties",
    "Declarations.watchOutExplain":
      "Wij hebben [number_of_shifts] inplanningen in de geselecteerde periode gevonden. Ga naar inplanningen om deze handmatig door te zetten",
    "Declarations.goShifts": "Ga naar inplanningen",
    "Declarations.notCorrections":
      "Er zijn geen geaccepteerde registraties voor de geselecteerde periode gevonden",
    "Declarations.includeCorrections":
      "Correcties: geef hier aan of je registraties vóór de geselecteerde periode in de salarisrun wilt meenemen. Dit zijn registraties voor de geselecteerde periode: [start_date] & [end_date]",
    "ExportOptions.declarations": "Urenregistratie",
    "ExportOptions.exportExplain": "Selecteer wat je wilt exporteren",
    "ExportOptions.declarations.explain":
      "Exporteer alle urenregistraties in de geselecteerde periode",
    "ExportOptions.totals": "Totalen",
    "ExportOptions.totals.explain":
      "Exporteer de totalen van de geselecteerde periode",
    "ExportOptions.newEmployees": "Nieuwe medewerkers",
    "ExportOptions.newEmployees.explain":
      "Exporteer de informatie van de medewerkers die hun eerste werkdag hebben gehad in de periode",
    "ExportOptions.wageTaxRebate": "Loonheffingskorting",
    "ExportOptions.wageTaxRebate.explain":
      "Exporteer de informatie van de medewerkers die een loonheffingskorting wijziging hebben aangevraagd",
    "ExportOptions.receiptImages": "Download bonnetjes",
    "ExportOptions.receiptImages.2": "Download bonnen",
    "ExportOptions.receiptImages.explain":
      "Download alle bonnen van de geselecteerde periode in een zip bestand",
    "ExportOptions.allDeclarations": "Toeslagen export",
    "ExportOptions.allDeclarations.explain":
      "Zowel uw intern als externe medewerkers zullen in de export meekomen",
    "ExportOptions.allDeclarations.noRateCard":
      "U moet eerst een toeslagkaart selecteren",
    "ExportOptions.allDeclarations.deselectRateCard":
      "De export is niet mogelijk, selecteer eerst een toeslagkaart",
    "SentToAccepted.periodExplain":
      "Bijna klaar! Klik op onderstaande knop om de geselecteerde uren naar geaccepteerd te sturen en sluit het proces",
    "SentToAccepted.continueAndClose": "Accepteer de uren en sluit",
    "SentToAccepted.successNotification":
      "Er zijn ([number_of_accepted]) urenregistraties naar geaccepteerd gezet",
    "SentToAccepted.successNotificationHourOwner":
      "Je bent niet de eigenaar van sommige uren die je hebt geëxporteerd. Alleen de uren waar je eigenaar van bent zijn geaccepteerd. ([number_of_accepted]) inplanningen zijn naar geaccepteerd gezet.",
    "SentToAccepted.errorNotification":
      "Er is iets misgegaan bij het versturen van de uren naar geaccepteerd",
    "SentToProcessed.periodExplain":
      "Bijna klaar! Zijn alle exports gelukt? Klik op de onderstaande knop om alle urenregistraties naar verwerkt te sturen en het proces af te ronden",
    "SentToProcessed.continueAndClose":
      "Stuur naar verwerkt en sluit het proces",
    "SentToProcessed.successNotification":
      "Er zijn ([number_of_processed]) urenregistraties naar verwerkt verstuurd",
    "SentToProcessed.errorNotification":
      "Er is een probleem opgetreden bij het versturen van de urenregistraties naar verwerkt",
    "SentToInvoiced.periodExplain": `Bijna klaar! Klik op onderstaande knop om de geselecteerde uren naar "Gefactureerd" te sturen.`,
    "SentToInvoiced.continueAndClose": "Markeer als gefactureerd en sluit",
    "SentToInvoiced.successNotification":
      "([number_of_invoiced]) zijn gemarkeerd als gefactureerd",
    "SentToInvoiced.errorNotification":
      "Er is een fout opgetreden bij het bij het markeren naar gefactureerd",
    "HoursDataTooltip.invoicedAt": "Gefactureerd op",
    "HoursDataTooltip.invoicedBy": "Gefactureerd door",
    "HoursDataTooltip.approvedAt": "Geaccepteerd op",
    "HoursDataTooltip.approvedBy": "Geaccepteerd door",
    "HoursDataTooltip.approvedByLine": "[user_email] op [approved_date]",
    "HoursDataTooltip.exportedAt": "Verwerkt op",
    "HoursDataTooltip.exportedBy": "Verwerkt door",
    "HoursDataTooltip.processedBy": "Verwerkt door",
    "HoursDataTooltip.processedAt": "Verwerkt op",
    "HoursDataTooltip.exportedByLine": "[user_email] op [exported_date]",
    "HoursDataTooltip.approvedAtMin": "Geaccepteerd op (Min)",
    "HoursDataTooltip.exportedAtMin": "Verwerkt op (Min)",
    "HoursDataTooltip.approvedAtMax": "Geaccepteerd op (Max)",
    "HoursDataTooltip.exportedAtMax": "Verwerkt op (Max)",
    "HoursDataDetailsModal.firstParagraph.notShared": `Check & keur de uren van je medewerkers goed in de "intern per inplanning tabel".`,
    "HoursDataDetailsModal.firstParagraph.shared": `Deze dienst is van <b>{job_owner_tenant_name}</b> en is ingevuld door een medewerker van <b>{employee_owner_tenant_name}</b>, jullie kunnen nu beide de gewerkte uren aanpassen totdat deze bij verwerkt zijn gezet door <b>{employee_owner_tenant_name}</b>.
    </br><b>{employee_owner_tenant_name}</b> kan de <b>{job_owner_tenant_name}</b> laten weten dat de uren zijn gecontroleerd door de uren goed te keuren in de tabel "intern per inplanning".`,
    "HoursDataDetailsModal.secondParagraph.notShared":
      "Wanneer de salarisperiode is verstreken, dien je de uren van geaccepteerd naar verwerkt te zetten.",
    "HoursDataDetailsModal.secondParagraph.shared": `Wanneer de salarisperiode is verstreken en <b>{employee_owner_tenant_name}</b> ook met Fleks werkt, worden de uren op verwerkt gezet.`,
    "HoursDataDetailsModal.thirdParagraph.notShared": `Wanneer je organisatie werkt met verschillende periodes voor facturatie, kun je vanuit de "alle uren" tabel de facturatierun starten.`,
    "HoursDataDetailsModal.thirdParagraph.shared": `Bespreek met uw connectie voor wanneer de uren in Fleks gecontroleerd en gecorrigeerd moeten worden. <b>{job_owner_tenant_name}</b> kan de uren markeren als gefactureerd.`,
    "PlannerEmployeeCard.title": "Medewerker details",
    "PlannerEmployeeCard.note.NoResult": "Geen inplanning binnen 2 dagen",
    "PlannerEmployeeCard.note.Less": "Pas op! Let op de rusttijd",
    "PlannerEmployeeCard.note.More": "Geen overschrijdende diensten",
    "PlannerEmployeeCard.note.Overlapping": "Let op! Er is al een dienst gepland op dit tijdstip",
    "PlannerEmployeeCard.restTime.Equal": "De medewerker staat al ingepland op", //
    "PlannerEmployeeCard.restTime.SameTimeTitle": " dezelfde tijd",
    "PlannerEmployeeCard.tooltipText": "De rustperiode van 11 uur mag eens in de 7 dagen ingekort worden tot minimaal 8 uur. Fleks controleert 2 dagen voor en na de dienst of de rusttijd overschreden wordt.",
    "PlannerEmployeeCard.restTimeBefore": " rusttijd tussen de eerder geplande dienst",//
    "PlannerEmployeeCard.restTimeAfter": " rusttijd voor de volgende geplande dienst(en)",//
    "PlannerEmployeeCard.hoursTitle": " uur",
    "PlannerEmployeeCard.minutesTitle": " minuten",
    "PlannerEmployeeCard.restTimeOverlappingBefore": " overlappend met de volgende geplande dienst(en)",
    "PlannerEmployeeCard.restTimeOverlappingAfter": " overlappend met de volgende geplande dienst(en)",
    "PlannerEmployeeCard.weeklyHours": "Gewerkte uren / weekuren",
    "PlannerEmployeeCard.experience": "Ervaring bij opdrachtgever",
    "PlannerEmployeeCard.projectExperience": "Ervaring bij project",
    "PlannerEmployeeCard.clientExperience": "Ervaring bij de opdrachtgever",
    "PlannerEmployeeCard.proposedAt": "Voorgesteld op",
    "PlannerEmployeeCard.categories": "Functies",
    "PlannerEmployeeCard.manyHoursWorkedThisWeek":
      "Letop! De geselecteerde medewerker zit over zijn/haar contracturen",
    "PlannerEmployeeCard.employeeWasInvited":
      "Let op, de medewerker is uitgenodigd voor een andere dienst",
    "PlannerEmployeeCard.employeeWasScheduled":
      "Let op! De medewerker staat ingepland voor een andere dienst",
    "PlannerEmployeeCard.cancelled_by": "Geannuleerd door",
    "PlannerEmployeeCard.created_by": `<strong>Aangemaakt door: </strong>Medewerker`,
    "PlannerEmployeeCard.registered_by": "Ingepland door",
    "PlannerEmployeeCard.expirationDateValidUntil": "Geldig tot",
    "AvailabilityStatus.available": "Beschikbaar",
    "AvailabilityStatus.notAvailable": "Niet Beschikbaar",
    "AvailabilityStatus.partlyAvailable": "Deels beschikbaar",
    "AvailabilityStatus.unknown": "Beschikbaarheid onbekend",
    "AvailabilityStatus.invited": "Uitgenodigd",
    "AvailabilityStatus.registered": "Ingeschreven",
    "AvailabilityStatus.scheduled": "Ingepland",
    "AvailabilityStatus.reserved": "Gereserveerd",
    "PlannerTenantCard.title": "Connectie details",
    "ClusteredJob.pendingSpot": `{spots, number} openstaand`,
    "ClusteredJob.registered": `{spots, plural,
      =0 {inschrijvingen}
      one { # inschrijving }
      other { # inschrijvingen }
    }`,
    "ClusteredJob.invited": `{spots, plural,
      =0 {uit te nodigen }
      one { # uit te nodigen }
      other { # uit te nodigen }
    }`,
    "Planning.numberOfAvailableSpots": "Openstaande posities",
    "Planning.title": "Snel plannen",
    "Planning.cluster": "Cluster",
    "Connections.newConnection.title": "Welkom bij Fleks,",
    "Connections.newConnection.description":
      "{tenantName} wil diensten met je uitwisselen via Fleks! Als jij het connectieverzoek accepteert, kunnen jullie diensten met elkaar delen en kandidaten voorstellen. Help elkaar door de lege gaten in de planning te vullen.",
    "Connections.newConnection.paragraph1": `<b>Hoe werkt diensten delen?</b><br/><br/>
    Zodra je het connectieverzoek hebt geaccepteerd, staan jullie in elkaars Fleks omgeving als connectie. Jouw relatie kan vanaf de planner jouw bedrijf selecteren en een 'dienst aanvraag' versturen. Jij ontvangt een melding van deze aanvraag en kunt deze vervolgens accepteren of weigeren. <br />
    Als jij de dienst aanvraag accepteert, verschijnt de dienst in jouw planner overzicht. De diensten die extern afkomstig zijn, zijn te herkennen aan het pijltje in het dienstkaartje dat naar binnen toewijst. Klik op het desbetreffende dienstkaartje en stel een geschikte kandidaat voor aan jouw connectie.<br /><br />
    Het is van belang dat er vooraf naar de kandidaat is geïnformeerd dat de gegevens gedeeld kunnen worden met de partij waarmee een connectie wordt gemaakt. Dit kan door middel van een directe communicatie of door vermelding in het arbeidscontract. De verantwoording van deze communicatie ligt bij de werkgever en Fleks faciliteert enkel het planproces met zijn software.`,
    "Connections.newConnection.paragraph2": `<b>Snel opstarten!</b><br/><br/>Je kunt een aantal voorbereidingen treffen waarmee je het proces nog soepeler laat verlopen. Volg de volgende tips:<br/><br/>
      <ul>
        <li>Zet je kwalificaties in het systeem</li>
        <li>Maak een aantal pools aan</li>
        <li>Zet jouw medewerkers in het systeem met een compleet profiel</li>
      </ul>

      Je bent nu klaar om kandidaten voor te stellen!`,
    "Connections.newConnection.paragraph3": `<b>Fleks gebruiken jouw eigen planning?</b><br/><br/>
    Mocht het plannen met Fleks je goed bevallen, dan is het ook mogelijk om Fleks te gebruiken om jouw eigen diensten te vullen. Neem contact met ons op voor meer informatie of een gratis demo: <a href="www.fleks.nl" target="_blank">www.fleks.nl</a>`,
    "Connections.acceptedConnection.title": "Beste connectie,",
    "Connections.acceptedConnection.description": `Leuk! Je hebt het connectieverzoek van jouw relatie geaccepteerd!`,
    "Connections.acceptedConnection.paragraph1": `De connectie met <b>{tenantName}</b> is nu bevestigd en jullie kunnen starten om diensten met elkaar te delen.`,
    "Connections.acceptedConnection.paragraph2": `<b>Vragen over het systeem?</b><br/><br/>
      Als je nog niet of niet voldoende bekend bent met Fleks, dan helpen wij jou graag met de eerste kennismaking en stappen. Vraag in dit geval een web-demo aan via: <a href="mailto:support@fleks.info">support@fleks.info</a>. Of bel ons op het volgende telefoonnummer: 020 244 1535.`,
    "Connections.acceptedConnection.paragraph3": `Deel, stel voor en vul snel jouw diensten met Fleks!`,
    "Connections.acceptedConnection.paragraph4": `Veel succes en een goede samenwerking toegewenst.`,
    "Connections.GoToFleks": "Ga naar Fleks",
    "Connections.rejectedConnection.title": "Wat jammer!",
    "Connections.rejectedConnection.description": `Je hebt het connectieverzoek van {tenantName} afgewezen.`,
    "Connections.rejectedConnection.paragraph1": `<b>Nog niet overtuigd?</b><br/><br/>
    Neem contact op met jouw relatie om de mogelijkheden tot samenwerking te bespreken. {tenantName} wil namelijk graag met jou samenwerken en de lege gaten vullen in de planning.`,
    "Connections.rejectedConnection.paragraph2": `<b>Vragen of het systeem?</b><br/><br/>
    Als je nog niet of niet voldoende bekend bent met Fleks, dan helpen wij jou graag met de eerste kennismaking en stappen. Vraag in dit geval een web-demo aan via: <a href="mailto:support@fleks.info">support@fleks.info</a>. Of bel ons op het volgende telefoonnummer: 020 244 1535.`,
    "Connections.rejectedConnection.paragraph3": `Wij hopen van jou te horen!`,
    "PlanningModal.numberOfEmployeesSelected": `{numberOfEmployees, plural,
      0= { Geen medewerkers geselecteerd }
      one { # medewerker geselecteerd }
      other { # medewerkers geselecteerd }
    }`,
    "PlanningModal.thereAreMoreEmployeesThanOpenSpots":
      "Je hebt meer medewerkers geselecteerd ({numberOfEmployees}) dan de benodigde posities",
    "PlanningModal.maximumEmployeesProposed":
      "U heeft voor deze dienst al het maximale aantal medewerkers voorgesteld",
    "PlanningModal.clustersEnabled": "Activeer clusterweergave",
    "Scheduling.moreEmployeesThanOpenSpots":
      "Het is niet mogelijk om meer medewerkers in te plannen dan het aantal benodigde posities",
    "Scheduling.sentInvitations": "Uitnodigingen",
    "SharedJobs.share": "Dienst aanvraag sturen",
    "SharedJobs.cancel": "Aanvraag intrekken",
    "SharedJobs.status.Pending": "In afwachting",
    "SharedJobs.status.Accepted": "Geaccepteerd",
    "SharedJobs.status.Rejected": "Afgewezen",
    "SharedJobs.status.notShared": "Niet aangevraagd",
    "SharedJobs.notification.shared": `{sharedJobs, plural, 
     one { # dienst is }
     other { # diensten zijn }
    } aangevraagd`,
    "DashboardAvailabilityTable.numberOfEmployees": `{employees, plural, 
      =0 { geen medewerkers }
      one { # medewerker }
      other { # medewerkers }
    }`,
    "DashboardAvailabilityTable.hours": `{hours, plural, 
      =0 { geen uren }
      one { # uur }
      other { # uren }
    }`,
    "DashboardAvailabilityTable.hours.title": "Aantal uur",
    "DashboardAvailabilityTable.description": "Beschikbare medewerkers per dag",
    "DashboardAvailabilityTable.period": "Periode",
    "DashboardAvailabilityTable.title": "Beschikbaarheid",
    "DashboardAvailabilityTable.availabilityType": "Type",
    "ExtensiveSearch.title": "Uitgebreid zoeken",
    "ExtensiveSearch.isQualified": "Gekwalificeerd",
    "ExtensiveSearch.name": "Naam",
    "ExtensiveSearch.sectors": "Functies",
    "ExtensiveSearch.qualifications": "Kwalificaties",
    "ExtensiveSearch.trainings": "Training",
    "Reports.unsubscribe.title": "Schrijf je uit voor de rapporten",
    "Reports.unsubscribe.success": "Je hebt je succesvol afgemeld",
    "Reports.unsubscribe.whichOne": "Welke rapporten wilt u uitschakelen?",
    "Reports.weeklyReport": "Wekelijks verslag",
    "Reports.dailyReport": "Dagelijks rapport",
    "NewFreelancers.title": "Nieuwe freelancers",
    "NewFreelancers.emptyState.title": "Geen nieuwe freelancers gevonden",
    "NewFreelancers.emptyState.description":
      "Vernieuw de pagina en probeer het opnieuw.",
    "NewFreelancers.first_name": "Naam",
    "NewFreelancers.last_name": "Achternaam",
    "NewFreelancers.username": "Gebruikersnaam",
    "NewFreelancers.email": "Email",
    "NewFreelancers.phoneNumber": "Telefoonnummer",
    "NewFreelancers.registrationDate": "Registratie datum",
    "NewFreelancers.contactTime": "Contactmoment",
    "NewFreelancers.all": "Alle",
    "NewFreelancers.verified": "{items} freelancers geverifieerd",
    "NewFreelancers.verify.confirmation": `Je staat op het punt om {items, plural,
      one {# freelancer}
      other {# freelancers}    
    } te verifiëren, weet je zeker dat je deze actie wilt doorvoeren?`,
    "Freelancers.title": "Freelancers",
    "Freelancers.emptyState.title": "Geen freelancers gevonden",
    "Freelancers.emptyState.description":
      "Vernieuw de pagina en probeer het opnieuw.",
    "Freelancers.first_name": "Naam",
    "Freelancers.last_name": "Achternaam",
    "Freelancers.username": "Gebruikersnaam",
    "Freelancers.email": "Email",
    "Freelancers.phoneNumber": "Telefoonnummer",
    "Freelancers.registrationDate": "Registratie datum",
    "Freelancers.all": "Alle",
    "Freelancers.about": "Over de freelancer",
    "Freelancers.typeOfDocument": "Type document",
    "Freelancers.documentNumber": "Document nummer",
    "Freelancers.expirationDate": "Vervaldatum",
    "Freelancers.frontsideDoc": "Voorkant",
    "Freelancers.backsideDoc": "Achterkant",
    "Freelancers.document": "Document",
    "Freelancers.businessData": "Zakelijke gegevens",
    "Freelancers.vat": "VAT",
    "Freelancers.kvk": "KvK",
    "Freelancers.workExperience": "Werkervaring",
    "Freelancers.declareValid": "Valide verklaren",
    "Freelancers.declareInvalid": "Niet valide verklaren",
    "Freelancers.accept.confirmation": `Je staat op het punt om {items, plural,
      one {# freelancer}
      other {# freelancers}    
    }, te accepteren, weet je zeker dat je de actie wilt doorvoeren?`,
    "Freelancers.cancel.confirmation": `Je staat op het punt om {items, plural,
      one {# freelancer}
      other {# freelancers}    
    }, te annuleren, weet je zeker dat je de actie wilt doorvoeren?`,
    "FreelancerCard.title": "Freelancer details",
    "NotificationsAndEmails.title.emails": "E-mails",
    "NotificationsAndEmails.settings.jobApplied":
      "Nieuwe inschrijving voor een dienst",
    "NotificationsAndEmails.settings.jobRequest": "Nieuwe dienst aanvraag",
    "NotificationsAndEmails.settings.proposedEmployee":
      "Voorgestelde kandidaat",
    "NotificationsAndEmails.settings.jobRequestAccepted":
      "Dienst aanvraag geaccepteerd",
    "NotificationsAndEmails.settings.jobRequestRejected":
      "Dienst aanvraag geweigerd",
    "NotificationsAndEmails.settings.proposedEmployeeAccepted":
      "Voorgestelde kandidaat geaccepteerd",
    "NotificationsAndEmails.settings.proposedEmployeeRejected":
      "Voorgestelde kandidaat geweigerd",
    "NotificationsAndEmails.settings.invitationAccepted":
      "Uitnodiging geaccepteerd",
    "NotificationsAndEmails.settings.export": "Export bestand klaar",
    "NotificationsAndEmails.settings.proposalAccepted": "Voorstel geaccepteerd",
    "NotificationsAndEmails.settings.newApplication":
      "Inschrijving voor een dienst",
    "Pools.projects.poolDescription": `De diensten gelinkt aan dit project zijn zichtbaar voor de medewerkers die binnen de criteria vallen van de geselecteerde pools. Wil je de diensten zichtbaar maken voor iedereen? Voeg dan geen pool toe. De diensten zijn nu zichtbaar voor {numberOfEmployees, plural,
        one {# medewerker}
        other {# medewerkers}    
      }. Wil je een uitzondering maken op een dienst? dan is het ook mogelijk om een pool toe te voegen aan een dienst!`,
    "Pools.jobs.poolDescription": `Zet je dienst uit naar de groep medewerkers die geschikt zijn om hier te werken. Moet de dienst zichtbaar zijn voor iedereen of de pool van het project? voeg dan geen pool toe. Je dienst is nu zichtbaar voor {numberOfEmployees, plural,
        one {# medewerker}
        other {# medewerkers}    
      }.`,
    "Pools.qualifiedEmployees": "Gekwalificeerde medewerkers",
    "NewApplicant.signUp": "Inschrijven",
    "NewApplicant.description": `Leuk dat je je wilt inschrijven voor {tenant_name}! Vul de onderstaande informatie in en wij nemen spoedig contact met je op.`,
    "NewApplicant.disclaimer":
      "Door het invullen van dit formulier, geef ik toestemming voor verwerking van persoonsgegevens die door Fleks worden verkregen.",
    "NewApplicant.signMeIn": "Inschrijven!",
    "NewApplicant.dear": `Beste {first_name}`,
    "NewApplicant.dearDescription":
      "Wij hebben je sollicitatie ontvangen! Bedankt voor je tijd en interesse. We nemen zo snel mogelijk contact met je op!",
    "NewApplicant.sincerely": "Vriendelijke groet,",
  },
};
