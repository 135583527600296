import { SET_SETTING_TENANT } from "../types";
import { combineReducers } from "redux";

export const settings = (state = "1", action = {}) => {
  switch (action.type) {
    case SET_SETTING_TENANT:
      return {
        timeInterval: action.data?.time_interval_for_declaration,
        hoursOwner: action.data?.hours_owner
      }
    default:
      return state;
  }
};

