import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";

import data from "./data";
import ui from "./ui";
import user from "./user";
import projects from "./projects";
import planner from "./planner";
import dashboard from "./dashboard";
import listViews from "./listViews";
import theme from "./theme";
import employee from "./employee";
import runSalary from "./runSalary";
import notifications from "./notification";
import database from "./database";
import pools from "./pools";
import media from "./media";
import occupations from "./occupations";
import scheduler from "./scheduler";
import materials from "./materialList";
import setCards from './setCards';
import employeeSetCards from "./employeeTableSetCards";
import projectsDataForPools from "./projectDataForPools";
import employeeRestTime from './employeerestTime';
import { settings } from "./settings";

export const rootReducer = combineReducers({
  routing,
  ui,
  data,
  user,
  projects,
  planner,
  dashboard,
  listViews,
  theme,
  employee,
  runSalary,
  notifications,
  database,
  pools,
  media,
  occupations,
  scheduler,
  settings,
  materials,
  setCards,
  employeeSetCards,
  projectsDataForPools,
  employeeRestTime
});

export default rootReducer;
